<template lang="pug">
#Quote.textBlock.align-items-center
  p.quote(:class="id, size")
    span.quoteText.safari-only {{quote}}
</template>
<script>
import { ref, onMounted } from "vue";
export default {
  name: "Quote",
  props: ["id", "quote", "transition", "size"],
  setup(props) {
    let sectionObserver = ref(null);
    const options = {
      rootMargin: "0px 0px",
      threshold: 0.7
    };

    function observeSections() {
      try {
        if (sectionObserver.value) sectionObserver.value.disconnect();
      } catch (error) {
        console.log(error);
      }
      sectionObserver.value = new IntersectionObserver(
        sectionObserverHandler,
        options
      );
      const sections = document.querySelectorAll("." + props.id);
      sections.forEach(section => {
        sectionObserver.value.observe(section);
      });
    }
    function sectionObserverHandler(entries) {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          entry.target.children[0].classList.add("revealed", props.transition);
        } else {
          entry.target.children[0].classList.remove(
            "revealed",
            props.transition
          );
        }
      }
    }
    onMounted(observeSections);
  }
};
</script>
<style lang="scss">
#Quote {
  padding: 7rem 1rem;
  position: relative;
  background-color: #fff;
  /* max-width: 750px; */
  p.quote {
    /* max-width: 600px; */
    font-size: 2.3rem;
    /* text-align: left; */
    /* font-weight: 600; */
  }
  p span {
    color: #000;
  }
  .revealed {
    background: linear-gradient(#000, #000) left no-repeat rgba(0, 0, 0, 0);
    background-size: 0% 100%;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
    /* animation: loading 6s forwards linear; */
  }
  .transition-3s {
    animation: loading 3s forwards linear;
  }
  .transition-6s {
    animation: loading 6s forwards linear;
  }
  .transition-9s {
    animation: loading 9s forwards linear;
  }
}
.lg-width {
  max-width: 750px;
}
.md-width {
  max-width: 640px;
}

@keyframes loading {
  to {
    background-size: 100% 100%;
  }
}
// safari
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    #Quote {
      .transition-3s {
        animation: loading 2s forwards linear;
      }
      .transition-6s {
        animation: loading 2s forwards linear;
      }
      .transition-9s {
        animation: loading 2s forwards linear;
      }
    }
    .safari-only {
      display: inline-block;
    }
  }
}
@media (max-width: 420px) {
  #Quote {
    p.quote {
      /* max-width: 800px; */
      font-size: 1.6rem;
      text-align: left;
      font-weight: 600;
    }
  }
}
</style>
