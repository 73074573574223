<template lang="pug">
#Entry3
  Navbar
  EntryHeader(v-bind="entryHeader")
  AudioPlayer.pb-5(:src="audio" :voice="voice")
  .intro.textBlock.align-items-center.px-3.py-5
    p.italic.bold {{intro}}
  TextBlock(:text="block1")
  FullQuote(v-bind="fullQuotes.first")
  TextBlock(:text="block2")
  .container-img-large
    img.responsive(src="/assets/Entry3/G1.svg" alt="dos gráficas, sexo de las personas asesinadas y sexo de las personas desaparecidas")
  //- block3
  .white-bg
    ul.md-list.textBlock
      li.px-3.mb-4 {{block3[0].text}}
      li.px-3.mb-4 {{block3[1].text}}
  .container-img-large
    img.responsive.section(src="/assets/Entry3/IMG_20210328_092222-01.jpg" alt="ficha de búsqueda de mujer pegada y rasgada")
  .label-img.justify-content-center
    p.w-1000 Ficha de búsqueda de Viviana Garrido desaparecida en noviembre del 2018. Durante las búsquedas en vida los familiares pegan las fichas en espacios públicos pero estas son constantemente retiradas.
  Quote.bold(id="e3q1" :quote="quotes.first" transition="transition-9s" size="lg-width")
  .white-bg
    .textBlock.lg-list.px-3
      p.my-5 {{bullets.intro}}
      ul.px-5
        li.my-5 {{bullets.first}}
        li.my-5 {{bullets.second}}
        li.my-5 {{bullets.third}}
        li.my-5 {{bullets.fourth}}
        li.my-5 {{bullets.fifth}}
  .white-bg
    SectionHeader.bold(:title="sectionHeaders.first")
    TextBlock.sm-width(:text="block4")
    TextBlock(:text="block27")
    SectionHeader.bold(:title="sectionHeaders.second")
    TextBlock.sm-width.pb-5(:text="block5")
    TextBlock.pt-5(:text="block6")
    SectionHeader.bold.uppercase(:title="sectionHeaders.third")
    TextBlock(:text="block7")
  .container-img-sm
    img.responsive(src="/assets/Entry3/DesaparecidasEdades.svg" alt="gráfica de edades de las personas desaparecidas por sexo")
  TextBlock(:text="block8")
  .container-img
    img.responsive(src="/assets/Entry3/G3.svg" alt="gráfica de estatus de las personas desaparecidas por sexo")
  .container-img
    img.responsive(src="/assets/Entry3/G2.svg" alt="gráfica de edades de las personas desaparecidas por sexo y por estatus")
  TextBlock(:text="block9")
  .container-img
    img.responsive(src="/assets/Entry3/19_tiempossexoedad.svg" alt="gráfica de tiempo de localización de las víctimas de desaparición por sexo y edad")
  Quote.bold(id="e3q2" :quote="quotes.second" transition="transition-9s" size="lg-width")
  TextBlock(:text="block10")
  .white-bg
    SectionHeader.bold(:title="sectionHeaders.fourth")
    TextBlock.sm-width.pb-5(:text="block11")
    TextBlock.pt-5(:text="block12")
  FullQuote(v-bind="fullQuotes.second")
  TextBlock.pt-5(:text="block13")
  .white-bg
    SectionHeader.bold.uppercase(:title="sectionHeaders.fifth")
  TextBlock(:text="block14")
  TextBlockLg.bold(:text="block26")
  //- block15
  .white-bg
    .paragraph.textBlock.pb-0
      p.text.px-3.mb-4
        span.italic -¿Cómo se explican que mujeres que fueron desaparecidas sean encontradas muertas?-&nbsp;
        span pregunta a Sol Salgado.
  TextBlock.pt-0(:text="block16")
  TextBlockLg.bold(:text="block17")
  TextBlock(:text="block18")
  .white-bg
    SectionHeader.bold.uppercase(:title="sectionHeaders.sixth")
  TextBlock(:text="block19")
  .container-fluid.px-0
    #e3i7.full-bg.section
  .label-img.justify-content-center
    p Mural de Mariela Vanessa en Iztapalapa realizado por el colectivo Hasta Encontrarles
  TextBlock(:text="block20")
  .white-bg
    SectionHeader.bold.uppercase(:title="sectionHeaders.seventh")
    TextBlock(:text="block21")
    SectionHeader.bold(:title="sectionHeaders.eighth")
    TextBlock.sm-width.pb-5(:text="block22")
  .container-img-large
    img.responsive.section(src="/assets/Entry3/DSC03199.png" alt="dos mujeres en un terreno con pasto y árboles")
  .label-img.justify-content-center
    p.w-1000 Herminia Valverde y Gabriela Díaz Valverde, madre y hermana de Mariela Vanessa, en el predio en el que se registró la última localización de Mariela, desaparecida desde abril del 2018.
  .white-bg
    TextBlock.sm-width.pb-5(:text="block23")
  TextBlock.pt-5(:text="block24")
  TextBlockLg.bold.italic.py-0(:text="block25")
  Pagination(:prev="prevEntry" :next="nextEntry")
</template>

<script>
// simport { ref } from "vue";
import Navbar from "@/components/Navbar.vue";
import AudioPlayer from "@/components/AudioPlayer.vue";
import TextBlock from "@/components/TextBlock.vue";
import TextBlockLg from "@/components/TextBlockLg.vue";
import EntryHeader from "@/components/EntryHeader.vue";
import SectionHeader from "@/components/SectionHeader.vue";
import FullQuote from "@/components/FullQuote.vue";
import Quote from "@/components/Quote.vue";
import Pagination from "@/components/EntryPagination/Pagination.vue";
import { thirdEntryText } from "@/assets/ThirdEntry.js";
import { secondTitle } from "@/assets/SecondEntry.js";
import { fourthTitle } from "@/assets/FourthEntry.js";

export default {
  name: "ThirdEntry",
  components: {
    TextBlock,
    TextBlockLg,
    EntryHeader,
    SectionHeader,
    FullQuote,
    Quote,
    Pagination,
    Navbar,
    AudioPlayer
  },
  setup() {
    const prevEntry = secondTitle;
    const nextEntry = fourthTitle;
    const audio = "https://s3.amazonaws.com/media.reportajes.fordfoundation.org/Desapariciones-DanielaRea.m4a"
    const voice = "Daniela Rea";
    const [
      block1,
      block2,
      block3,
      block4,
      block5,
      block6,
      block7,
      block8,
      block9,
      block10,
      block11,
      block12,
      block13,
      block14,
      block15,
      block16,
      block17,
      block18,
      block19,
      block20,
      block21,
      block22,
      block23,
      block24,
      block25,
      block26,
      block27,
      entryHeader,
      sectionHeaders,
      fullQuotes,
      quotes,
      intro,
      bullets
    ] = thirdEntryText;
    return {
      block1,
      block2,
      block3,
      block4,
      block5,
      block6,
      block7,
      block8,
      block9,
      block10,
      block11,
      block12,
      block13,
      block14,
      block15,
      block16,
      block17,
      block18,
      block19,
      block20,
      block21,
      block22,
      block23,
      block24,
      block25,
      block26,
      block27,
      entryHeader,
      sectionHeaders,
      prevEntry,
      nextEntry,
      fullQuotes,
      quotes,
      intro,
      bullets,
      audio,
      voice
    };
  }
};
</script>
<style lang="scss">
#Entry3 {
  #Header {
    .header-bg {
      background-image: url("/assets/Entry3/DSC02827.JPG");
    }
    /* background-image: url("/assets/Entry3/DSC02827.JPG");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    height: 200vh;
    margin: auto auto 5rem auto; */
  }
  #SectionHeader {
    max-width: 750px;
  }
  .intro {
    background-color: #fff;
    font-weight: 600;
    p {
      max-width: 750px;
      font-size: 1.5rem;
    }
    span {
      max-width: 750px;
      font-size: 1.2rem;
    }
  }

  .md-list {
    max-width: 630px;
    font-size: 1.25rem;
  }
  .lg-list {
    max-width: 750px;
    font-size: 1.5rem;
    font-weight: 600;
  }
  .sm-width {
    max-width: 530px;
  }

  img.section {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s ease 1s, opacity 800ms ease,
      filter 0.5s ease-in-out;
    filter: blur(1px);
  }
  img.section.revealed {
    filter: blur(0);
    visibility: visible;
    opacity: 1;
    transition-delay: 0s;
  }
  .full-bg.revealed {
    background-color: rgba(#fff, 0);
  }
  #e3i7 {
    background-image: url("/assets/Entry3/DSC02862.png");
  }
}
@media (max-width: 420px) {
  #Entry3 {
    #e3i7 {
      height: 70vh;
      background-position: center;
    }
    .lg-list {
      font-size: 1.2rem;
    }
  }
}
</style>
