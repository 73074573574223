const block1 = [
  {
    text:
      "Sagrario González Flores desapareció el 16 de abril de 1998, dos meses antes de cumplir 18 años. Su cuerpo, martirizado, se localizó semanas después en el desierto de Chihuahua."
  },
  {
    text:
      "Sagrario era la cuarta de los siete hijos del matrimonio de Paula Flores y Jesús González. Originaria de Durango, la familia había llegado tres años antes a Ciudad Juárez con el sueño de mejorar sus vidas en la ciudad donde prosperaban las maquiladoras. "
  },
  {
    text:
      "En febrero de 2005 la familia logró que la policía detuviese a José Luis Hernández, El Manuelillo, un coyote del barrio, quien había desaparecido de la zona poco después del crimen. En su primera declaración, Hernández dijo que dos hombres le pagaron 500 dólares por entregar a la joven, a la que interceptaron al salir de la maquiladora cuando la cambiaron de turno y su padre ya no pudo acompañarla. En el juicio cambió su declaración y dijo que actuó solo. Lo condenaron a 28 años de prisión. "
  },
  {
    text:
      "Jesús González, el padre de Sagrario, se suicidó en 2006, incapaz de superar la pena. "
  },
  {
    text:
      "Paula Flores, su madre, se quedó en Juárez y se convirtió en un ícono de la lucha por la justicia en los feminicidios de esa ciudad fronteriza. Su historia fue llevada al cine en el documental dirigido por José Bonilla 'La Carta. Sagrario nunca has muerto para mí'. Paula llevó su caso a la Comisión Interamericana de Derechos Humanos. La última audiencia que ha tenido fue en septiembre de 2019, es decir, 21 años después del asesinato de Sagrario."
  }
];
const block2 = [
  {
    text:
      "expresó la mujer frente a cinco comisionados del organismo internacional el viernes 27 de septiembre, cuando se desahogó una diligencia pública del caso 13 mil 337, abierto contra el Estado mexicano por seis feminicidios ocurridos en Juárez entre 1995 y 2003. El año en el que mataron a Sagrario hubo en Juárez otras 35 mujeres asesinadas. Para el cierre de ese 1998, ya sumaban 179 asesinatos de mujeres en esa ciudad, que vivió un auge de empresas maquiladoras a partir del establecimiento del Tratado de Libre Comercio de América del Norte (TLCAN). Muchas de las víctimas eran, como Sagrario, jóvenes que vivían en las colonias periféricas y trabajaban en la maquila. La prensa nacional comenzó a referirse a ellas como “Las muertas de Juárez”. La investigadora Marcela Lagarde le puso nombre al delito: feminicidio."
  }
];
const block3 = [
  {
    text:
      "La definición de los asesinatos de mujeres cometidos por hombres desde una superioridad de grupo ya existía. En la década de los 70, la estadunidense Diana Russell acuñó la expresión de “feminicio” para reconocer y visibilizar la discriminación, opresión, desigualdad y violencia sistemática contra las mujeres en su forma más violenta hasta entonces: el asesinato que ocurría, por lo general, dentro del núcleo familiar."
  },
  {
    text:
      "Juárez y sus maquilas cambiaron el paradigma: las mujeres desaparecían y sus cuerpos eran localizados en la vía pública o se esfumaban en el desierto. Lagarde agregó al concepto un significado político para denunciar la falta de respuesta del Estado y definió el feminicidio como un crimen de Estado: “una fractura del Estado de Derecho que favorece la impunidad”. El 16 de noviembre de 2009, la Corte Interamericana de Derechos Humanos emitió una sentencia condenatoria contra el Estado mexicano por el asesinato de tres mujeres en Campo Algodonero. Fue el primer fallo de su tipo en el mundo y estableció reparaciones con perspectiva de género."
  }
];
const block4 = [
  {
    text:
      "Para 2019, el año en que Paula Flores acudió a su última audiencia en la CIDH, los asesinatos de mujeres en Juárez sumaban mil 884 desde 1993, según los registros de la Fiscalía de Chihuahua. Sólo en 2010 hubo 304, es decir, nueve veces más que en el año del asesinato de Sagrario."
  },
  {
    text:
      "Y de acuerdo con el estudio Homicidios y Desapariciones de Mujeres en México, elaborado por Data Cívica, entre los años 2000 y 2017 fueron asesinadas en México 34 mil 846 mujeres."
  },
  {
    text:
      "El estudio precisa que los asesinatos de mujeres representan apenas el 11 por ciento de los homicidios registrados en el país en ese mismo tiempo. Y destaca un dato: las tasas de homicidio y desaparición de mujeres siguieron dos trayectorias distintas: La primera se mantuvo en un rango más o menos constante, mientras que la segunda se disparó."
  }
];
const block5 = [
  {
    text:
      "¿Cómo fue que pasamos de hablar de “las muertas de Juárez” a contar mujeres asesinadas en todo el territorio del país?"
  },
  {
    text:
      "Laura Baptista, consultora en seguridad y experta en temas de género, no tiene dudas de que el análisis de los feminicidios está cruzado con la violencia provocada por la expansión y fragmentación de los grupos del crimen organizado durante el sexenio de Felipe Calderón."
  },
  {
    text:
      "'Se rompió un pacto', dice Baptista. Lo que en su momento fue llamado guerra contra las drogas provocó un cambio, tanto de las formas de participación de las mujeres dentro de las organizaciones criminales, como de ver a las novias o esposas de delincuentes del grupo contrario como un botín de guerra."
  }
];
const block6 = [
  {
    text:
      "A eso se agregan dos elementos: la diversificación de las formas del crimen (tráfico de órganos, trata) y una saña cada vez mayor sobre los cuerpos."
  }
];
const block7 = [
  {
    text:
      "“El feminicidio se encontraba en casa. Pero hoy está en el espacio público y tiene una narrativa de lo que piensan los asesinos sobre sus víctimas: son nada, basura, un despojo. Es una descomposición brutal, de chavos que tienen poco aprecio por su vida porque a nadie importa su vida. Y se ha ido sofisticando. La forma más brutal son las desapariciones”, dice."
  },
  {
    text:
      "La investigadora feminista Emanuela Borzacchiello coincide en el diagnóstico y agrega un elemento: el autorreconocimiento dentro de una comunidad violentada."
  }
];
const block8 = [
  {
    text:
      "La exposición de los cuerpos, dice Borzacchiello, es más que lanzar un mensaje hacia sus pares. Es matar el recuerdo, la afectividad."
  },
  {
    text:
      "“Es para decir: ‘Yo tengo poder y ejerzo la voluntad de matar’. Y para demostrar ese poder no sirve solo matar a una desconocida, sino a una mujer deseada. Y hay que hacerlo con más violencia, para reconocerse y ser más reconocidos”."
  },
  {
    text:
      "Borzacchiello piensa que es necesario un análisis del fenómeno distinto al que se ha hecho, y que está enfocado, sobre todo, en la impunidad de los crímenes."
  },
  {
    text:
      "“Necesitamos un paso metodológico más atrevido”, dice. “Es decir, sí la impunidad, pero no sólo la impunidad. Porque si siguen diciendo que la impunidad es el problema no vamos a avanzar”."
  }
];
const block9 = [
  {
    text:
      "A partir del trabajo que hizo de violencia de género entre 2007 y 2009 en Guanajuato, donde estudió este cambio en las violencias hacia las mujeres en Silao, un municipio en proceso de industrialización similar al que tuvo Juárez, la investigadora enlista algunas pistas:"
  }
];
const block10 = [
  {
    text:
      "“Tenemos que revisar lo que ocurre entre lo público y lo privado, porque las matan en la casa, pero no las dejan en la casa. Y no se está registrando donde las mataron, sino donde las dejaron. Tenemos que volver a revisar Juárez, porque los feminicidios están bajando y las desapariciones aumentando y la desaparición con fines de trata es la máxima esclavitud desde un concepto económico neoliberal. Hay un modus operandi que se está gestando en eso y que tiene que ver con las cadenas productivas, con la desigualdad que se crea a partir de la precarización laboral y del modelo económico se inserta en la competencia. Tenemos que pensar de otra forma el concepto para la reparación del daño, que ahora está reducido a dar dinero a las familias”."
  }
];
const block11 = [
  {
    text:
      "En 2002, Paula Flores fundó en Lomas de Poleo el jardín de niños María Sagrario Flores González."
  },
  {
    text:
      "“Tenemos que trabajar con los más chiquitos, para que todo cambie”, decía en 2010, cuando se presentó el documental."
  },
  {
    text:
      "Por esos días también dirigía la Fundación Sagrario, que buscaba articular una propuesta cultural como alternativa a la violencia. Pero esa, como otras alternativas culturales en Juárez, fueron arrasadas por la violencia que desató la estrategia de seguridad de Felipe Calderón y que en 2010 colocó a Juárez en la cima de las ciudades más violentas del continente."
  },
  {
    text:
      "Para entonces, también, la gente en Juárez y en el país se había acostumbrado al tema del feminicidio y comenzaba a normalizarlo. Y ya desde entonces, las organizaciones reconocían un fenómeno emergente: la desaparición de niñas y mujeres."
  },
  {
    text:
      "A la distancia, Borzacchiello y Baptista destacan que la búsqueda de justicia opacó un tema que Paula Flores veía desde 2002: la incidencia en los espacios formativos de los posibles agresores."
  }
];
const block12 = [
  {
    text:
      "Laura Baptista, quien tiene un amplio trabajo en prisiones y con policías y ministerios públicos, tiene una visión menos optimista:"
  },
  {
    text:
      "“El problema es enorme. No es solo un problema de impunidad: es de estructuras sociales. Porque los policías y los oficiales piensan como los agresores y los ministerios públicos también. El tipo penal está mal hecho y mete a los ministerios públicos en la necesidad de demostrar cosas muy difíciles, como la intención. Son zonas grises que no se ven, y no hay capacidad de atender el problema”."
  },
  {
    text:
      "Para poder salir del atolladero, dice, se requieren muchos cambios, incluso de los medios de comunicación, de las organizaciones de víctimas y de los propios colectivos el movimiento feminista."
  },
  {
    text:
      "“Grupos de mujeres han tenido información valiosa, pero se quedan en el activismo. Y está súper abandonado el tema de las masculinidades. Los talleres que hay son poco asertivos, en general, parece que ellos deben ir para ser regañados. En otros lugares hay experiencias más exitosas, o que han logrado algún avance, como en Medellín (Colombia), donde los programas se concentraron en trabajar con los más jóvenes”."
  }
];
const entryHeader = {
  preTitle: "",
  title: "Feminicidios:",
  subtitle: "Más que matar porque puedo, matar para ser alguien",
  place: "Juárez, el laboratorio",
  author: "Por: Daniela Pastrana",
  color: "pink-color",
  route: "feminicidios"
};
const fullQuotes = {
  first: {
    id: "e1f1",
    text:
      "¿Cómo fue que pasamos de hablar de “las muertas de Juárez” a contar mujeres asesinadas en todo el territorio del país? ¿Cómo pasamos de los feminicidios íntimos a los cuerpos de mujeres expuestos?",
    subtext: "",
    bgColor1: "white-bg",
    bgColor2: "grey-bg",
    color1: "grey-color",
    color2: "neutral-color",
    fontSize: "md"
  },
  second: {
    id: "e1f2",
    text: "“No me quiero morir sin saber que realmente se hizo justicia”",
    subtext: "",
    bgColor1: "pink-bg",
    bgColor2: "light-grey-bg",
    color1: "grey-color",
    color2: "grey-color",
    fontSize: "lg"
  },
  third: {
    id: "e1f3",
    text:
      "“Tenemos políticas siempre enfocadas en las víctimas y no en los victimarios”, dice Borzacchiello. “Necesitamos volver a crear un vínculo entre las personas afectadas y los victimarios. No se trata de personas sino de un proceso de escucha y reconciliación. Y por supuesto, la educación de los niños es fundamental”.",
    subtext: "",
    bgColor1: "pink-gradient-bg",
    bgColor2: "grey-gradient-bg",
    color1: "carbon-color",
    color2: "carbon-color",
    fontSize: "sm"
  }
};
const quotes = {
  first:
    "Pero los crímenes por violencia de género no sólo no se detuvieron. Por el contrario: Juárez escaló al país.",
  second:
    "“Las mujeres son un territorio donde esta violencia se expresa, y son propiedades de estos hombres. Sus enemigos vienen y expresan estas violencias en ellas”.",
  third:
    "“Cuando el contexto de violencia cambia y se vuelve más violento, ellos tienen que ser más violentos para ser reconocidos. Ya no sólo es que mata porque puede, sino para sentirse alguien, para retroalimentar su propia existencia en un contexto en el que a nadie le importa”."
};
const sectionHeaders = {
  first: "Un intento por nombrar",
  second: "¿Qué pasó?",
  third: "Trabajar con los más chiquitos"
};
export const firstEntryText = [
  block1,
  block2,
  block3,
  block4,
  block5,
  block6,
  block7,
  block8,
  block9,
  block10,
  block11,
  block12,
  entryHeader,
  sectionHeaders,
  fullQuotes,
  quotes
];
export const firstTitle = entryHeader;
