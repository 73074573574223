<template lang="pug">
#TextBlock.textBlock.align-items-center
  .div(v-for="paragraph in text")
    p.text.px-3.mb-4 {{paragraph.text}}
</template>

<script>
export default {
  name: "TextBlock",
  props: ["text"],
  setup() {}
};
</script>
<style lang="scss">
#TextBlock {
  background-color: #fff;
  p.text {
    max-width: 640px;
    font-size: 1.25rem;
    text-align: left;
  }
}
</style>
