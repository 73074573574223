<template lang="pug">
#Collectives
  .container.collectivesTitle
    h2 Colectivas
    p por Vania Pigeonutt
  .container.collectives
    section.container.collectives-intro.section
      p.text-center.py-3.md-width.bolder Nos matan, nos desaparecen, nos borran, nos quieren callar.
      p.text-center.boldest.py-3.md-width La historia de las mujeres en México ha sido contada desde las pérdidas, las ausencias, la orfandad.
      p.text-center.py-3.lg-width
        span.bolder Pero las mujeres nos rebelamos a que esa sea la última historia, también queremos contar la nuestra:&nbsp;
        span.pink.boldest la del cuidado, la ternura, el ser compañeras; la de la lucha diaria por construir lugares que podamos habitar.
    .row.d-flex.justify-content-around
      .col-sm-12.col-lg-3.text-center.mt-5(v-for="collective in collectivesTitles")
        a(:href="collective.route")
          .img-container.section
            img(:src="collective.imagePath" alt="imagen de la coletiva")
          .title-container.mt-5
            p.pretitle {{collective.preTitle}}
            p.title {{collective.title}}
            p.subtitle {{collective.subtitle}}
            p
              span.place {{ collective.place}}

</template>
<script>
import { fourthTitle } from "@/assets/FourthEntry.js";
import { fifthTitle } from "@/assets/FifthEntry.js";
import { sixthTitle } from "@/assets/SixthEntry.js";

export default {
  name: "Collectives",
  setup() {
    const collectivesTitles = [fourthTitle, fifthTitle, sixthTitle];
    return {
      collectivesTitles
    };
  }
};
</script>
<style lang="scss">
#Collectives {
  background-color: #e5e5e5;
  font-size: 1.5rem;
  .collectives {
    padding-bottom: 16rem;
  }
  .img-container {
    height: 200px;
    visibility: hidden;
    opacity: 0;
    transition: visibility 1s linear 1s, opacity 1s linear;
    img {
      width: auto;
      height: 100%;
    }
  }
   section {
    height: 90vh;
    padding: 1rem;
    visibility: hidden;
    opacity: 0;
    transition: visibility 1s ease 1s, opacity 1s ease;
    /* transition: visibility 1s linear 1s, opacity 1s linear; */
    h2 {
      font-size: 1.6rem;
      font-weight: bold;
    }
    &.collectives-intro {
      .md-width {
        max-width: 850px;
      }
      .lg-width {
        max-width: 850px;
      }
      p {
        font-size: 1.4rem;
        font-family: "Mulish", sans-serif;
      }
    }
  }
  .revealed {
    visibility: visible;
    opacity: 1;
    transition-delay: 0s;
  }
  a {
    text-decoration: none;
  }
  .pretitle,
  .subtitle {
    color: #303030;
    font-weight: 700;
    line-height: 1.1;
    padding: 0;
    margin: 0;
  }
  .title {
    color: #303030;
    font-weight: 900;
    padding: 0;
    margin: 0;
  }
  .place {
    color: #303030;
    padding: 0 0.5rem;
    font-weight: 600;
    font-style: italic;
    background-color: #ba7eaf86;
    /* background-color: rgba(219, 130, 204, 0.39); */
    font-size: 1.4rem;
  }
  .collectivesTitle {
    display: none;
  }
}
@media (max-width: 769px) {
  #Collectives {
    .collectivesTitle {
      color: #303030;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;
      h2 {
        font-weight: 900;
        text-transform: uppercase;
        font-size: 2.7rem;
        margin: 0;
      }
      p {
        font-weight: bold;
        font-style: italic;
        font-size: 1.5rem;
      }
    }
  }
}
@media (max-width: 420px) {
  #Collectives {
    section {
      &.collectives-intro {
        p {
          font-size: 1.25rem;
        }
      }
    }
  }
}
</style>
