<template lang="pug">
#Entry6
  Navbar
  EntryHeader(v-bind="entryHeader")
  AudioPlayer.pb-5(:src="audio" :voice="voice")
  .white-bg
    .intro.textBlock.px-3
      p.italic {{intro}}
      span.pt-4 {{introAuthor}}
  SectionHeader.semibold(:title="sectionHeaders.first")
  TextBlock(:text="block1")
  .container-img-large
    img.responsive.section(src="/assets/Entry6/Ecatepec_colectiva_mujeresdelaperiferiaparalaperiferia01.jpg" alt="retrato de mujer con pañuelo morado en el cuello")
  .label-img.justify-content-center
    p.w-1000 Carolina comenzó en el performance cuando iban en la preparatoria.Desde entonces fue creciendo la semilla del feminismo en su corazón, ahora es parte de la colectiva con la que acciona contra la violencia a las mujeres en Ecatepec.
  TextBlock(:text="block2")
  FullQuote(v-bind="fullQuotes.first")
  TextBlock.bold.pb-0(:text="block3")
  TextBlock(:text="block4")
  SectionHeader.semibold(:title="sectionHeaders.second")
  TextBlock.pb-0(:text="block5")
  // block6
  .white-bg
    .paragraph.textBlock.py-0
      p.text.px-3.mb-4 Pero en el territorio eso no se ve, no se siente. Ninguna de las alertas o las acciones han revertido el miedo que significa caminar sola, o ha brindado la posibilidad de ser libres.&nbsp;
        span.bold Carolina, por ejemplo, carga una navaja en la mochila, ha aprendido a no caminar por las calles de su municipio sin compañía y a no confiar en la policía.
    .container-fluid.px-0
      img.responsive.section(src="/assets/Entry6/Ecatepec_colectiva_mujeresdelaperiferiaparalaperiferia03.jpg" alt="vestido y zapatos rojos tirados en el piso")
  TextBlock(:text="block7")
  Quote.bolder(id="e6q1" :quote="quotes.first" transition="transition-9s" size="lg-width")
  .container-fluid.px-0
    #e6i1.full-bg.section
  .label-img.justify-content-center
    p Angélica Ramírez y Karla Esteves fueron asesinadas en abril del 2019. La colectiva Mujeres de la Periferia para la Periferia acompañan a su familia en la búsqueda de justicia. En esta fotografía aparece Magda junto a Angélica Esteves, hija y hermana de las víctimas de feminicidio en el predio en el que encontraron sus cuerpos y que la colectiva ha resignificado realizando performances en su memoria.
  TextBlock(:text="block8")
  SectionHeader.semibold(:title="sectionHeaders.third")
  TextBlock(:text="block9")
  Quote.bold(id="e6q2" :quote="quotes.second" transition="transition-6s" size="md-width")
  TextBlock(:text="block10")
  .container-img-large
    img.responsive.section(src="/assets/Entry6/Ecatepec_colectiva_mujeresdelaperiferiaparalaperiferia02.jpg" alt="mujer de espaldas con corona de flores rosas")
  .label-img.justify-content-center
    p.w-1000 Lucía porta una corona de flores que es parte de uno de los distintos vestuarios que utilizan en sus intervenciones políticas. Los vestuarios de performance los realizan ellas con sus recursos e imaginación.
  TextBlock(:text="block11")
  SectionHeader.semibold(:title="sectionHeaders.fourth")
  .container-img-large
    img.responsive.section(src="/assets/Entry6/Ecatepec_colectiva_mujeresdelaperiferiaparalaperiferia.jpg" alt="tres mujeres recragadas contra la pared haciendo señas con las manos")
  .label-img.justify-content-center
    p.w-1000 El performance es una de las herramientas que la colectiva Mujeres en la periferia para la periferia utiliza para protestar contra la violencia a la que se enfrentan mujeres radicadas en Ecatepec.
  TextBlock(:text="block12")
  FullQuote(v-bind="fullQuotes.second")
  TextBlock(:text="block13")
  Quote.lighter(id="e6q3" :quote="quotes.third" transition="transition-3s" size="md-width")
  Pagination(:prev="prevEntry" :next="nextEntry")


</template>

<script>
import Navbar from "@/components/Navbar.vue";
import AudioPlayer from "@/components/AudioPlayer.vue";
import TextBlock from "@/components/TextBlock.vue";
import EntryHeader from "@/components/EntryHeader.vue";
import SectionHeader from "@/components/SectionHeader.vue";
import FullQuote from "@/components/FullQuote.vue";
import Quote from "@/components/Quote.vue";
import Pagination from "@/components/EntryPagination/Pagination.vue";
import { sixthEntryText } from "@/assets/SixthEntry.js";
import { fifthTitle } from "@/assets/FifthEntry.js";

export default {
  name: "SixthEntry",
  components: {
    TextBlock,
    EntryHeader,
    SectionHeader,
    FullQuote,
    Quote,
    Pagination,
    Navbar,
    AudioPlayer
  },
  setup() {
    const prevEntry = fifthTitle;
    const nextEntry = "";
    const intro =
      "“Yo soy Michel, Joseline, Lupita, Daniela, Rosita, una niña de la orilla. Yo soy la embolsada, la violada y quemada, la niña arrojada al río de aguas negras. Yo soy la niña pobre de la periferia. Yo soy fuego, aires de estas tierras, voz de las nuestras que surge entre polvareda para llegar aquí, a dar voz en otras que insisten vivir y jugar mientras sueñan”. ";
    const introAuthor =
      "Manuel Amador, profesor de la preparatoria La Panchito, Ecatepec.";
    const audio =
      "https://s3.amazonaws.com/media.reportajes.fordfoundation.org/PonerElCuerpo_Vania_Vivir.m4a";
    const voice = "Vivir Quintana";
    const [
      block1,
      block2,
      block3,
      block4,
      block5,
      block6,
      block7,
      block8,
      block9,
      block10,
      block11,
      block12,
      block13,
      entryHeader,
      sectionHeaders,
      fullQuotes,
      quotes
    ] = sixthEntryText;
    return {
      block1,
      block2,
      block3,
      block4,
      block5,
      block6,
      block7,
      block8,
      block9,
      block10,
      block11,
      block12,
      block13,
      entryHeader,
      sectionHeaders,
      fullQuotes,
      quotes,
      intro,
      introAuthor,
      prevEntry,
      nextEntry,
      audio,
      voice
    };
  }
};
</script>
<style lang="scss">
#Entry6 {
  #Header {
    .header-bg {
      background-image: url("/assets/Entry6/Ecatepec_colectiva_mujeresdelaperiferiaparalaperiferia04.jpg");
    }
    /* background-image: url("/assets/Entry6/Ecatepec_colectiva_mujeresdelaperiferiaparalaperiferia04.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    height: 200vh;
    margin: auto auto 5rem auto; */
  }
  .intro {
    max-width: 750px;
    font-weight: 600;
    p {
      font-size: 1.5rem;
    }
    span {
      font-size: 1.2rem;
    }
  }
  img.section {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s ease 1s, opacity 800ms ease,
      filter 0.5s ease-in-out;
    filter: blur(1px);
  }
  img.section.revealed {
    filter: blur(0);
    visibility: visible;
    opacity: 1;
    transition-delay: 0s;
  }
  .full-bg.revealed {
    background-color: rgba(#fff, 0);
  }
  #e6i1 {
    background-image: url("/assets/Entry6/ecatepec_colectiva_mujeresdelaperiferiaparalaperiferia05_familiaangelicaykarla.jpg");
  }
}
@media (max-width: 420px) {
  #Entry6 {
    #e6i1 {
      height: 90vh;
      background-position: center;
    }
  }
}
</style>
