<template lang="pug">
#Navbar
  nav.navbar.navbar-expand.d-flex(:class="{ 'hidden-nav': !showNavbar }")
    //- .container-fluid.my-0.d-flex.justify-content-between
    a.navbar-brand.py-0(href='/')
      img(src="/assets/Logo_Reportajes Ford_Header.png" alt="logo reportajes")
    ul.navbar-nav.desktop
      li.nav-item.main
        a.nav-link(href="feminicidios") feminicidios
      li.nav-item.main
        a.nav-link(href="precarizacion") precarización
      li.nav-item.main
        a.nav-link(href="desapariciones") desapariciones
      li.nav-item.main.collectives
        a.nav-link(href="#") colectivas
      li.nav-item.nested
        a.nav-link(href="neza") Nos queremos
          br
          span Vivas Neza
      li.nav-item.pt-2.nested
        a.nav-link(href="cdmx")
          span.px-3.py-1.side-border Siempre Vivas
      li.nav-item.pt-2.nested
        a.nav-link(href="ecatepec") Poner el cuerpo
    ul.navbar-nav.mobile
      li.nav-item.dropdown
        .div.btn-group
        button.btn.btn-name {{routeName}}
        button.btn.btn-caret.dropdown-toggle.dropdown-toggle-split(data-bs-toggle="dropdown" aria-expanded="false")
          span.visually-hidden toggle
        //- a#navbarDropdownMenuLink.nav-link.dropdown-toggle(href='#' role='button' data-bs-toggle='dropdown' aria-expanded='false')
        //-   | {{routeName}}
        ul.dropdown-menu.dropdown-menu-end(aria-labelledby='navbarDropdownMenuLink')
          li
            a.dropdown-item.main-drop(href="feminicidios") feminicidios
          li
            a.dropdown-item.main-drop(href="precarizacion") precarización
          li
            a.dropdown-item.main-drop(href="desapariciones") desapariciones
          li
            a.dropdown-item.main-drop.disabled(href="#") colectivas
          hr
          li
            a.dropdown-item.collective(href="neza") Nos queremos Vivas Neza
          li
            a.dropdown-item.collective(href="cdmx") Siempre Vivas
          li
            a.dropdown-item.collective(href="ecatepec") Poner el cuerpo






</template>
<script>
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import { useRoute } from "vue-router";
export default {
  name: "Navbar",
  setup() {
    const route = useRoute();
    let routeName = computed(() => route.name);
    // let lastScrollPosition = 0;
    let prevScrollpos = ref(window.pageYOffset);
    let showNavbar = ref(true);
    function handleScroll() {
      if (
        document.body.scrollTop === 0 ||
        document.body.scrollTop > 700 ||
        document.documentElement.scrollTop > 700
      ) {
        showNavbar.value = true;
        let currentScrollPos = window.pageYOffset;
        if (prevScrollpos.value > currentScrollPos) {
          showNavbar.value = true;
        } else {
          showNavbar.value = false;
        }
        prevScrollpos.value = currentScrollPos;
      } else {
        showNavbar.value = false;
      }
    }
    function onScroll() {
      window.addEventListener("scroll", handleScroll);
    }
    onMounted(onScroll);
    onBeforeUnmount(() => {
      window.removeEventListener("scroll", handleScroll);
    });
    return {
      route,
      routeName,
      showNavbar
    };
  }
};
</script>
<style lang="scss">
#Navbar {
  .navbar.hidden-nav {
    transform: translate3d(0, -100%, 0);
  }
  .navbar {
    z-index: 99;
    height: 70px;
    background-color: black;
    font-size: 1rem;
    padding: 0rem;
    justify-content: center;
    position: fixed;
    width: 100%;
    transition: top 0.5s ease-out;
    .navbar-brand {
      height: 100%;
      width: auto;
      margin-right: 3rem;
      img {
        height: 100%;
        width: auto;
      }
    }
    .main {
      padding-top: 0.75rem;
      margin: 0 0.6rem;
      .nav-link {
        text-transform: uppercase;
        color: #fff;
      }
      .nav-link:hover {
        border: 1px solid #fff;
      }
    }
    .main.collectives:hover ~ .nested {
      .nav-link {
        color: #e17de8;
      }
    }
    .nested {
      text-align: center;
      font-size: 0.8rem;
      .nav-link {
        padding: 0.8rem 0.6rem;
        color: #929292;
      }
      .nav-link:hover {
        color: #e17de8;
      }
      .side-border {
        border-left: 1px solid #fff;
        border-right: 1px solid #fff;
      }
    }
  }
}
@media (max-width: 1279px) {
  #Navbar {
    .navbar {
      height: 60px;
      .main {
        padding-top: 0.6rem;
        font-size: 0.7rem;
      }
      .nested {
        font-size: 0.6rem;
      }
    }
  }
}
@media (max-width: 1000px) {
  #Navbar {
    .navbar {
      height: 50px;
      .main {
        margin: 0rem;
        padding-top: 0.3rem;
        font-size: 0.6rem;
        .nav-link {
          padding: 0.4rem;
        }
      }
      .nested {
        font-size: 0.5rem;
        .nav-link {
          padding: 0.4rem;
        }
      }
    }
  }
}
@media (max-width: 769px) {
  #Navbar {
    .navbar {
      height: 49px;
      .navbar-brand {
        margin-right: 1rem;
      }
      .main {
        .nav-link {
          padding: 0.3rem;
        }
      }
      .nested {
        .nav-link {
          padding: 0.3rem;
        }
      }
    }
  }
}
@media (max-width: 600px) {
  #Navbar {
    .navbar {
      height: 55px;
      justify-content: between;
      .navbar-brand {
        height: 100%;
        width: auto;
        margin-right: 1rem;
        img {
          margin-left: -45px;
          height: 100%;
          width: auto;
        }
      }
      .dropdown {
        border-radius: 8px;
        border: 1px solid #fff;
        .btn {
          padding: 0 0.75rem;
        }
        .btn-name {
          font-size: 0.8rem;
          color: #e17de8;
          width: 150px;
          margin: 0;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .btn-caret {
          font-size: 1rem;
          color: #fff;
        }
      }
      /*.dropdown-toggle::after {
        display: flex;
        color: #fff;
        font-size: 1.5rem;
        vertical-align: 0.1em;
      } */
      .dropdown-menu {
        background-color: black;
        font-size: 0.8rem;
      }
      hr {
        margin: 0 1.25rem 0.8rem 1.25rem;
        border-top: 1.5px solid#fff;
        opacity: 0.75;
      }
      .main-drop {
        text-transform: uppercase;
        font-weight: 600;
        color: #fff;
        padding: 0.8rem 1.2rem;
      }
      .disabled {
        color: #929292;
      }
      .collective {
        padding-left: 1.5rem;
        font-style: italic;
        color: #fff;
      }
    }
  }
}
</style>
