<template lang="pug">
#Entries
    a.feminicidios(href="feminicidios")
      img.section(src="/assets/Home/Foto-Cruces-Arroyo-del-Navajo-1-_Rey-R.-Jauregui_LaVerdad_Cruces_2.png" alt="cruces de ciudad Juárez")
      h3.title Feminicidios:
      p.sub Más que matar porque puedo,
        br
        span matar para ser alguien
      p.place Juárez, el laboratorio
      p.author por Daniela Pastrana
    .line1
     img(src="/assets/Home/Camino_1.svg" alt="línea punteada hacia el siguiente reportaje")
    a.precarizacion.justify-content-center(href="precarizacion")
      img.section(src="/assets/Home/Precarización_Landing_1.png" alt="hogar de víctima de feminicidio")
      h3.title.text-end La precarización de la vida y el recrudecimiento de la violencia
      //- p.sub.text-end y el recrudecimiento de la violencia
      p.author.text-end por Lydiette Carrión
    .line2
     img(src="/assets/Home/Camino_2.svg" alt="línea punteada hacia el siguiente reportaje")
    a.desapariciones(href="desapariciones")
      img.section(src="/assets/Home/contador_desaparicion_pamelagallardo_landing.png" alt="contador de días en desaparición")
      h3.title Desapariciones:
      p.sub Mantenerlas vivas
        br
        span para explotar su cuerpo
      p.place Valle de México, una biopsia del país
      p.author por Daniela Rea
    .line3
     img(src="/assets/Home/Camino_3.svg" alt="línea punteada hacia el siguiente reportaje")
    .collectivesTitle
      h2 Colectivas
      p por Vania Pigeonutt
    .container-mb.px-4
      .row
        a.feminicidios-mb.d-flex.flex-column.justify-content-center.align-items-center(href="feminicidios")
          img.section(src="/assets/Home/Foto-Cruces-Arroyo-del-Navajo-1-_Rey-R.-Jauregui_LaVerdad_Cruces_2.png" alt="cruces de ciudad Juárez")
          h3.title Feminicidios:
          p.sub Más que matar porque puedo,
            br
            span matar para ser alguien
          p.place Juárez, el laboratorio
          p.author por Daniela Pastrana
        .line1-mb.d-flex.justify-content-end
          img(src="/assets/Home/Camino_1_Mobile.svg" alt="línea punteada hacia el siguiente reportaje")
        a.precarizacion-mb.d-flex.flex-column.justify-content-start.align-items-start(href="precarizacion")
          img.section(src="/assets/Home/Precarización_Landing_1.png" alt="hogar de víctima de feminicidio")
          h3.title La precarización de la vida y el recrudecimiento de la violencia
          //- p.sub y el recrudecimiento de la violencia
          p.author por Lydiette Carrión
        .line2-mb.d-flex
          img(src="/assets/Home/Camino_2_Mobile.svg" alt="línea punteada hacia el siguiente reportaje")
        a.desapariciones-mb.d-flex.flex-column.justify-content-center.align-items-center(href="desapariciones")
          img.section(src="/assets/Home/contador_desaparicion_pamelagallardo_landing.png" alt="contador de días en desaparición")
          h3.title Desapariciones:
          p.sub Mantenerlas vivas
            br
            span para explotar su cuerpo
          p.place Valle de México, una biopsia del país
          p.author por Daniela Rea
        .line3-mb.d-flex.justify-content-center
          img(src="/assets/Home/Camino_3_Mobile.svg" alt="línea punteada hacia el siguiente reportaje")

</template>
<script>
export default {
  name: "Entries",
  setup() {}
};
</script>
<style lang="scss" scoped>
#Entries {
  a {
    text-decoration: none;
  }
  .section {
    padding: 1rem;
    visibility: hidden;
    opacity: 0;
    transition: visibility 1s linear 1s, opacity 1s linear;
  }
  .revealed {
    visibility: visible;
    opacity: 1;
    transition-delay: 0s;
  }
  background-image: url("/assets/Landing_Desierto.png"),
    linear-gradient(transparent 20%, #e5e5e5 80%);
  background-repeat: no-repeat;
  height: 2400px;

  background-size: 100% 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 0px 0px;
}
.container-mb,
.feminicidios-mb,
.precarizacion-mb,
.desapariciones-mb,
.line1-mb,
.line2-mb,
.line3-mb {
  display: none;
}
.feminicidios {
  grid-area: 3 / 6 / 5 / 10;
  padding-left: 1rem;
  img {
    width: 90%;
    max-width: 220px;
  }
}
.precarizacion {
  grid-area: 6 / 2 / 7 / 5;
  display: flex;
  flex-direction: column;
  justify-content: end;
  img {
    align-self: flex-end;
    width: 90%;
    max-width: 320px;
  }
}
.desapariciones {
  grid-area: 9 / 7 / 10 / 11;
  img {
    width: 65%;
    max-width: 250px;
  }
}
.line1 {
  grid-area: 3 / 3 / 6 / 6;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  img {
    width: 100%;
    max-width: 280px;
    height: auto;
  }
}
.line2 {
  grid-area: 7 / 2 / 10 / 9;
  display: flex;
  justify-content: end;
  align-items: flex-end;
  img {
    width: 100%;
    height: auto;
    max-height: 520px;
  }
}
.line3 {
  grid-area: 10 / 6 / 13 / 8;
  display: flex;
  justify-content: center;
  align-items: start;
}
.collectivesTitle {
  grid-area: 12 / 3 / 13 / 6;
  color: #303030;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h2 {
    font-weight: 900;
    text-transform: uppercase;
    font-size: 2.7rem;
    margin: 0;
  }
  p {
    font-weight: bold;
    font-style: italic;
    font-size: 1.5rem;
  }
}
.title {
  font-weight: 800;
  color: #fff;
  font-size: 2rem;
  margin: 0;
  line-height: 1.1;
}
.sub {
  color: #fff;
  font-size: 1.7rem;
  font-weight: 600;
  margin: 0;
  line-height: 1.1;
}
.place {
  color: #000;
  font-style: italic;
  font-weight: 700;
  font-size: 1.6rem;
  margin: 0;
  line-height: 1.1;
}
.author {
  color: #000;
  font-style: italic;
  font-weight: 700;
  font-size: 1.3rem;
  margin: 0;
  line-height: 1.1;
}
@media (min-width: 1400px) {
  #Entries {
    background-size: 100% 100%;
  }
}
@media (max-width: 769px) {
  #Entries {
    height: 1550px;
    .collectivesTitle {
      display: none;
    }
    .title {
      font-size: 1.5rem;
    }
    .sub {
      font-size: 1.2rem;
    }
    .place {
      font-size: 1.1rem;
    }
    .author {
      font-size: 1.1rem;
    }
    .feminicidios {
      img {
        max-width: 150px;
      }
    }
    .precarizacion {
      grid-area: 6 / 2 / 7 / 6;
      display: flex;
      flex-direction: column;
      justify-content: end;
      img {
        align-self: flex-end;
        width: 90%;
        max-width: 320px;
      }
    }
    .desapariciones {
      grid-area: 9 / 7 / 11 / 11;
      img {
        width: 65%;
        max-width: 250px;
      }
    }
    .line1 {
      grid-area: 3 / 4 / 6 / 6;
      img {
        width: 100%;
        max-width: 360px;
        height: auto;
      }
    }
    .line2 {
      grid-area: 8 / 2 / 10 / 8;
      img {
        width: 100%;
        height: auto;
        max-height: 300px;
      }
    }
    .line3 {
      grid-area: 10 / 6 / 13 / 8;
      display: flex;
      justify-content: start;
      img {
        align-self: flex-start;
        width: 100%;
        height: auto;
        max-height: 280px;
      }
    }
  }
}
@media (max-width: 420px) {
  #Entries {
    background-image: url("/assets/Landing_Desierto_Mobile.png"),
      linear-gradient(transparent 30%, #e5e5e5 70%);
    display: flex;
    align-items: center;
    background-size: 100% 99.99%;
    /* background-size: cover; */
    .feminicidios,
    .desapariciones,
    .precarizacion,
    .line1,
    .line2,
    .line3 {
      display: none;
    }
    .container-mb {
      display: block;
    }
    .feminicidios-mb {
      img {
        max-width: 150px;
      }
    }
    .line1-mb {
      margin-top: -20px;
    }
    .precarizacion-mb {
      img {
        max-width: 250px;
      }
    }
    .desapariciones-mb {
      img {
        max-width: 200px;
      }
    }
  }
}
</style>
