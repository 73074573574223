<template lang="pug">
#Entry5
  Navbar
  EntryHeader(v-bind="entryHeader")
  FullQuote.text-center.italic(v-bind="fullQuotes.first")
  AudioPlayer(:src="audio" :voice="voice")
  // block1
  .white-bg
    .paragraph.textBlock.pb-0
      p.text.px-1.mb-4 Es septiembre de 2019 y mujeres de la colectiva Siempre Vivas acompañan las largas jornadas del juicio por su feminicidio. &nbsp;
        span.bold Bordan el rostro, nombre y sueños de Lesvy en una gran manta  y tejen, al mismo tiempo, sus historias.
  TextBlock.pt-0(:text="block2")
  .container-fluid.white-bg
    .row.d-flex
      .col-sm-12.col-md-7.px-0
        img.responsive.section(src="/assets/Entry5/DSC05013.png" alt="madre y padre de Lesvy sostienen un bordado en memoria de su hija")
        .label-img.justify-content-center
          p Araceli Osorio y Lesvy Rivera, madre y padre de Lesvy Berlín Rivera Osorio sostienen uno de los bordados realizados en memoria de su hija. Las mantas que las Siempre Vivas hicieron les acompañan en todas sus acciones por conseguir justicia para su hija.
      .textColumn.col-sm-12.col-md-5.d-flex
        TextBlock(:text="block3")
  TextBlock(:text="block4")
  FullQuote(v-bind="fullQuotes.second")
  Quote.bolder.text-center(id="e5q1" :quote="quotes.first" transition="transition-3s" size="md-width")
  TextBlock(:text="block5")
  .container-fluid.white-bg
    .row
      .col-sm-12.col-md-6.pt-2
        img.responsive(src="/assets/Entry5/DSC03818.JPG" alt= "bordado de Ingrid Escamilla y Fátima Cecilia")
      .col-sm-12.col-md-6.pt-2
        img.responsive(src="/assets/Entry5/DSC03819.JPG" alt= "bordado de Lesvy Berlín")
  .label-img.justify-content-center
          p Bordados por las Siempre Vivas de Ingrid Escamilla, Fátima Cecilia y Lesvy Berlín Rivera Osorio, víctimas de feminicidios. Estos bordados forman parte de la manta de la memoria, una apuesta colectiva que han ido construyendo a lo largo de los años.
  TextBlock(:text="block6")
  SectionHeaderDark(:title="sectionHeaders.first" color="purple-color")
  TextBlock(:text="block7")
  .white-bg
    .container
      .row
        .col-sm-12.col-md-6.pt-2
          img.responsive.max-height(src="/assets/Entry5/DSC05236.png" alt="integrante del colectivo mostrando su bordado")
        .col-sm-12.col-md-6.pt-2
          img.responsive.max-height(src="/assets/Entry5/DSC05246.png" alt="integrante del colectivo mostrando su bordado")
      .row
        .col-sm-12.pt-2
          img.responsive(src="/assets/Entry5/DSC05317.png" alt="integrante del colectivo mostrando su bordado")
      .label-img.justify-content-center
          p Fernanda Montiel, Alejandra López y Denisse Almaguer accionan juntas desde el bordado desde hace más de dos años. En el bordado encontraron una herramienta de memoria y de justicia al recuperar, con agujas e hilos, la identidad que la violencia, el morbo y el amarillismo arrebata a las mujeres en México.
  TextBlock(:text="block8")
  .white-bg
    .divQuote.textBlock
      Quote.semibold.text-center(id="e5q2" :quote="quotes.second" transition="transition-3s" size="md-width")
  TextBlock(:text="block9")
  SectionHeaderDark(:title="sectionHeaders.second" color="purple-color")
  TextBlock(:text="block10")
  .container-fluid.px-0
    #e5i7.full-bg.section
    //- img.responsive(src="/assets/Entry5/DSC05370.png")
  .label-img.justify-content-center
    p Layla Vázquez sostiene el rostro bordado de la activista trans Vanessa Flores. Layla comenzó a trabajar en la fiscalía de feminicidios donde incide activamente para que los transfeminicidios sean atendidos con respeto y con perspectiva de género. Debido a esto ya no pudo continuar dentro de la colectiva por la cantidad de trabajo a la que se enfrenta en su día a día.
  TextBlock(:text="block11")
  TextBlockLg.bolder(:text="block21")
  TextBlock(:text="block12")
  SectionHeaderDark(:title="sectionHeaders.third" color="purple-color")
  TextBlock(:text="block13")
  .container-img-large
    img.responsive.section(src="/assets/Entry5/DSC05348.png" alt="bordado de la activista trans Alessa Flores")
  .label-img.justify-content-center
    p.w-1000 La activista trans Alessa Flores fue asesinada en 2016. A cinco años el esclarecimiento de su transfeminicidio sigue pendiente.
  TextBlock(:text="block14")
  TextBlockLg.bold(:text="block15")
  TextBlock(:text="block16")
  SectionHeaderDark(:title="sectionHeaders.fourth" color="purple-color")
  TextBlock(:text="block17")
  .container-fluid.white-bg
    .row.d-flex
      .col-sm-12.col-md-7.px-0
        img.responsive.section(src="/assets/Entry5/DSC05091.png" alt="integrante del colectivo mostrando su bordado")
        .label-img.justify-content-center
          p.w-1000 Karen Rodríguez sostiene su bordado en el que plasmó los rostros de Alejandra Negrete, Mile Martín, Nadia Vera, Rubén Espinosa y Yesenia Quiroz asesinados en la colonia Narvarte en 2015.
      .textColumn.col-sm-12.col-md-5.d-flex
        TextBlock(:text="block18")
  TextBlock(:text="block19")
  FullQuote(v-bind="fullQuotes.third")
  TextBlock(:text="block20")
  Pagination(:prev="prevEntry" :next="nextEntry")
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import AudioPlayer from "@/components/AudioPlayer.vue";
import TextBlock from "@/components/TextBlock.vue";
import TextBlockLg from "@/components/TextBlockLg.vue";
import EntryHeader from "@/components/EntryHeader.vue";
import SectionHeaderDark from "@/components/SectionHeaderDark.vue";
import FullQuote from "@/components/FullQuote.vue";
import Quote from "@/components/Quote.vue";
import Pagination from "@/components/EntryPagination/Pagination.vue";
import { fifthEntryText } from "@/assets/FifthEntry.js";
import { fourthTitle } from "@/assets/FourthEntry.js";
import { sixthTitle } from "@/assets/SixthEntry.js";

export default {
  name: "FifthEntry",
  components: {
    TextBlock,
    TextBlockLg,
    EntryHeader,
    SectionHeaderDark,
    FullQuote,
    Quote,
    Pagination,
    Navbar,
    AudioPlayer
  },
  setup() {
    const prevEntry = fourthTitle;
    const nextEntry = sixthTitle;
    const audio = "https://s3.amazonaws.com/media.reportajes.fordfoundation.org/SiempreVivas_Vania_EreDervez.m4a"
    const voice = "Eréndira Derbez";
    const [
      block1,
      block2,
      block3,
      block4,
      block5,
      block6,
      block7,
      block8,
      block9,
      block10,
      block11,
      block12,
      block13,
      block14,
      block15,
      block16,
      block17,
      block18,
      block19,
      block20,
      block21,
      entryHeader,
      sectionHeaders,
      fullQuotes,
      quotes
    ] = fifthEntryText;
    return {
      block1,
      block2,
      block3,
      block4,
      block5,
      block6,
      block7,
      block8,
      block9,
      block10,
      block11,
      block12,
      block13,
      block14,
      block15,
      block16,
      block17,
      block18,
      block19,
      block20,
      block21,
      entryHeader,
      sectionHeaders,
      fullQuotes,
      quotes,
      prevEntry,
      nextEntry,
      audio,
      voice
    };
  }
};
</script>
<style lang="scss">
#Entry5 {
  #Header {
    .header-bg {
      background-image: url("/assets/Entry5/DSC05183.png");
    }
    /* background-image: url("/assets/Entry5/DSC05183.png");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    height: 200vh;
    margin: auto auto 5rem auto; */
  }
  img.section {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s ease 1s, opacity 800ms ease, filter 0.5s ease-in-out;
    filter: blur(1px);
  }
  img.section.revealed {
    filter: blur(0);
    visibility: visible;
    opacity: 1;
    transition-delay: 0s;
  }
  .full-bg.revealed {
    background-color: rgba(#fff, 0);
  }
  .textColumn {
    padding: 0rem 5rem;
  }
  .divQuote {
    max-width: 500px;
  }
  .intro {
    max-width: 750px;
    font-weight: 600;
    p {
      font-size: 1.5rem;
    }
    span {
      font-size: 1.2rem;
    }
  }
  #e5i7 {
    background-image: url("/assets/Entry5/DSC05370.png");
  }
}
@media (max-width: 420px) {
  #Entry5 {
    .textColumn {
      padding: 4rem 0.25rem 0rem 0.25rem;
    }
    #e5i7 {
      background-position: center;
    }
  }
}
</style>
