const block1 = [
  {
    text:
      "Fue en el año 2007. Si miramos la gráfica de las desapariciones de mujeres entre el 2000 y 2017, según los registros hechos en ese periodo por el Centro de Planeación, Análisis e Información para el Combate a la Delincuencia (CENAPI), fue justo en el año 2007 cuando este delito casi se sextuplicó. De 242 mujeres desaparecidas en el 2006, pasó a 1429 un año después."
  },
  {
    text:
      "Y luego seguiría creciendo, año con año, hasta llegar a un pico máximo en el 2014 para luego quedar en espera de lo que nosotros, personas, país, decidamos hacer para proteger (o no) la vida de nosotras, las mujeres."
  },
  {
    text:
      "¿Qué hay detrás de las estadísticas sobre desapariciones de mujeres? ¿Qué problemas sociales evidencian los números? ¿Es un fenómeno distinto la desaparición de mujeres respecto a la de hombres? ¿Quién las desaparece? Si se van con sus novios o se van de sus casas ¿qué tanto es un problema personal, privado, y qué tanto parte de un país o una sociedad descompuesta? Cuando se trata de violencia contra mujeres ¿qué podemos entender desde las desapariciones, frente a lo que nos dicen los feminicidios? ¿Qué explica que se hayan disparado estos crímenes?"
  },
  {
    text:
      "“A partir de la militarización comienza una violencia exponencial, un aumento de crímenes contra mujeres. A los feminicidios y su continuum de violencia se le suma la desaparición”, dice Julia Monárrez quien ha estudiado la violencia contra las mujeres desde los feminicidios muy localizados en Ciudad Juárez hasta las desapariciones que se esparcen por todo el país."
  }
];
const block2 = [
  {
    text:
      "Basta con que el cuerpo de una mujer sea expuesto con una cartulina para que se asuma como “narcomensaje” y por lo tanto se borre del crimen su condición de mujer."
  },
  {
    text:
      "En una mirada panorámica -y con todas las limitaciones y sesgos que implica-, en un intento de entender cómo coexisten el asesinato y la desaparición de las mujeres, la línea de tiempo de violencia contra mujeres entre el 2000 y 2019 nos dice:"
  }
];
const block3 = [
  {
    text:
      "El asesinato de mujeres ha mantenido una continuidad a lo largo de estos 17 años que oscila entre el 7 y 11 por ciento del total de muertes violentas en el país. Esto nos habla de una violencia estructural que refleja las relaciones históricas y de poder, de la deshumanización de las mujeres, de un sistema de violencia que se ha instalado y se reproduce constantemente en cuanto al porcentaje del total nacional y en cuanto a los grupos de edad que más se matan (20-44 años rango de edad de las mujeres víctimas), dice Emanuela Borzacchiello, académica feminista. Una violencia latente que mantiene una sociedad en constante violencia y amenaza para permitir, posibilitar que otros delitos, como la desaparición, existan."
  },
  {
    text:
      "La desaparición de mujeres tiene un porcentaje de participación en el total nacional mucho mayor al del asesinato de mujeres y presenta movimientos más bruscos: las desapariciones se mueven del 25 % hasta un 50 % del total nacional. Sobre esto habría que decir que las mujeres representan un porcentaje importante de las personas desaparecidas localizadas, y un porcentaje menos importante de las no localizadas. La desaparición parte de esa misma deshumanización de las mujeres, pero su objetivo podría ser la explotación económica y el amasamiento de un capital criminal. Para entender la desaparición hay que hacerse la pregunta ¿para qué sirven, a quién le sirven las mujeres desaparecidas? A un sistema que busca usar a las mujeres, no asesinarlas. "
  }
];
const block4 = [
  {
    text:
      "El 21 de octubre de 2019 Mónica Rojas salió de su casa en Tlalnepantla y fue a su trabajo en una comercializadora de plásticos. Llegó a las 9:30 más o menos y ya no salió de ahí."
  },
  {
    text:
      "De acuerdo con sus familiares, la última vez que esta joven de 30 años se comunicó con ellos fue a las 18:00 horas de ese día. Su turno terminaba a las 19:00 horas, sin embargo, no llegó a su domicilio en la zona."
  },
  {
    text:
      "Mónica estuvo desaparecida desde entonces hasta octubre del 2020 que su cuerpo fue encontrado. Sus padres Gonzalo Rojas y Guadalupe Luna la buscaron desde esa noche que desapareció. Alrededor de las 9 de la noche recibieron una llamada de la pareja de Mónica preguntando por ella pues no había llegado a casa, tenía un retraso de dos horas. La familia de Mónica le marcó al teléfono y no tenía señal."
  },
  {
    text:
      "En ese momento don Gonzalo fue a la empresa a buscarla y, a través del interfon, el vigilante le dijo que su hija no había ido a trabajar, que ya había ido su pareja a preguntar y le había dicho lo mismo. Al día siguiente Gonzalo volvió a la empresa y el vigilante de ese turno le dijo que Mónica sí había ido, pues ella nunca faltaba. Don Gonzalo también pudo hablar con uno de los compañeros del trabajo y él le confirmó que sí, que llegó como a las 9:30  y que se quedó ahí en la empresa después de que casi todos se fueron, a las 6 de la tarde."
  },
  {
    text:
      "Don Gonzalo intentó contactar a los jefes, no pudo hasta ya tarde. Esta mañana no fueron a la oficina. Por la tarde pudo hablar por teléfono con uno de los dueños. “Me dijo que no sabía nada, que se fue, que dentro de la empresa no pasó nada pero de lo que pase afuera no sabe nada. El señor fue muy indiferente”. "
  },
  {
    text:
      "La policía indagó a la pareja de Mónica y éste comprobó con fotos y documentos de la empresa que el día de la desaparición él estaba en su trabajo. Ahí terminó la investigación. El rastreo de la familia y la autoridad se dirigió a otro lado."
  },
  {
    text:
      "“En su trabajo le gritaban, la ofendían, no le pagaban. Ese día de la desaparición no la dejaron salir a comer, hubo maltrato laboral, mucho. Violencia laboral por esta relación de poder sobre ella porque ella manejaba las finanzas”, dice su padre. "
  },
  {
    text:
      "Id(h)eas, organización que acompaña a la familia, insistió desde los primeros momentos en la hipótesis de la posible agresión en su lugar de trabajo, el último lugar en el que se le vio con vida. Después de solicitar la práctica de diligencias como el análisis forense en el lugar de trabajo, análisis de líneas telefónicas, mapeos e información que se establece en el Protocolo Homologado de Desaparición, se coordinó con la Fiscalía del Estado de México y se pudo establecer la presunta responsabilidad de tres personas."
  },
  {
    text:
      "Al día de hoy una persona está detenida por la desaparición de Mónica y existen órdenes de aprehensión contra otras dos."
  },
  {
    text:
      "En octubre del 2020 fue encontrado el cuerpo de Mónica en un lugar distante de su lugar de trabajo y fuera de la Ciudad de México. Por ello se inició una carpeta por el delito de feminicidio, que deberá investigarse de manera conjunta con la desaparición previa."
  }
];
const block5 = [
  {
    text:
      "La señora Sandra Luz Román hace un recuento de la violencia que sufría su hija, antes de ser desaparecida:"
  },
  {
    text:
      "“Respecto a mi hija la vida que llevó con esa gente, porque vivió con su pareja, era mucha violencia. Decía mi hija que él pateaba las puertas, les dejaba hoyos, tenía pajaritos que cantan y los agarraba y los aventaba en la alberca, se ahogaban los pajaritos, yo pensaba si a ella le hacía lo mismo. Me la trataba mal, siempre fue así. Violencia mi hija sí tuvo. En el sótano de su casa escuchaba llantos, gritos, ‘yo no sé ahí qué había”, me decía mi hija. Una vida sana no la llevó, violencia sí la tuvo siempre. Ella siempre fue vigilada en su casa”. Melissa tuvo una hija con su pareja."
  },
  {
    text:
      "Melissa Flores fue desaparecida a sus 19 años. La madrugada del 24 de octubre del 2012 un grupo de hombres armados y encapuchados vinculados a la policía de Iguala entraron a su casa, donde estaba con su hermano y su cuñada. Los hombres llegaron preguntando “quién es Melissa” y ella respondió que esa mujer no se encontraba ahí. Los hombres se llevaron a Melissa y su cuñada, con cuatro meses de embarazo (la cuñada sería liberada cuatro días después con otra mujer que también había sido privada de su libertad)."
  },
  {
    text:
      "“Ella (la cuñada) nos alcanza a decir que en esos días (de la privación de la libertad) hubo episodios de violencia, nos dio a entender que violencia sexual”, dice Sandra. Nunca se recibió llamada de rescate."
  },
  {
    text:
      "La familia de Melissa fue a pedir ayuda a la policía de Iguala para su búsqueda, pero le respondieron que estaban acuartelados y no podían hacer nada por ella. Casi cuatro años después el MP de Iguala atendió las solicitudes de la familia de búsqueda. Pero no ordenó diligencias a buscarla. Actualmente la investigación se lleva a nivel federal y los abogados de Id(h)eas presentaron el caso ante la Convención sobre la Eliminación de toda forma de Discriminación contra la Mujer (CEDAW) para exigir al gobierno mexicano que busque a Melissa."
  },
  {
    text:
      "“Por la mínima vinculación con el crimen organizado se descarta el factor género y eso es peligroso en términos del sesgo y criminalización de las víctimas.  Y hay que entender que las mujeres son sometidas, violentadas dentro del crimen organizado, siempre hay violencia de género”, dice Arely Varela, abogada."
  },
  {
    text:
      "En la desaparición de Melissa se cruza la violencia intrafamiliar y la violencia criminal-estatal. Por esas mismas fechas, dice Arely Varela, la abogada de Id(h)eas, otras jóvenes fueron desaparecidas, torturadas. Y aun así, la desaparición de Melissa no se investigó -tampoco las otras- con una perspectiva de género."
  }
];
const block6 = [
  {
    text:
      "Julia Monárrez dice que en estas violencias “confluyen grupos armados estatales y paraestatales, en donde se ha creado un discurso oficial que minimiza el impacto de la violencia en la vida de las mujeres”. Bajo este discurso se representa a las mujeres víctimas como acompañantes sentimentales de criminales, delincuentas, daños colaterales o chicas desafortunadas que se fueron con el novio. Y, por lo tanto, el crimen se explica o justifica en esa condición de la mujer."
  },
  {
    text:
      "Contar una desaparición es un acto complejo. Porque se trata de contar lo que no se ve, no se sabe. Pero en México además hay que echar mano de datos y relatos para poder desmenuzar la narrativa común de que “las (o los) desapareció el narco”, “se los tragó la tierra”. Una noción que justifica el crimen y la no búsqueda; que impide generar conocimiento para comprender y prevenir más desapariciones."
  }
];
const block7 = [
  {
    text:
      "De las mujeres que fueron desaparecidas entre el 2000 y 2020, el 58% son niñas y adolescentes entre 10 y 19 años y asciende a 76% del total de mujeres desaparecidas si contamos hasta los 24 años. Es decir niñas, adolescentes, jóvenes. A diferencia de las desapariciones de hombres en las cuales sólo el 19.1% son de hombres de entre 10 y 19 años de edad."
  }
];
const block8 = [
  {
    text:
      "De acuerdo con el análisis de Data Cívica hecho con el CENAPI, las mujeres son localizadas con vida en una mayor proporción que los hombres: mientras el 78% de las mujeres desaparecidas en ese periodo se encontraron vivas, en el caso de los hombres fue el 46%. El porcentaje de personas desaparecidas encontradas sin vida es del 1% en el caso de mujeres y del 6% en el caso de los hombres."
  },
  {
    text:
      "Las mujeres también son localizadas con vida más rápido que los hombres. En promedio, en 3.7 meses después de la desaparición, mientras que a ellos los encuentran en 4.7 meses."
  }
];
const block9 = [
  {
    text:
      "Acerquémonos a lo que sucede con las mujeres que son aparecidas vivas:"
  },
  {
    text:
      "Sobre la edad: Las adolescentes de entre 10 y 19 años representan la mitad de las mujeres desaparecidas y en proporción son también ellas las que aparecen vivas con mayor frecuencia. De acuerdo con datos anteriores, del CENAPI, son también las adolescentes de este rango de edad  las que más rápido fueron encontradas vivas: casi la mitad de ellas se encuentra en una semana, el resto en tres meses."
  }
];
const block10 = [
  {
    text:
      "Norma Andrade fundó la organización Nuestras Hijas de Regreso a Casa a partir de la desaparición de su hija Alejandra en el año 2001, en Ciudad Juárez; aunque fue encontrada muerta 8 días después, los captores la mantuvieron viva 6 días y luego la mataron."
  },
  {
    text:
      "Desde el acompañamiento a otros casos de desaparición en esa ciudad fronteriza y recientemente en el Valle de México, Norma dice que “hay niñas que se van por su voluntad con el niño, la pareja, o con otros; que se van huyendo de la violencia que hay dentro de casa”."
  },
  {
    text:
      "“Cuando las fiscalías nos dicen que ‘se fueron con el novio’ ponemos el grito en el cielo, pero sí es cierto, a veces se van. No hay que rasgarnos las vestiduras, mejor hay que reconocerlo y analizar ¿qué está pasando con esa juventud que está dejando sus casas? ¿qué impide a las jóvenes permanecer en sus casas seguras?”, dice Monárrez. “Necesitamos pensar en una política nacional integral que vea a la desaparición en toda su diversidad, no la hay”."
  },
  {
    text:
      "Norma Andrade dice que es posible identificar cuando las adolescentes se van por su propio pie: se llevan ropa, artículos personales, dejan algún mensaje o se lo comentan a alguna amiga. “Pero lo cierto es que el gran número de niñas que desaparecen son jovencitas que se las roban, que son privadas de su libertad y que algunas se logran localizar en redes de trata, otras más son encontradas asesinadas y un margen muy grande que no son localizadas”, aclara."
  },
  {
    text:
      "“¿Por qué regresan?” Se pregunta Julia Monárrez. “Eso es algo muy importante a investigar, pero tenemos tan mala información por parte de fiscalías que no sabemos por qué regresan, cómo, en qué condiciones”."
  },
  {
    text:
      "A partir de su trabajo en la Fiscalía Especializada para la Investigación de Personas Desaparecidas, y ahora en la Comisión Estatal de Búsqueda de Personas en el Estado de México, Salgado dice que muy pocas veces las jóvenes “quieren hablar” después de ser recuperadas o de haber vuelto de manera “voluntaria”. Si fueron víctimas de algo, probablemente no lo dirán por muchas razones: porque hubo “enamoramiento” y están “convencidas” de que no son víctimas; porque tienen vergüenza de lo que les hicieron o lo que vivieron; porque si estuvieron en redes de delincuencia el temor es mayúsculo: ¿cómo voy a decir que estuve tres meses vendiendo droga? ¿cómo se lo voy a decir a mis papás, al MP? ¿cómo después de que supe lo que hacen los narcos, como los voy a denunciar?"
  },
  {
    text:
      "Sí. Se van las niñas, las adolescentes, las mujeres jóvenes e incluso de edad mediana, se van. A veces se van por una discusión familiar, o porque en casa no les permiten cierta relación. Muchas otras veces se van por violencia; o porque, en el caso de las adolescentes, no les permiten crecer o las agobian con obligaciones que no son suyas. Muchas se van y regresan. Y eso no quiere decir que no hayan sido víctimas."
  }
];
const block11 = [
  {
    text:
      "Marzo de 2013. Susana desaparece. Tiene 15 años, pero los cumplió un mes atrás. No hubo fiesta, porque está presionada estudiando para ingresar al bachillerato.  Es buena alumna, quiere ser profesora de educación física para niños con discapacidad. En general Susana es lo que la gente suele calificar como “buena niña”: le gusta el deporte y es destacada; por las tardes trabaja en la cocina económica familiar."
  },
  {
    text:
      "Pero una tarde, el abuelo, su jefe directo, se pone pesado. Hay algún disgusto y aquél comienza a romper los platos contra la pared. Susana, enojada, se va. No regresa a casa, no quiere ver a sus padres, ni estudiar para el examen. Está dolida y harta; se dirige entonces a un centro comercial y se sienta en una banca. Ahí se encuentra con un amigo y le cuenta sus problemas. Los padres no saben con quién habló Susana, sólo saben que no llegó a casa, no lleva celular y no es problemática."
  },
  {
    text:
      "A las nueve  de la noche, la hija llama. Habla, dice, desde una caseta telefónica, dice que fue a la colonia Condesa a buscar trabajo. Que ya no trabajará más en la cocina económica. Que no se preocupen, va de vuelta a casa. Diez minutos después llama de nuevo y dice que está por meterse al metro, que va con amigos que la acompañarán a casa.  Pero ella no llega esa noche ni al día siguiente."
  },
  {
    text:
      "La familia interpone la denuncia en el ministerio público de Naucalpan. Problemas: la desaparición debió haber ocurrido en el entonces Distrito Federal. Contrario a otros casos, las autoridades sí investigan. Analizan los dos números desde donde marcó: ninguno pertenece a una caseta telefónica. El primero “rebota” (por las antenas) en un edificio de la calle Chilpancingo. El segundo, cerca del metro Chapultepec."
  },
  {
    text:
      "La familia teme lo peor. Por suerte, tres semanas después  Susana regresa por propio pie."
  },
  {
    text: "¿Qué fue lo que pasó?"
  },
  {
    text:
      "Esta es la voz de Susana. Aquel día del centro comercial, la adolescente fue efectivamente a solicitar trabajo con su amigo. Fueron a unas oficinas situadas en la colonia Hipódromo Condesa. El empleo era como vendedora  de frutas con limón y chile en carrito, recorriendo los parques y calles de la ciudad. Aceptó."
  },
  {
    text:
      "Cuando llamó y dijo que iba de vuelta a casa, era genuinamente su  intención. Pero una vez que colgó el teléfono, sus compañeros le dijeron que “era muy tarde” para acompañarla hasta Naucalpan y que mejor pasara la noche en una casa que les prestaban a los vendedores de la empresa, en su mayoría adolescentes y jóvenes fugados de sus casas o en condición de calle. Así lo hizo. Al día siguiente temprano comenzó a trabajar empujando su carrito y vendiendo por las calles; no parecía su trabajo ideal, pero continuaba enojada con su familia y quería demostrarles que podía ser independiente.  Por la tarde le pagaron el día trabajado y tuvo dinero por primera vez en su vida (en la cocina económica ella no tenía sueldo,  el dinero era administrado por sus familiares). Se quedó de nuevo con sus compañeros de trabajo: una chica de 16 embarazada; otra niña cuyo padrastro y hermanastro abusaban de ella, un chico con problemas familiares."
  },
  {
    text:
      "Todos hablaban de sus problemas, de las violencias sufridas; y la necesidad de probar que sí podían salir adelante solos. Pero las condiciones dieron un giro: a los 3, 4 días, la empresa dejó de pagarles, aunque ellos seguían trabajando. Sus jefes prometían que pronto pagarían. Mientras, a los chicos los corrieron de la casa prestada y tuvieron que dormir en un estacionamiento. Apenas juntaban para comer. Y ella no sabía cómo regresar a casa, le daba pena, miedo, y no tenía para el pasaje. Pareciera algo sencillo: pedir prestados 20 pesos y regresar a casa; pero no es así. El cansancio, la confusión y el miedo no hacen fácil para una niña de 15 pedir la ayuda que necesita."
  },
  {
    text:
      "Durante las siguientes semanas sufrirá violencia, no sólo laboral, sino de género y sexual."
  },
  {
    text:
      "Tres semanas después, un chico que trabaja con ella se guarda el dinero de la venta y la lleva hasta su casa. Otro chico en condición de calle que la ayudó a regresar."
  }
];
const block12 = [
  {
    text:
      "“No siempre las autoridades investigan los casos de las que sí fueron desaparecidas y localizadas”, agrega Emanuela Borzacchiello. “Cierran el caso revictimizándolas diciendo que ‘huyó con el novio’, por un lado; por otro es muy difícil para ellas contar la experiencia de violencia, tanto por la falla institucional como la presión social, que las excluye por todos los estereotipos de una mujer violada en su comunidad: es una mujer fácil, se lo buscó”."
  },
  {
    text:
      "Sol Salgado, como comisionada de búsqueda del Estado de México, sabe que las estadísticas tienen un sesgo: errores en los registros, desapariciones que no son denunciadas."
  },
  {
    text:
      "“Cuando desaparecen dos personas, un joven y una joven, la actitud de las familias es muy diferente por el dominio patriarcal: si es un chico se piensa que quizá se fue con los amigos, con su novia, ‘démosle chance’, la propia familia posterga esa denuncia; en el caso de la mujer, (denuncian) al primer indicio de tardanza de la hora a la que tendría que llegar a casa”."
  }
];
const block13 = [
  {
    text:
      "Ahí radica la diferencia de cuál puede ser su destino: irse con un amigo que pueda tratarla con violencia, con una red que la explote sexualmente, o con alguien que pueda privarle de la vida."
  },
  {
    text:
      "“Cuando se trata de menores de edad es muy fácil mostrar el ilícito, con las mayores de 18 años es más difícil porque el argumento es que ‘se fueron por su voluntad’”, dice Salgado."
  }
];
const block14 = [
  {
    text:
      "Según el reporte de Data Cívica, el 21 por ciento de las mujeres desaparecidas entre 2000 y 2017 aún no han sido localizadas. Sólo el 1 por ciento del total fueron encontradas sin vida. De las que fueron localizadas sin vida, una de cada dos su cuerpo apareció en el primer mes y una de cada 10 después de un año de búsqueda."
  },
  {
    text:
      "Salgado explica que en algunos casos podría haber un vínculo entre asesinatos y desapariciones de mujeres, es decir, mujeres que fueron desaparecidas y que fueron asesinadas pero sus cuerpos o no han sido encontrados o no han sido identificados, y se mantienen en la condición de desaparecidas. “Puede ser que en el Estado de México muchas de las mujeres que estamos buscando se trate de feminicidios”."
  }
];
const block15 = [
  {
    text:
      "-¿Cómo se explican que mujeres que fueron desaparecidas sean encontradas muertas?- se le pregunta a Sol Salgado."
  }
];
const block16 = [
  {
    text:
      "–En algunos casos se les mató porque estaban hablando de más de las actividades ilícitas que ellos realizaban. En el caso de algunas por endeudamiento o que no les hacían rendir cuentas claras. En algunos casos se ha visto que las matan cuando se emprende una búsqueda muy evidente de ellas. Pero también hemos detectado a delincuentes que no les gustan las víctimas boletinadas. Cuando ellos ven que se les está buscando en redes sociales, cuando ven un boletinaje cargado, ellos mismos les dicen ‘vete’, no quieren problemas. Ellos no quieren que se les eche luz con las búsqueda de las chicas."
  },
  {
    text:
      "Aun así en el caso de las mujeres, en la ejecución sigue la connotación sexual y de abuso, previo a que las asesinen. Vejaciones y abusos, actos sexuales degradantes en sus cuerpos. En el caso de los hombres no ocurre."
  },
  {
    text:
      "Arely Varela, abogada feminista, pide ser cautelosa con las cifras que hablan de la aparición de mujeres con vida, pues se corre el riesgo de invisibilizar la desaparición al asumir que la mayoría vuelve viva. “Lo que debemos empujar es la búsqueda de las mujeres, asumirlos como desaparición y no como extravíos. Los números siempre dicen que la mayoría de mujeres regresan porque se reportan las encontradas, las extraviadas. En mi experiencia no he encontrado a mujeres vivas, duramos años buscándolas. Esa no es la realidad, aunque eso reporten los números”."
  },
  {
    text:
      "A las integrantes de la colectiva Nos queremos vivas Neza el acompañamiento a familiares de víctimas de feminicidio les han enseñado cosas: "
  }
];
const block17 = [
  {
    text:
      "“La desaparición es algo mucho más espontáneo, algo como mucho más inesperado, como algo repentino. En el caso de los feminicidios los familiares nos dicen ‘es que yo sospechaba que vivía violencia pero nunca me lo contó’, la familia ata cabos de por qué fue asesinada, producto de violencia que vivieron por años, dentro del seno familiar. No en todos los casos, claro, porque muchos son feminicidios de personas desconocidas, pero la gran mayoría ocurren en el seno familiar”, dice Nayade, integrante de la colectiva. “Pero también hay feminicidios que los cometió gente que no las conocía, que igual puede ser integrante de una banda criminal, o no, como nos enseñó Ciudad Juárez”."
  }
];
const block18 = [
  {
    text: "A esos feminicidios se les llama “feminicidios de ocasión”."
  },
  {
    text:
      "Julia Monárrez retoma la importancia de traer a cuenta el pacto patriarcal que permite estas violencias. “Siempre he considerado que el feminicidio lo comete un hombre conocido y desconocido. Por desconocidos estoy hablando de quienes ingresan en el crimen organizado, de la policía, ejército, desconocido cualquiera. Lo que hace la unión entre el desconocido y conocido es la asociación patriarcal que ambos grupos tienen”, dice."
  },
  {
    text:
      "Norma Andrade lo entiende así: “Las académicas que saben dicen que los hombres matan a las mujeres por la misoginia, pero no me cabe en la cabeza, no me entra cómo una persona puede asesinar a otra persona y menos con esa saña. Tiene mucho que ver con que el hombre cree que la mujer es de su propiedad, que no es libre, pero aun así  es muy difícil comprenderlo”. Norma tuvo que recoger el cuerpo de su hija abandonado en un baldío, envuelto en una cobija y con huellas de tortura sexual y física."
  }
];
const block19 = [
  {
    text:
      "A inicios de 2018, activistas en la ciudad de México comenzaron a registrar un fenómeno que llamaron “desapariciones intermitentes”: mujeres jóvenes que desaparecen por un par de días, un par de semanas, y vuelven a casa; posteriormente volvían a desaparecer."
  },
  {
    text:
      "Rita Canto, activista, y Socorro Damián, abogada,  documentaron a partir de ese año tres casos de estos en la Ciudad de México: dos de las jóvenes eran menores de edad. Una de ellas volvió después de dos desapariciones y ahora intenta hacer su vida, pero no habla de lo que sucedió. Como ellas, Celeste Perosino, una antropóloga forense argentina, hablaba de este tipo de desaparición."
  },
  {
    text:
      "¿Por qué las desaparecen y las devuelven con vida? Se pregunta Emanuela Borzacchiello y ella misma se responde: “Cuando Julia Monárrez escribe su libro sobre feminicidio sexual sistémico, descubrí una cosa que es importantísima: en realidad no quieren exterminar a las mujeres, no quieren apostar por el feminicidio porque les sirven nuestros cuerpos”."
  },
  {
    text: "Les sirven nuestros cuerpos en explotación y trabajo permanente. "
  }
];
const block20 = [
  {
    text:
      "“Creemos que la mayoría de mujeres que buscamos están vivas y están funcionando para nutrir un sector importantísimo de la economía criminal que justo, hay un autor”, dice Rita Canto."
  },
  {
    text:
      "“Nos usan como trabajo doméstico (cocineras, afanadoras dentro de grupos del crimen) y de explotación sexual también, pero no quieren matarnos. Un sistema donde nuestros cuerpos pueden siempre ser trabajo bajo el régimen de amenaza constante, volver siempre con miedo porque cuando quieran nos pueden volver a desaparecer. Para mí lo más impresionante es que nos desaparecen y no. El delito existe y no existe. La desaparición intermitente es un régimen brutal que instala el neoliberalismo: no matarnos pero tenernos siempre en un sistema de muerte”, dice Emanuela."
  },
  {
    text:
      "Sol Salgado les llama desapariciones reincidentes: conforme las jóvenes desaparecen de manera reincidente, se hace más difícil ubicar su paradero. “Primero la encuentras por el GPS de su celular, a la siguiente deja su celular; si la encontraste por red social, a la siguiente usa otro perfil. Cuando vuelven son chicas que vuelven embarazadas o no hablan de lo que sucedió”."
  },
  {
    text:
      "Uno de los problemas más graves en la desaparición intermitente o reincidente, explica Salgado, es que sucede lo que en Pedro y el Lobo: al inicio todos buscan, después ya no. Incluso la misma familia ya no pone denuncia de desaparición."
  },
  {
    text:
      "Una de las víctimas de Juan Carlos Hernández (el Monstruo de Ecatepec) fue una joven de 15 años. La joven desapareció y volvió, después volvió a desaparecer: los abuelos ya no denunciaron pues creyeron que, como en la primera ocasión, andaba por ahí y volvería. En el 2017 cuando identificaron a las víctimas de Hernández, la joven era una de ellas. "
  }
];
const block21 = [
  {
    text:
      "“Los cuerpos son políticos y los cuerpos son económicos”, dice Julia Monárrez. “¿Cuánto vale un cuerpo políticamente? ¿Es un sujeto político o es un sujeto matable? Analizar las violencias contra las mujeres como mujeres nos permite desnudar un crimen económica y políticamente”."
  },
  {
    text:
      "Algo en lo que coinciden las mujeres entrevistadas, que han vivido, estudiado y acompañado desapariciones de mujeres, es que no hay una única explicación a este crimen: adolescentes, jóvenes que se van por violencia intrafamiliar, otras vinculadas a la trata de personas con fines sexuales, la explotación laboral; otras inexplicables (aún). Pero hay algo común en ellas y es la cadena de violencias que forman parte de esa desaparición: violencias enraizadas en un sistema patriarcal, racial."
  }
];
const block22 = [
  {
    text:
      "Mariela Vanessa Díaz Valverde salió de su casa, ubicada en la colonia Fuego Nuevo, Iztapalapa, la mañana del 27 de abril de 2018. Los planes de esta joven de 21  años no podían ser más que ir a la UNAM, donde estudiaba Letras Hispánicas, o a la Biblioteca Vasconcelos."
  },
  {
    text:
      "Cuando su mamá regresó al domicilio no la encontró, por lo que llamó a su celular pero no hubo respuesta. Luego se dirigió a buscarla a la universidad pero ya era muy tarde y las aulas estaban vacías."
  },
  {
    text:
      "“El mismo día que no regresa hicimos una búsqueda personal, porque aún sonaba su celular”, relata Gabriela Díaz, su hermana mayor.  “Fuimos a la UNAM y nada. Como a las 12 de la noche hicimos la denuncia ante un MP en Miguel Ángel de Quevedo, pero ahí lo que nos refirieron es que se tenía que levantar un acta hasta el día siguiente en Capea. Así lo hicimos al día siguiente, mi mamá estuvo desde las 10 de la mañana hasta las 2 de la tarde que la atendieron”."
  },
  {
    text:
      "La mamá de Mariela se presentó ante el Ministerio Público  donde le indicaron llevar el caso ante el Centro de Apoyo a Personas Extraviadas y Ausentes (CAPEA), sin embargo las primeras 72 horas no se realizó ninguna búsqueda, no se activaron los protocolos homologados de búsqueda. Sólo les dieron el volante con la foto de Mariela para que la misma familia lo repartiera."
  },
  {
    text:
      "El día de la desaparición se registró una geolocalización de su celular, marcaba uno de los accesos al Cerro de la Estrella. Sin embargo fue hasta 5 meses después que se hicieron búsquedas en ese lugar con binomios de la policía capitalina, pero no encontraron ningún rastro. Ese lugar, explica Gabriela, está como a 5 minutos de la casa, aunque es un sitio no circulado."
  }
];
const block23 = [
  {
    text:
      "“Se me hizo muy raro que botara la geolocalización hacia el cerro porque no vamos al cerro y tampoco es el paso a ninguna ruta, pero también comprendo que a veces hay fallas en las antenas y se me hizo raro que esa geolocalización botó a las 10 am más o menos. Suponemos, porque tampoco es algo muy preciso, que salió como a las 8 am y dos horas después marca ese registro. Ella no mandó mensaje y eso fue extraño porque siempre que salía a algún lugar avisaba”, relata Gabriela, su hermana."
  },
  {
    text:
      "Dos años después de la desaparición, Gabriela dice que la Fiscalía no tiene línea de investigación, no hay registro de que haya sido vista por alguien. "
  },
  {
    text:
      "Del 2009 al 2017, un total de 312 mujeres fueron registradas como desaparecidas en Iztapalapa, según el CENAPI (que llega hasta ese 2017). En su última actualización, 59 seguían sin aparecer y 253 se encontraron vivas. No hay registro de que se hayan encontrado muertas. Entre las causas posibles de la desaparición en el registro del CENAPI: en 291 casos no se especifica, es decir la autoridad no investigó; 15 de ellas fue “voluntaria”; dos casos por enfermedad mental y un caso por problemas con la pareja."
  },
  {
    text:
      "“Hemos sabido de otros casos de desaparición por los foto-volantes que vemos en la calle, o que la gente nos dice de otros casos cuando repartimos la foto de mi hermana. Por ello hemos exigido  un análisis de contexto, pues se sigue tratando como casos aislados”, dice Gabriela."
  }
];
const block24 = [
  {
    text:
      "Aún quedan muchos espacios por dónde continuar: por ejemplo, qué violencias sufren mayormente las adultas mayores, donde los niveles de feminicidio y desaparición son de los más bajos pero constantes históricamente. O qué nos dicen las diferencias de edades y el ser víctima de desaparición o de feminicidio; o si cambian las violencias sufridas por mujeres si analizamos por regiones el mapa del país."
  },
  {
    text:
      "Los datos hablan y también dejan silencios. Silencios de las estadísticas sesgadas. Silencios de lo que no es registrable. Silencios en los conceptos. Silencios en las adolescentes que se van y no sabemos por qué. Silencios en las que regresan y no se investiga qué les sucedió. Silencios de las familias que callan la violencia puertas adentro. Silencios de las instituciones que nada investigan."
  }
];
const block25 = [
  {
    text:
      "Muchos silencios y muchas ausencias.  ¿Cuántas mujeres nos faltan? ¿Cuál es el tamaño de la ausencia, de todas las mujeres que nos faltan? "
  }
];
const block26 = [
  {
    text:
      "“Sin embargo no todas las mujeres víctimas de feminicidio que hemos encontrado en el Estado de México fueron reportadas como desaparecidas; podemos pensar que fue un feminicidio íntimo y por eso no se reportó la desaparición, mujeres que vivieron contexto de violencia con su pareja, quien sería el más beneficiado en su desaparición. La desaparición del cuerpo es la vía del feminicida para evadir la justicia: sin cuerpo no hay crimen. Ellos son quienes los desaparecen”."
  }
];
const block27 = [
  {
    text:
      "En este escenario estamos: desapariciones en donde no hay un cuerpo que testifique la vida o la muerte de las mujeres que faltan. A partir de esa ausencia hay que intentar construir el significado."
  },
  {
    text:
      "“La desaparición responde a muchas causas, es consecuencia de muchas violencias estructurales”, dice Arely Varela, quien ha acompañado a familias que buscan a sus hijas en Ciudad Juárez, de donde es originaria, y en el Valle de México, donde radica ahora. “A partir del 2006-2007 hubo un cambio en las modalidades de la violencia contra las mujeres”."
  },
  {
    text:
      "“Sobre las causas o motivos de la desaparición, el abanico se abre muchísimo: violencia intrafamiliar, ingobernabilidad de adolescentes (que se van a la fiesta sin permiso), trata de personas en todos sus abanicos: mendicidad, explotación sexual, reclutamiento para labores delictivas”, dice Sol Salgado, comisionada de Búsqueda en el Estado de México"
  },
  {
    text:
      "Y en cuanto a perpetradores son hombres conocidos (parejas, novios, ex parejas), crimen organizado, fuerzas estatales y paraestatales, enumeran Monárrez, Carbajal y Medina en el documento “Nuevas Guerras y crímenes contra la humanidad de las mujeres sin cuerpo y sin espacio”"
  }
];
const entryHeader = {
  preTitle: "",
  title: "Desapariciones:",
  subtitle: "Mantenerlas vivas para explotar su cuerpo",
  place: "Valle de México, una biopsia del país",
  author: "Por: Daniela Rea",
  color: "accent-lilac",
  route: "desapariciones"
};
const sectionHeaders = {
  first: "Mónica Ruth Rojas",
  second: "Melissa Flores",
  third: "Aparecidas",
  fourth: "Susana",
  fifth: "Muertas",
  sixth: "Desapariciones intermitentes",
  seventh: "Trazar lo difuso",
  eighth: "Mariela Vanessa"
};
const fullQuotes = {
  first: {
    id: "e3f1",
    text:
      "“La violencia generalizada a partir de la guerra contra las drogas  invisibilizó la violencia de género, la cual ha exacerbado viejas formas de violencia contra las mujeres y creado otras”, agrega en entrevista.",
    subtext: "",
    bgColor1: "mid-grey-bg",
    bgColor2: "accent-lilac-bg",
    color1: "carbon-color",
    color2: "grey-color",
    fontSize: "md"
  },
  second: {
    id: "e3f2",
    text:
      "Hay que construir las condiciones para narrar las historias de las jóvenes y adolescentes que se fueron de su casa.  Para que no se les criminalice a ellas ni a sus familias. Para que se puedan pensar políticas públicas que atiendan este problema, porque si bien en algunos casos se van por su propio pie, hay que entender por qué se están yendo y, sobre todo, que al irse están expuestas a ser víctimas de algún delito.",
    subtext: "",
    bgColor1: "mid-grey-bg",
    bgColor2: "light-lilac-bg",
    color1: "carbon-color",
    color2: "grey-color",
    fontSize: "md"
  }
};
const quotes = {
  first:
    "¿Cómo fue que llegamos aquí? Como pasamos de contar cuerpos de mujeres abandonados en calles, baldíos, casas, en la década de los 90, en Ciudad Juárez, a intentar registrar (acaso) el número de mujeres desaparecidas. Como pasamos de un crimen concreto que nos hablaba de unas condiciones de la violencia contra mujeres a uno difuso, que seguimos tratando de entender.",
  second:
    "¿Dónde son encontradas las mujeres cuando son encontradas vivas? Dos terceras partes son encontradas en el mismo municipio donde desaparecieron, 8 por ciento en el mismo estado pero en otro municipio y 4 por ciento en un estado distinto al que desaparecieron. De una cuarta parte no se sabe dónde fueron encontradas."
};
const intro =
  "¿Qué pasó a partir del 2007 cuando las desapariciones de mujeres tuvieron un repunte en la estadística nacional? ¿Cómo pasamos de contar feminicidios a intentar registrar mujeres ausentes? ¿Qué podemos entender de cada uno de esos crímenes sobre la violencia contra las mujeres?";
const bullets = {
  intro:
    "Los datos publicados en la versión pública del Registro Nacional de Personas Desaparecidas y No Localizadas nos dicen que:",
  first: "Del 2000 al 2020, 69,363 mujeres fueron desaparecidas en México.",
  second:
    "Más de dos terceras partes de las mujeres desaparecidas (68%) tienen entre 10 y 24 años de edad.",
  third:
    "7 de cada diez mujeres desaparecidas en ese periodo de tiempo fueron encontradas con vida; una de cada cien fue encontrada sin vida.",
  fourth:
    "Yucatán y Guanajuato son los estados con las mayores tasas de desaparición acumulada de mujeres; Tlaxcala y San Luis Potosí tienen la menor donde hay menor número de mujeres desaparecidas.",
  fifth:
    "Esta información ya no es posible tenerla para años recientes, pero un análisis de Data Cívica hecho con los datos del CENAPI indica que del 2000 al 2017, 58% de las mujeres encontradas (vivas o muertas) se localizaron en los municipios donde fueron desaparecidas."
};
export const thirdEntryText = [
  block1,
  block2,
  block3,
  block4,
  block5,
  block6,
  block7,
  block8,
  block9,
  block10,
  block11,
  block12,
  block13,
  block14,
  block15,
  block16,
  block17,
  block18,
  block19,
  block20,
  block21,
  block22,
  block23,
  block24,
  block25,
  block26,
  block27,
  entryHeader,
  sectionHeaders,
  fullQuotes,
  quotes,
  intro,
  bullets
];
export const thirdTitle = entryHeader;
