<template lang="pug">
#textBlockLg.textBlock.align-items-center
  .div(v-for="paragraph in text")
    p.text.px-3.mb-4 {{paragraph.text}}
</template>

<script>
export default {
  name: "TextBlockLg",
  props: ["text"],
  setup() {}
};
</script>
<style lang="scss">
#textBlockLg {
  background-color: #fff;
  padding: 5rem 0;

  p.text {
    max-width: 640px;
    font-size: 1.6rem;
    /* font-weight: 800; */
    text-align: left;
  }
}
//small devices
@media (max-width: 420px) {
  #textBlockLg {
    p.text {
      font-size: 1.3rem;
    }
  }
}
</style>
