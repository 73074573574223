const block1 = [
  {
    text:
      "Magda es una mujer de 29 años que participa en performances en Ecatepec para exigir justicia por las mujeres asesinadas. El primero que hizo, recuerda, se llamó “Hasta que la muerte nos separe” y trató sobre la violencia dentro del noviazgo y del matrimonio."
  },
  {
    text:
      "“Cuando me invitan a hacer este performance me dicen que me aprendiera un caso de alguna víctima de feminicidio o víctima de violencia doméstica. Me aprendí el caso de la señora Xóchitl Ivette que fue asesinada por su pareja, dentro de su hogar y escondió el cuerpo debajo de la cama, como esperando que nadie lo encontrara y lo encontró uno de los hijos de Xóchitl”, narra."
  },
  {
    text:
      "Magda se preparó. Estudió el caso de Xóchitl Ávalos Díaz, asesinada un 8 de agosto de 2017 en la colonia Florida, en Ecatepec, a sólo unos minutos de Santa Clara, donde Magda vive y creció. Pasaron casi tres años para que la Fiscalía del Estado de México detuviera al asesino."
  }
];
const block2 = [
  {
    text:
      "“Cuando empiezo el performance se me olvida su nombre, las fechas, todo. En el momento escuchaba a mis compañeras hablar de los otros casos y se me llenaba el cuerpo de miedo, de recuerdos, cosas que yo viví”."
  },
  {
    text:
      "Entonces Magda, en medio del performance, decide hablar de su propia historia: ese mismo año en que Xóchitl fue asesinada, ella estuvo una semana en el hospital. Su ex pareja la golpeó tan fuerte que casi la mata."
  }
];
const block3 = [
  {
    text:
      "“Ser sobreviviente es convertirte en otra persona”, dice. Sobrevivir la hizo convertirse desde su interior en una mujer más fuerte y ser consciente de su cuerpo: un cuerpo lastimado, pero también un cuerpo vivo. “Algo en ti se quiebra y vuelves a empezar una nueva etapa de tu vida, donde vas construyendo a otra persona, con otra visión, con otras expectativas”. "
  }
];
const block4 = [
  {
    text:
      "Magda es una mujer determinante, decidida, solidaria; fuerte, sobre todo. Es una sobreviviente en estado de alerta constante. Enseña karate a otras mujeres en su barrio como resistencia ante la calle. Para sentirse más segura y hacer sentir más seguras a otras."
  },
  {
    text:
      "Magda llegó a estos performances de la mano de su hermana Itan. Los performances se construyen dentro del taller “Mujer, arte y política”, que imparte Manuel Amador, también coordinador de la Red Denuncia feminicidios Estado de México desde el 2014."
  },
  {
    text:
      "Estos talleres, que se dan en la Universidad Autónoma de la Ciudad de México, son semilleros de organización de las mujeres. Aquí se han encontrado sobrevivientes de violencias distintas, quienes toman los espacios públicos escenificando y dándoles vida a mujeres desaparecidas y asesinadas. Desde esa articulación, varias de las alumnas han organizado colectivas feministas, como “Mujeres en la periferia para la periferia”, cuya historia aquí contamos."
  }
];
const block5 = [
  {
    text:
      "Ecatepec es el municipio más habitado del área metropolitana de la Ciudad de México y uno de los más pobres del Estado de México. Desde el teleférico Mexicable, que cruza por las alturas gran parte de sus colonias, se mira la marginalidad en la zona periférica: casas de hormigón construidas juntas e irregulares, en cerros y laderas inestables. "
  },
  {
    text:
      "Ecatepec llegó a estar entre las 50 ciudades más peligrosas del mundo, por la cantidad de homicidios en el mundo, de acuerdo con el Consejo Ciudadano para la Seguridad Pública y la Justicia Penal, y además tiene una de las mayores tasas de carpetas de investigación por feminicidio en el país. De acuerdo con el Observatorio Nacional Ciudadano, en los primeros tres meses del año 2020 aumentaron 58 por ciento los asesinatos de mujeres, Ecatepec tuvo el mayor incremento."
  },
  {
    text:
      "Por municipios como Ecatepec, el Estado de México cuenta con doble Alerta de Violencia de Género (AVG), al igual que Veracruz. De las 17 entidades con mayores tasas de carpetas de investigación abiertas por feminicidio, a éstas supuestamente son a las que más recursos ha invertido la federación para revertir el fenómeno feminicida."
  },
  {
    text:
      "El 10 de septiembre de 2020, Magda junto a varias compañeras que tomaron la sede de la Comisión de Derechos Humanos del Estado de México (CODHEM) en Ecatepec fueron agredidas y detenidas por policías municipales de ese municipio. La Fiscalía del Estado de México informó que se detuvieron a 13 personas, las cuales recibieron varios golpes en el cuerpo."
  }
];
const block6 = [
  {
    text:
      "Pero en el territorio eso no se ve, no se siente. Ninguna de las alertas o las acciones han revertido el miedo que significa caminar sola, o ha brindado la posibilidad de ser libres. Carolina, por ejemplo, carga una navaja en la mochila, ha aprendido a no caminar por las calles de su municipio sin compañía y a no confiar en la policía."
  }
];
const block7 = [
  {
    text:
      "Carolina, junto con Magda, Itan, Lucía, Sara y Abigail son compañeras en la colectiva  “Mujeres de la Periferia, para la periferia” que fundaron el 8 de marzo de 2019."
  },
  {
    text:
      "Carolina tiene 25 años. Estudió en la preparatoria Francisco Villa de la colonia Hank González, en donde aprendió que la sororidad –solidaridad entre mujeres–, salva. Sobrevivió a un intento de secuestro y desde entonces presta su cuerpo para interpelar a una sociedad que le parece indolente, indiferente, sorda, ciega, muda."
  },
  {
    text:
      "“El 31 de mayo de 2013 iba a la preparatoria con mi papá y a dos cuadras de llegar a la prepa se nos atravesó una camioneta negra, tenía los vidrios polarizados, se bajó un tipo nos sacó el arma, nos encañonó, me dijo que me subiera. Yo pensé que en ese momento iba a hacernos algo. Me pide que me suba, me jalonea, nos encañona a los dos con el arma. Sentí que la única manera de salvarlo era subiéndome”."
  },
  {
    text:
      "Subió un pie en la camioneta negra y escuchó una detonación y el grito de un tipo que dijo que los dejaran, que no valían la pena. Enseguida ella y su papá se echaron a correr y escucharon cómo les disparaban. Por fortuna ninguna de las balas los tocó. Pero pudieron percatarse de que dentro del auto había personas encapuchadas y encadenadas. Eran las 7 de la mañana,  había negocios a punto de abrir, pero, ¿quién haría algo? Nadie. Desde allí cambió su vida."
  },
  {
    text:
      "Su experiencia se transformó cuando comenzó a tomar clases en el taller del maestro Amador; le sirvió emocional y espiritualmente para sanar, para no tener miedo. Se involucró en los performances y ahí conoció a Itan, quien le presenta a su vez a Magda. Luego vendrían Lucía, Sara y Abi quienes formaban parte de otra colectiva, “Invisibles somos visibles”, pero decidieron salirse de ahí porque no era la forma de lucha que querían, ellas querían trabajar en la periferia y de manera voluntaria, no en la Ciudad de México."
  },
  {
    text:
      "Carolina, Itan y Magda trataron de recomponer la confianza de lo que implica hacer nuevos lazos. Hablaron con Lucía, Sara y Abi para trabajar en el Estado de México. Hacer redes implica organización, discusión, acuerdo."
  }
];
const block8 = [
  {
    text:
      "Las mujeres de la periferia habitan barrios marginados, los espacios académicos de reflexión feminista les son lejanos. Sus reflexiones parten del territorio y de la experiencia en el cuerpo propio."
  }
];
const block9 = [
  {
    text:
      "Lucía sobrevivió a un padre alcohólico y golpeador. Cuando era niña ella, su hermana y su mamá dejaron la casa por violencia intrafamiliar y una amiga de su mamá llamada Regina les dio refugio. Con los años su mamá trabajó en una tienda de materias primas que puso Regina. Desde niña Lucía aprendió del apoyo entre mujeres. “Les debemos todo a otras mujeres, incluso muchas que ni conocemos”."
  },
  {
    text:
      "Lucía, ahora de 41 años,  trabaja en la Secretaría de la Mujer de la Ciudad de México en el área de prevención, y sabe que brindar refugios a mujeres víctimas de violencia familiar es una necesidad atendida parcialmente por los gobiernos."
  },
  {
    text:
      "Conoció a Sara, de 26 años, porque es promotora de Salud e hizo sus prácticas en la dependencia donde trabaja. De allí fueron construyendo juntas y Sara sumó a su hermana Abi de 17 años, que a pesar de ser la más joven de las Periféricas ha demostrado un compromiso por tomar el espacio público como un acto de resistencia."
  },
  {
    text:
      "“Los espacios son la representación de poder de quien los habita”, dice. Cuando hacía performances recuerda ver a otras mujeres observándolas detrás de sus oficinas, sus rejas, como atrincheradas.  “Eso me parecía tan simbólico”, reflexiona."
  }
];
const block10 = [
  {
    text:
      "Lucía  ha representado el caso de Pamela Salas, una chica asesinada en un hotel por su novio, un famoso skater llamado Mario  Sáenz, a quien la sociedad arropó por ser guapo y famoso y, en cambio, criminalizó a Pamela por ejercer su sexualidad."
  },
  {
    text:
      "Como parte de la colectiva ha caminado sobre el margen del río de los Remedios, donde han sido encontradas decenas de mujeres muertas, cuyos casos en su mayoría siguen en la impunidad. Pero camina esos bordes en compañía, con otras madres, mujeres, otras colectivas."
  }
];
const block11 = [
  {
    text:
      "“Vas caminando en las orillas del río, en ese espacio donde han sido arrojadas, en bolsas, ver cómo los cuerpos de las mujeres se volvieron tan desechables, están despersonalizados, es algo que me sacudió tremendamente, es bien interesante ver cómo  piensas que vas aportar tú algo, pero también termina el performance aportándote a ti”."
  },
  {
    text:
      "Magda estuvo en varios círculos feministas. Había ido a las marchas del 28S, de Marea Verde para la despenalización del aborto. Pero recuerda con particular emoción la marcha del 31 de agosto del 2018, cuando mamás, hermanas, amigas de mujeres asesinadas y desaparecidas llegaron hasta el ayuntamiento de Ecatepec, entonces a cargo de Fernando Vilchis."
  },
  {
    text:
      "Al llegar al edificio del ayuntamiento la marcha de mujeres se encontró con una valla hecha por mujeres funcionarias y servidoras públicas. Magda se sintió decepcionada de verlas cuidando un edificio, más que la vida de las mujeres. Exigieron una charla con el alcalde y se las negaron. Así que Magda y su hermana entraron “al Palacio, con unas playeras negras y les pusimos los rostros de las desaparecidas (impresos en papel) de Ecatepec”."
  },
  {
    text:
      "El 8 de marzo del 2019 fue la primera vez que las integrantes de Periféricas caminaron juntas, sabiéndose parte de un mismo territorio, amado y temido, Ecatepec."
  },
  {
    text:
      "Quizá Magda no verá un cambio sustancial en esta violencia e impunidad pronto, pero sabe que protestar, caminar, acompañar a otras mujeres es el fruto de su lucha, el ejemplo para otras mujeres, para las niñas de Ecatepec y México."
  }
];
const block12 = [
  {
    text:
      "Sara es sobreviviente de violencia física. “Mi primo se metió a mi casa, me golpeó, al igual mi tía. Los denuncié, según estaban en proceso, yo seguía el caso. Y cuál fue la sorpresa, que no había delito qué perseguir. No les hicieron nada. Hubo profesores que me dijeron que no me dejara, que le echara ganas, que tenía que ser fuerte, y bueno, yo decía: ¿Cómo diablos voy a salir de esta situación? Me sentía súper quebrada”."
  },
  {
    text:
      "Cuando hace el performance Sara lo vive “como si fueras la persona, por un momento te olvidas de ser tú y lo vives como si fuera ella… Todas las mujeres del Estado de México exigimos justicia, seguridad, caminar libres y seguras”. Sara influenció a su hermana Abi a participar en los performance y ser Periférica."
  },
  {
    text:
      "“Puedo decir que empecé chiquita a participar y eso de pronto sí ha sido difícil porque tengo que representar en los performances a mujeres casi de mi edad”, dice Abi. “En ese tiempo como de 12 años 13, 14, 15, que fueron asesinadas... Ya te imaginas, pude haber sido yo, me pudo haber pasado a mí. Acabando los performance lloraba y es un sentimiento encontrado, igual una experiencia muy diferente a ir a las marchas y estar investigando”."
  }
];
const block13 = [
  {
    text:
      "Las mujeres habitan la periferia con su cuerpo. Lo ponen en el día a día, camino a la escuela, en el trabajo, en la casa; lo ponen en los performances para nombrar a las que ya no están. Es su forma de luchar, a diferencia de otras colectivas que, por ejemplo, acompañan casos jurídicamente o realizan análisis de contextos."
  }
];
const entryHeader = {
  preTitle: "",
  title: "Poner el cuerpo:",
  subtitle: "Mujeres en la periferia para la periferia",
  place: "Ecatepec",
  author: "Por: Vania Pigeonutt",
  color: "pink-color",
  route: "ecatepec",
  imagePath: "/assets/Home/Ecatepec_colectiva_Landing.png"
};
const fullQuotes = {
  first: {
    id: "e6f1",
    text:
      "Mientras Magda contaba su historia se dio cuenta que, a diferencia de Xóchitl, ella es una sobreviviente. Y está aquí. Y puede contarlo. Y le invadió una sensación de que si estaba viva, tenía una responsabilidad con aquellas que ya no lo estaban. Y así decidió prestar su cuerpo en los performances a las mujeres asesinadas, desaparecidas, no identificadas. ",
    subtext: "",
    bgColor1: "pink-bg",
    bgColor2: "grey-bg",
    color1: "grey-color",
    color2: "pink-color",
    fontSize: "md"
  },
  second: {
    id: "e6f2",
    text:
      "Para Caro hacer performance juntas significa sanar. “Cuando yo represento a una mujer, me siento ella, me siento en su vida, pienso que volvió a la vida por lo mismo. El hecho de estar en el acto performático, de poder yo representarlas, a la hora de gritar las frases, de decir lo que a ella le gustaba hacer, lo que era ella en vida, es un dolor que empieza  a canalizarse. Cuando estoy allí es un momento de liberación, es sanador, nos sana el alma, que nos sana el corazón. A veces tengo un coraje de poder decir todo lo que nos molesta por el hecho de ser mujeres”.",
    subtext: "",
    bgColor1: "grey-bg",
    bgColor2: "pink-bg",
    color1: "pink-color",
    color2: "grey-color",
    fontSize: "sm"
  }
};
const quotes = {
  first:
    "Construir entre mujeres en Ecatepec es un acto de resistencia:  provienen del territorio y buscan transformarlo; transformar las calles, callejones, el río de los Remedios, porque aquí han sido asesinadas y desaparecidas mujeres y niñas, pero también son los sitios donde viven sus seres amados y tienen recuerdos felices.",
  second:
    "Para Lucía la pobreza acentúa todos los problemas, evidencia el olvido, el abandono a la periferia, a las mujeres. “No somos la prioridad para el estado”, dice.",
  third: "Su cuerpo es el cuerpo de otras y así su historias se encuentran."
};
const sectionHeaders = {
  first: "SOBREVIVIR",
  second: "CONSTRUIR",
  third: "CAMINAR",
  fourth: "PONER EL CUERPO"
};
export const sixthEntryText = [
  block1,
  block2,
  block3,
  block4,
  block5,
  block6,
  block7,
  block8,
  block9,
  block10,
  block11,
  block12,
  block13,
  entryHeader,
  sectionHeaders,
  fullQuotes,
  quotes
];
export const sixthTitle = entryHeader;
