const block1 = [
  {
    text:
      "Es septiembre de 2019 y mujeres de la colectiva Siempre Vivas acompañan las largas jornadas del juicio por su feminicidio. Bordan el rostro, nombre y sueños de Lesvy en una gran manta y tejen, al mismo tiempo, sus historias."
  }
];
const block2 = [
  {
    text:
      "Al interior del Reclusorio Oriente el juez escucha los argumentos de las abogadas que refutan la versión de las autoridades de la Ciudad de México quienes aseguran que Lesvy se suicidó. Afuera del Reclusorio la colectiva Siempre Vivas borda la manta colorida. Es su forma de hacerla presente y de arropar a la familia de Lesvy."
  }
];
const block3 = [
  {
    text:
      "Lesvy fue asesinada el 3 de mayo del 2017 en un jardín de la universidad más importante del país, la UNAM. Su muerte convocó a infinidad de jóvenes, estudiantes, trabajadoras y mamás en toda la ciudad a luchar por la vida de las mujeres."
  }
];
const block4 = [
  {
    text:
      "Una de esas luchas para cuidar la vida de las mujeres es la campamenta que las Siempre Vivas montan afuera del juzgado, frente a la entrada del reclusorio Oriente y junto al grupo de acompañamiento de amigos y familiares de Lesvy. Hay lonas blancas que hacen una pequeña casa de campaña. Las Siempre Vivas sentadas en el suelo tejen. En la malla de metal hay fotos de Araceli, mamá de Lesvy, fichas de desaparecidas y lonas de organizaciones. Durante varios días suenan canciones de trova."
  },
  {
    text:
      "Hoy es uno de los días del juicio. Entre todas se distribuyen las actividades del día: compañeros de algunas cooperativas llevan café y pan, otras sacan el bordado, otras comparten comida, como tortitas de plátano, como las que preparaba Lesvy. Ahí también coinciden los papás de Lesvy, sus abogadas, Sayuri Herrera y Anayeli Pérez, sus amigas y otras madres de mujeres asesinadas. Ellas, además, dejan en el piso cartas y mensajes de apoyo, alguna se acerca a bordar en la manta de dos metros de largo."
  }
];
const block5 = [
  {
    text:
      "La idea de bordar una manta fue de Fer, una de las integrantes de Siempre Vivas. La ejecución es de todas: bordan flores moradas, rojas, rosas, azules y multicolores, huellas de un puma y ositos; un jardín con pastos de verdes pistache, bandera y agua. Bordan a Lesvy, tocando su instrumento, la mandolina, y bordan sus cabellos con hermosos hilos cafés con destellos morados. "
  }
];
const block6 = [
  {
    text:
      "Utilizan varias técnicas, como el yarn bombing –tejidos en lana con relieve–; utilizan los botones que pertenecían a Lesvy y que Araceli, su mamá, les regaló para esta actividad; también su listón azul de la rondalla femenil universitaria, de la UNAM, a la que perteneció."
  },
  {
    text:
      "Adentro del juzgado el feminicida de Lesvy está sentado en una silla: esa imagen rompe a algunas de las jóvenes que acompañan a la familia, como a Lilí.  Afuera del juzgado un mundo paralelo se desarrolla. Se encuentran con ternura y alegría."
  },
  {
    text:
      "Están ahí porque sintieron que era necesario respaldar a la familia de Lesvy, para que no se sintiera sola. Todos los días hacen un reporte, como parte de sus cuidados: contarse dónde están y cómo se sienten. Un monitoreo, sobre todo, de emociones. "
  },
  {
    text:
      "Durante 20 días las Siempre Vivas y otras compañeras tejen la vida de Lesvy al tiempo que tejen sus propias historias. Al escucharse y conversar mientras bordan, comprenden que ese acto va mucho más allá de lo que se resolverá dentro del juzgado."
  },
  {
    text:
      "Al finalizar las audiencias el ex novio de Lesvy, Jorge Luis González, será declarado culpable del feminicidio y sentenciado a 45 años de cárcel."
  }
];
const block7 = [
  {
    text:
      "La Colectiva Siempre Vivas se gestó en marzo del 2019 durante un encuentro en una escuela de Derechos Humanos, al sur de la Ciudad de México. Ale, Lili, Deni, Laura, Fer, Lay, Brenda, Karen, Montse, Aurorita y Belén llegaron ahí a un taller y cuando éste terminó se mantuvieron en contacto, con el tiempo se hicieron amigas."
  },
  {
    text:
      "En julio se reunieron para ir juntas a un encuentro en Pajapan, Veracruz, convocado por el Consejo Nacional Indígena (CNI). Uno de los requisitos para el ingreso era registrarse como colectiva y ellas decidieron hacerla juntas. Luego vinieron marchas y protestas, a donde acudieron juntas, contra casos de desaparición forzada y feminicidio, como el de Pamela Gallardo, una joven desaparecida en el 2017 durante un festival de música electrónica en el sur de la Ciudad de México."
  },
  {
    text:
      "Pero una de estas manifestaciones fue fundacional: la campamenta afuera del reclusorio, durante la audiencia del feminicidio de Lesvy. Porque el miedo a ser asesinada en su escuela atravesó las vidas de las compañeras, como a Fer que, a sus 21 años, estudia en la Facultad de Ciencias Políticas y Sociales de la UNAM."
  }
];
const block8 = [
  {
    text:
      "Siempre Vivas está conformada por mujeres de entre 21 y 30 años que viven, en su mayoría, en el oriente y sur de la Ciudad de México. Su nombre es un conjuro contra el olvido. Lo mismo evoca a las suculentas, las plantas que también son llamadas así, o a la canción de una de sus cantantes favoritas, Rebeca Lane, “Siempre Viva”:  Quién dijo que era fácil ser mujer / Desde pequeñita me hicieron creer. "
  }
];
const block9 = [
  {
    text:
      "Las Siempre Vivas vienen de diferentes espacios, de diferentes luchas previas: hay psicólogas, historiadoras, una abogada, Lau practica yoga, Ale cura con tés y Fer siempre está dispuesta a inventar una nueva intervención a través de sus hilos y agujas. En el camino todas se han hecho artistas del textil y del bordado que cada día dominan más y le imprimen más un sello personal. Juntas, con Lili, Deni, Lay, Montse, Aurora, Belén, Karen y Brenda."
  }
];
const block10 = [
  {
    text:
      "Para Lay, la única mujer trans de la colectiva, acompañar significa compartirse. Vivir y comprender sus diferencias. Tiene 26 años, es judía y sabe que los cuidados del corazón valen más que cualquier teoría. Ella y Alesita, quien se define como feminista radical, saben que es mucho más radical construir juntas. Nadie esperaría ver a una transfeminista construyendo con una feminista radical, precisa Ale."
  }
];
const block11 = [
  {
    text:
      "Deni, a quien el divorcio de sus padres le mostró que sí hay un sesgo de género a la hora de seguir sus vidas, pues tuvieron que irse de la casa del papá, entiende acompañar como vivirse, permitirse estar, confiar. Ale cree que es el intercambio de vivencias, espacios y saberes."
  },
  {
    text:
      "Aprendieron juntas que las amigas, aunque vengan de historias distintas, acompañan y salvan la vida."
  }
];
const block12 = [
  {
    text:
      "Deni confiesa que acompañar–a familias de mujeres desaparecidas y asesinadas– es un proceso de dolor y pérdida de la inocencia de cómo ella concebía al mundo. Cuando se juntó en colectiva ese dolor se transformó en otra cosa, que a veces puede ser, incluso, alegría: “Ahora yo me veo acompañando a las mamás desde algo tan hermoso como el bordado”."
  }
];
const block13 = [
  {
    text:
      "Las Siempre Vivas han conocido el rostro de mujeres asesinadas y desaparecidas a través de las agujas, hilos y mantas. Cada puntada es un pensamiento, una palabra, una emoción por las que ya no están y por cómo esa ausencia traza la vida de quienes aquí siguen. Como una medida de autocuidado decidieron alejarse de las imágenes de mujeres asesinadas como Ingrid Escamilla y eligieron acercarse a su rostro vivo bordándola."
  },
  {
    text:
      "Así han llegado a sus vidas decenas de mujeres y otras colectivas como Vivas en la memoria o Nos Queremos Vivas Neza, de quienes han aprendido sobre todo desde sus diferencias de acciones: algunas prestan el cuerpo en performances, otras bordan como ellas. Las han encontrado en movilizaciones o en actos políticos."
  }
];
const block14 = [
  {
    text:
      "Pamela Gallardo fue desaparecida en el Ajusco, al terminar un concierto de música electrónica, el 5 de noviembre de 2017, tenía entonces 23 años. La señora María Volante, su mamá, les pidió a las Siempre Vivas que bordaran una manta para su hija y que la acompañaran a la marcha de protesta por el segundo año de la desaparición, en el 2019."
  }
];
const block15 = [
  {
    text:
      "“No hay guía de cómo hacer un acompañamiento político; un acto que apoye en la presión para que haya justicia. La señora Mari nos contactó. Hay que bordar el rostro de Pame, porque tiene todo un significado que bordemos su rostro, y cuando bordamos, encontramos a la mujer que estamos buscando o bordamos a la mujer que queremos mantener en la memoria”, dice Alesita."
  }
];
const block16 = [
  {
    text:
      "Bordaron la frase ‘¿Dónde está Pamela Gallardo?’ y fue una experiencia muy fuerte, porque fue asumir la ausencia de manera pública y política al apropiarse con sus mantas del espacio público. Con sus mantas crearon una especie de refugio para los familiares que participaban en la marcha por sus desaparecidas y asesinadas; un refugio contra la intimidación policial."
  },
  {
    text:
      "“Me acuerdo que sacamos nuestras agujas. Hay una foto con nuestras agujas de armas, contra quien quisiera pasar. Fuimos dándonos cuenta de lo poderoso que estábamos haciendo: nuestra arma es una aguja, puede ser una metáfora, pero es real, es nuestra arma. De esta forma es la que nos hemos inventado, eso ha sido muy bonito”, dice Alesita."
  },
  {
    text:
      "Luego vendría otro bordado, el que presentaron el 7M del 2020 durante el concierto de Mon Laferte, Vivir Quintana y Rebeca Lane, en el zócalo de la Ciudad de México. “Nos sembraron miedo, nos crecieron alas”, escribieron en una manta con hilos, parte de la Canción sin miedo."
  },
  {
    text:
      "Alesita considera, y sus amigas coinciden, que Siempre Vivas tiene una preocupación especial por el cuidado. “No podemos acompañar, cuando nosotras no estamos del todo bien. Nos interesa estar bien al interior, estar en lo individual, para eso es también lo colectivo, para estar bien en lo social”."
  },
  {
    text:
      "Por eso han creado lazos con las familias. Pone como ejemplo a Araceli Osorio. “Ara es parte de nosotras, de las Siempre Vivas, porque hemos decidido construir juntas. Para nosotras Ara no sólo es una mamá que busca justicia, Ara es luz, Ara es fuerza y es parte de nosotras. Nos construimos alrededor de todas las personas que acompañamos. Nuestro interés va más allá de accionar políticamente. Accionar desde la ternura desde acompañar en todos los sentidos”."
  }
];
const block17 = [
  {
    text:
      "Belén, de 30 años, es la mayor del grupo y piensa en sus sanaciones como un espacio de cuidados, de escucharse. Justo en la escucha encuentra alivio. Incluso en estos días de cuarentena, mientras se desarrollan las charlas por videollamadas, se dieron cuenta que el cuidado ya no es sólo en las calles, sino en las casas y en las emociones."
  }
];
const block18 = [
  {
    text:
      "Les habría gustado no conocer a doña Mari, a Ara, a las mamás de Pamela y Lesvy, pero al mismo tiempo fue ese dolor el que les permitió encontrarse y resignificarlo en el acompañar y buscar juntas la justicia. No olvidar el pasado para construir futuro. Recordar a Fátima, Ingrid, Isabel, Lesvy, Pamela, Zyanya en sus bordados, trazos que quedan en la memoria colectiva. Son las voces de estas mujeres, traídas a la vida."
  }
];
const block19 = [
  {
    text:
      "Deni cree que han aprendido a construir aceptándose diversas, pero son su mayor muro de protección contra un mundo que les repite todo el tiempo que las pueden matar, desaparecer."
  }
];
const block20 = [
  {
    text:
      "Está segura de que otra de las formas es saber que alguien las sostiene. Por más destruidas o caídas que se puedan  sentir, siempre habrá alguien que sostiene el trabajo que hacen."
  },
  {
    text:
      "Deni tiene otra certeza: “En mi historia personal y a lo largo de mi vida, siempre me han cuidado mis amigas. Diversas, diferentes y en momentos muy distintos, siempre ha habido una amiga que me ha sostenido, dado la mano. Las amigas me han salvado mi vida”."
  }
];
const block21 = [
  {
    text:
      "“En la marcha del 8M queríamos estar en la ciudad, pero también en la periferia, porque muchas vivimos en el oriente. Fuimos a la marcha de Neza, para nosotras significa mucho estar allí, porque es un territorio que nos han enseñado a negar y de alguna forma al ir a la universidad, o al trasladar nuestra vida al centro-sur de la ciudad ha sido un despojo de nuestro propio territorio”, dice Deni."
  }
];
const entryHeader = {
  preTitle: "",
  title: "Siempre vivas.",
  subtitle: " “Tejer la memoria por las que ya no están”",
  place: "Ciudad de México",
  author: "Por: Vania Pigeonutt",
  color: "purple-color",
  route: "cdmx",
  imagePath: "/assets/Home/Colectivos_Landing_2.png"
};
const fullQuotes = {
  first: {
    id: "e5f1",
    text: {
      text1: "Lesvy Berlín Rivera Osorio",
      text2: "tejió las vidas de decenas de mujeres."
    },
    subtext: "",
    bgColor1: "white-bg",
    bgColor2: "pink-bg",
    color1: "none",
    color2: "none",
    fontSize: "xl"
  },
  second: {
    id: "e5f2",
    text:
      "“Querida Lesvy: tu ausencia nos duele… Estoy viviendo el mismo infierno por el que tu admirable madre ha pasado. Mi amada hija nos fue arrebatada”,",
    subtext:
      "le escribe Paty Becerril, madre de Zyanya Figueroa, una joven que fue asesinada, pero cuya muerte el gobierno de Puebla determinó como suicidio.",
    bgColor1: "grey-bg",
    bgColor2: "dark-purple-bg",
    color1: "light-purple-color",
    color2: "light-purple-color",
    fontSize: "lg"
  },
  third: {
    id: "e5f3",
    text:
      "“Aceptarnos en nuestras diversidades nos ha permitido sanar. Después saber que lo personal es político. Saber que las cosas que nos pasan son compartidas. Este dolor por el amor romántico, por todo esto que se nos inculca desde pequeñas nos ha pasado y podemos también ser distintas, construir con otros y con otras de una forma diferente”, dice Deni. ",
    subtext: "",
    bgColor1: "dark-lilac-bg",
    bgColor2: "hot-lilac-bg",
    color1: "neutral-color",
    color2: "carbon-color",
    fontSize: "md"
  }
};
const quotes = {
  first:
    "“Gracias por cambiar mi forma de luchar, por hacer mi furia más grande y combativa”, le escribe una joven.",
  second: "Una evocación constante a las que ya no están",
  third:
    "“Cuando el contexto de violencia cambia y se vuelve más violento, ellos tienen que ser más violentos para ser reconocidos. Ya no sólo es que mata porque puede, sino para sentirse alguien, para retroalimentar su propia existencia en un contexto en el que a nadie le importa”."
};
const sectionHeaders = {
  first: "encontrarse.",
  second: "acompañar.",
  third: "cuidar.",
  fourth: "sanar."
};
export const fifthEntryText = [
  block1,
  block2,
  block3,
  block4,
  block5,
  block6,
  block7,
  block8,
  block9,
  block10,
  block11,
  block12,
  block13,
  block14,
  block15,
  block16,
  block17,
  block18,
  block19,
  block20,
  block21,
  entryHeader,
  sectionHeaders,
  fullQuotes,
  quotes
];
export const fifthTitle = entryHeader;
