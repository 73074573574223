<template lang="pug">
#Entry4
  Navbar
  EntryHeader(v-bind="entryHeader")
  AudioPlayer(:src="audio" :voice="voice")
  TextBlock( :text="block1")
  FullQuote(v-bind="fullQuotes.first")
  TextBlock( :text="block2")
  .container-fluid.px-0
    #e4i1.full-bg.section
    //- (src="/assets/Entry4/_MRZ2303.jpg")
  .label-img.justify-content-center
    p Karina Bolaños sosteniendo una cruz rosa que alude a los feminicidios en Neza.
  TextBlock( :text="block3")
  SectionHeader.semibold(:title="sectionHeaders.first")
  TextBlock( :text="block4")
  SectionHeader.semibold(:title="sectionHeaders.second")
  Quote.bold(id="e4q1" :quote="quotes.first" transition="transition-6s" size="lg-width")
  TextBlock( :text="block5")
  .container-img-large
    img.responsive.section(src="/assets/Entry4/MRZ_0690.jpg" alt="mujeres preparando actividad")
  .label-img.justify-content-center
    p.w-1000 Nos queremos vivas Neza durante la jornada de “juntas comemos, juntas florecemos” actividades de apoyo a mujeres de su territorio que han sido afectadas por la pandemia.
  TextBlock( :text="block6")
  SectionHeader.semibold(:title="sectionHeaders.third")
  TextBlock( :text="block7")
  TextBlockLg.bolder(:text="block8")
  TextBlock( :text="block9")
  .container-fluid.px-0
    #e4i3.full-bg.section
    //- img.responsive(src="/assets/Entry4/_MRZ2322.jpg")
  .label-img.justify-content-center
    p Retrato a Miranda Rosales sosteniendo una cruz. Las cruces rosas son de las primeras actividades que la asamblea realizó en su comunidad.
  TextBlock( :text="block10")
  SectionHeader.semibold(:title="sectionHeaders.fourth")
  .container-fluid.px-0
    #e4i4.full-bg.section
    //- img.responsive(src="/assets/Entry4/_MRZ2426.jpg")
  .label-img.justify-content-center
    p Retrato a Julieta Ruiz, integrante de Nos Queremos Vivas Neza.
  TextBlock( :text="block11")
  .container-img-large
    img.responsive.section(src="/assets/Entry4/_MRZ2394.jpg" alt="mujer sosteniendo cruz rosa")
  .label-img.justify-content-center
    p.w-1000 Diana Betanzos es originaria de Nezahualcóyotl, uno de los once municipios del Estado de México que cuenta con alerta por violencia de género.
  TextBlock( :text="block12")
  SectionHeader.semibold(:title="sectionHeaders.fifth")
  Quote.bolder(id="e4q2" :quote="quotes.second" transition="transition-6s" size="lg-width")
  TextBlock( :text="block13")
  .container-fluid.px-0
    #e4i6.full-bg.section
    //- img.responsive(src="/assets/Entry4/_MRZ2340.jpg")
  .label-img.justify-content-center
    p La Asamblea Vecinal Nos Queremos Vivas Neza se acompañan y escuchan. A lo largo de los años han desarrollado un cariño muy grande entre ellas que es también una de las bases de su organización.
  TextBlock( :text="block14")
  //bolck 15
  .white-bg
    .paragraph.textBlock.italic.bold.py-0
      p.text.px-3.mb-0 A mi vida, a mi cuerpa y a mi alma les debo y procuraré el agua limpia, el alimento fresco y el descanso necesario.
      p.text.px-3.mb-0 Me prometo que no callaré mi voz y que dejaré fluir la palabra escrita.
      p.text.px-3 Que tomaré alga espirulina y polen para ponerme fuerte y disfrutar de los colores de la vida y la muerte…

  //- TextBlock.italic.semibold(:text="block15")
  Quote.bolder(id="e4q3" :quote="quotes.third" transition="transition-3s" size="md-width")
  Pagination(:prev="prevEntry" :next="nextEntry")
</template>

<script>
// simport { ref } from "vue";
import Navbar from "@/components/Navbar.vue";
import AudioPlayer from "@/components/AudioPlayer.vue";
import TextBlock from "@/components/TextBlock.vue";
import TextBlockLg from "@/components/TextBlockLg.vue";
import EntryHeader from "@/components/EntryHeader.vue";
import SectionHeader from "@/components/SectionHeader.vue";
import FullQuote from "@/components/FullQuote.vue";
import Quote from "@/components/Quote.vue";
import Pagination from "@/components/EntryPagination/Pagination.vue";
import { fourthEntryText } from "@/assets/FourthEntry.js";
import { thirdTitle } from "@/assets/ThirdEntry.js";
import { fifthTitle } from "@/assets/FifthEntry.js";

export default {
  name: "FourthEntry",
  components: {
    TextBlock,
    TextBlockLg,
    EntryHeader,
    SectionHeader,
    FullQuote,
    Quote,
    Pagination,
    Navbar,
    AudioPlayer
  },
  setup() {
    const prevEntry = thirdTitle;
    const nextEntry = fifthTitle;
    const audio = "https://s3.amazonaws.com/media.reportajes.fordfoundation.org/NosQueremosVivasNeza_VaniaPigeonutt.m4a"
    const voice = "Karen Jocelyn Monzón";
    const [
      block1,
      block2,
      block3,
      block4,
      block5,
      block6,
      block7,
      block8,
      block9,
      block10,
      block11,
      block12,
      block13,
      block14,
      block15,
      entryHeader,
      sectionHeaders,
      fullQuotes,
      quotes
    ] = fourthEntryText;
    return {
      block1,
      block2,
      block3,
      block4,
      block5,
      block6,
      block7,
      block8,
      block9,
      block10,
      block11,
      block12,
      block13,
      block14,
      block15,
      entryHeader,
      sectionHeaders,
      fullQuotes,
      quotes,
      prevEntry,
      nextEntry,
      audio,
      voice
    };
  }
};
</script>
<style lang="scss">
#Entry4 {
  #Header {
    .header-bg {
      background-image: url("/assets/Entry4/Header.jpg");
    }
    /* background-image: url("/assets/Entry4/Header.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    height: 200vh;
    margin: auto auto 5rem auto; */
  }
  .full-bg.revealed {
    background-color: rgba(#fff, 0);
  }
  img.section {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s ease 1s, opacity 800ms ease, filter 0.5s ease-in-out;
    filter: blur(1px);
  }
  img.section.revealed {
    filter: blur(0);
    visibility: visible;
    opacity: 1;
    transition-delay: 0s;
  }
  #e4i1 {
    background-image: url("/assets/Entry4/_MRZ2303.jpg");
  }
  #e4i3 {
    background-image: url("/assets/Entry4/_MRZ2322.jpg");
  }
  #e4i4 {
    background-image: url("/assets/Entry4/_MRZ2426.jpg");
  }
  #e4i6 {
    background-image: url("/assets/Entry4/_MRZ2340.jpg");
  }
}
@media (max-width: 420px) {
  #Entry4 {
    #e4i1 {
      background-position: center;
    }
    #e4i3 {
      background-position: 200% center;
    }
     #e4i4 {
      background-position: center;
    }
    #e4i6 {
      height: 70vh;
      background-position: center;
    }
  }
}
</style>
