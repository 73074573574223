<template lang="pug">
#Entry2
  Navbar
  EntryHeader(v-bind="entryHeader")
  AudioPlayer(:src="audio" :voice="voice")
  TextBlock(:text="block15")
  SectionHeaderDark(:title="sectionHeaders.sixth" color="hot-pink-color")
  TextBlock(:text="block16")
  SectionHeaderDark(:title="sectionHeaders.seventh" color="hot-pink-color")
  TextBlock(:text="block1")
  .container-img-large
    img.responsive.section(src="/assets/Entry2/alrededordelcerro_pueblosotomies_edomex_textolyd.jpg" alt="poblado de Otzolotepec")
  .label-img.justify-content-center
    p.w-1000 Poblado de Otzolotepec desde el Cerro Verónica en el Estado de México.
  SectionHeaderDark(:title="sectionHeaders.first" color="hot-pink-color")
  TextBlock(:text="block2")
  .container-fluid.white-bg
    .row
      .col-sm-12.col-md-6.px-1.pt-2
        img.responsive(src="/assets/Entry2/alrededordelcerro_pueblosotomies_edomex_textolyd03.jpg" alt="poblado de Otzolotepec")
      .col-sm-12.col-md-6.px-1.pt-2
        img.responsive(src="/assets/Entry2/otzolotepec_pueblosotomies_edomex_textolyd02.jpg" alt="mujer lavando frente a su casa en el pueblo de Temoaya")
  .label-img
    p Del lado izquierdo la fotografía muestra el poblado de Otzolotepec. La fotografía del lado derecho es una escena cotidiana en el pueblo de Temoaya, donde la mayoría de los habitantes viven del comercio que proporciona el turismo que atrae a la comunidad el Centro Ceremonial Otomí.
  TextBlock(:text="block3")
  .container-img
    img.responsive(src="/assets/Entry2/G1_precarizacion.svg" alt="gráfica de tasa estatal de desaparecidas por sexo de entre 10 y 19 años")
  TextBlock(:text="block4")
  SectionHeaderDark(:title="sectionHeaders.second" color="hot-pink-color")
  .container-fluid.white-bg
    .row
      .col-sm-12.col-md-6.px-1.pt-2
        img.responsive(src="/assets/Entry2/feminicidio_maichapamela_edomex_mujeresindigenas.jpg" alt="foto impresa de Maicha Pamela")
      .col-sm-12.col-md-6.px-1.pt-2
        img.responsive(src="/assets/Entry2/feminicidio_maichapamela_edomex_mujeresindigenas_senoraelo.jpg" alt="madre de Maicha Pamela sentada en la cama del cuarto de su hija mientras muestra fotos de ella")
  .label-img.justify-content-center
    p Maicha Pamela de origen otomí fue asesinada en su casa en mayo del 2020 en Temoaya, Estado de México. Su madre Elocadia muestras fotografías de su hija en el que fue su cuarto.
  TextBlock(:text="block5")
  Quote.bolder(id="e2q1" :quote="quotes.first" transition="transition-6s" size="md-width")
  TextBlock(:text="block6")
  .container-img-large
    img.responsive(src="/assets/Entry2/G2_precarizacion.svg" alt="gráfica de tasa estatal de desaparecidas por cuartil de pobreza de entre 10 y 19 años")
  TextBlock(:text="block7")
  SectionHeaderDark(:title="sectionHeaders.eighth" color="hot-pink-color")
  TextBlock(:text="block17")
  TextBlockLg.bolder.pt-0(:text="block18")
  .container-img-large
    img.responsive.section(src="/assets/Entry2/feminicidio_maichapamela_edomex_señoraelocaida03.jpg" alt="retrato de la madre de Maicha Pamela")
  .label-img.justify-content-center
      p.w-1000 Elocadia es madre de Maicha Pamela González. Desde que asesinaron a su hija se ha enfrentado a una serie de omisiones en la investigación del feminicidio de su hija. Maicha Pamela tenía 16 años, vivían en Temoaya y trabajaba atendiendo una tienda de abarrotes en su casa.
  TextBlock(:text="block19")
  SectionHeaderDark(:title="sectionHeaders.third" color="hot-pink-color")
  .container-img-large
    img.responsive.section(src="/assets/Entry2/feminicidio_maichapamela_edomex_casatienda.jpg" alt="Hogar de Maicha Pamela, construcción azul de una sola planta")
  .label-img.justify-content-center
    p.w-1000 Hogar de Maicha Pamela, en este espacio fue asesinada. Los peritajes no se realizaron de manera adecuada con el pretexto de la pandemia.
  TextBlock(:text="block8")
  FullQuote(v-bind="fullQuotes.first")
  TextBlock(:text="block9")
  SectionHeaderDark(:title="sectionHeaders.fourth" color="hot-pink-color")
  //- TextBlock(:text="block10")
  //- TextBlockLg.bolder(:text="block11")
  //- TextBlock(:text="block12")
  //- SectionHeaderDark(:title="sectionHeaders.fifth" color="hot-pink-color")
  .container-img-large
    img.responsive.section(src="/assets/Entry2/terapeutafamiliar_segovia_textolydiette.jpg" alt=" mujer en consultorio trabajando en una laptop")
  .label-img.justify-content-center
    p.w-1000 Adriana Segovia en su consultorio en casa donde da terapia familiar.
  TextBlock.pb-0(:text="block20")
  .white-bg
    .paragraph.textBlock.py-0
      p.text.px-3.mb-4
        span.italic –¿Cuáles son esos factores?&nbsp;
        br
        span.italic –Violencia familiar, pero algunos rasgos en concreto.
  TextBlock.boldest(:text="block21")
  TextBlock.pt-0(:text="block22")
  SectionHeaderDark(:title="sectionHeaders.fifth" color="hot-pink-color")
  TextBlock(:text="block13")
  Quote.bolder(id="e2q2" :quote="quotes.second" transition="transition-9s" size="lg-width")
  TextBlock(:text="block14")
  Pagination(:prev="prevEntry" :next="nextEntry")
</template>

<script>
// simport { ref } from "vue";
import Navbar from "@/components/Navbar.vue";
import AudioPlayer from "@/components/AudioPlayer.vue";
import TextBlock from "@/components/TextBlock.vue";
import TextBlockLg from "@/components/TextBlockLg.vue";
import EntryHeader from "@/components/EntryHeader.vue";
import SectionHeaderDark from "@/components/SectionHeaderDark.vue";
import FullQuote from "@/components/FullQuote.vue";
import Quote from "@/components/Quote.vue";
import Pagination from "@/components/EntryPagination/Pagination.vue";
import { secondEntryText } from "@/assets/SecondEntry.js";
import { firstTitle } from "@/assets/FirstEntry.js";
import { thirdTitle } from "@/assets/ThirdEntry.js";

export default {
  name: "SecondEntry",
  components: {
    TextBlock,
    TextBlockLg,
    EntryHeader,
    SectionHeaderDark,
    FullQuote,
    Quote,
    Pagination,
    Navbar,
    AudioPlayer
  },
  setup() {
    const prevEntry = firstTitle;
    const nextEntry = thirdTitle;
    const audio = "https://s3.amazonaws.com/media.reportajes.fordfoundation.org/precarizaci%C3%B3n_lydiettecarrion_media.m4a"
    const voice = " Ixchel Cisneros";
    const [
      block1,
      block2,
      block3,
      block4,
      block5,
      block6,
      block7,
      block8,
      block9,
      block10,
      block11,
      block12,
      block13,
      block14,
      block15,
      block16,
      block17,
      block18,
      block19,
      block20,
      block21,
      block22,
      entryHeader,
      sectionHeaders,
      quotes,
      fullQuotes
    ] = secondEntryText;
    return {
      block1,
      block2,
      block3,
      block4,
      block5,
      block6,
      block7,
      block8,
      block9,
      block10,
      block11,
      block12,
      block13,
      block14,
      block15,
      block16,
      block17,
      block18,
      block19,
      block20,
      block21,
      block22,
      entryHeader,
      sectionHeaders,
      quotes,
      fullQuotes,
      prevEntry,
      nextEntry,
      audio,
      voice
    };
  }
};
</script>
<style lang="scss">
#Entry2 {
  #Header {
    .header-bg {
      background-image: url("/assets/Entry2/feminicidio_maichapamela_edomex_senoraelocaida02.png");
    }
  }
  #SectionHeaderDark {
    max-width: 850px;
  }
  img.section {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s ease 1s, opacity 800ms ease, filter 0.5s ease-in-out;
    filter: blur(1px);
  }
  img.section.revealed {
    filter: blur(0);
    visibility: visible;
    opacity: 1;
    transition-delay: 0s;
  }
}

</style>
