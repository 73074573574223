<template lang="pug">
#SectionHeader
  .header.px-3
    span.title {{title}}
</template>
<script>
import { ref, onMounted } from "vue";
export default {
  name: "SectionHeader",
  props: ["title"],
  setup() {
    let sectionObserver = ref(null);
    const options = {
      rootMargin: "0px 0px",
      threshold: 0.6
    };

    function observeSections() {
      try {
        if (sectionObserver.value) sectionObserver.value.disconnect();
      } catch (error) {
        console.log(error);
      }
      sectionObserver.value = new IntersectionObserver(
        sectionObserverHandler,
        options
      );
      const sections = document.querySelectorAll("#SectionHeader");
      sections.forEach(section => {
        sectionObserver.value.observe(section);
      });
    }
    function sectionObserverHandler(entries) {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          entry.target.children[0].classList.add("background");
        } else {
          entry.target.children[0].classList.remove("background");
        }
      }
    }
    onMounted(observeSections);
  }
};
</script>
<style lang="scss">
#SectionHeader {
  background-color: #fff;
  text-align: center;
  height: 100vh;
  margin: auto;
  .header {
    font-size: 3.8rem;
    /* font-weight: 500; */
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    line-height: 1;
    display: inline;
    /* white-space: pre-wrap; */
    background: linear-gradient(to top, #c46cc34d 60%, transparent 40%);
    background-repeat: no-repeat;
    background-size: 0% 100%;
    transition: background-size 3s;
  }
  .background {
    background-size: 100% 100%;
  }
}

//small devices
@media (max-width: 420px) {
  #SectionHeader {
    .header {
      font-size: 2.2rem;
    }
  }
}
</style>
