<template lang="pug">
#Entry1
  Navbar
  EntryHeader(v-bind="entryHeader")
  FullQuote.text-center(v-bind="fullQuotes.first")
  AudioPlayer(:src="audio" :voice="voice")
  TextBlock(:text="block1")
  FullQuote.text-center(v-bind="fullQuotes.second")
  TextBlock(:text="block2")
  .container-img
    img.responsive(src="/assets/Entry1/G1_entry1.svg" alt="gráfica sobre la edad de las mujeres asesinadas en méxico de 2000 a 2019")
  .container-fluid.white-bg
    .row.mb-1
      .col-sm-6.col-md-4.px-0.grid-img
        img.responsive(src="/assets/Entry1/_MG_0894c.jpg" alt="periódico con imagen de campo donde encontraron los cuerpos de víctimas")
      .col-sm-6.col-md-5.px-0.grid-img
        img.responsive(src="/assets/Entry1/_MG_0935c.jpg" alt=" periódico con titulal 'Claman justicia a 3 años de campos algodonero'")
      .col-sm-12.col-md-3.px-0.grid-img
        img.responsive(src="/assets/Entry1/_MG_2189c.png" alt= "letrero de que exige justicia para mujer")
    .row.grid-img
      .col-sm-12.col-md-5.px-1.grid-img
        img.responsive(src="/assets/Entry1/_MG_1987c.jpg" alt="periódico con caso de feminicidio")
      .col-sm-12.col-md-7.px-1.grid-img
        img.responsive(src="/assets/Entry1/_MG_1995c.jpg" alt="periódico con caso de feminicidio")
  .label-img.justify-content-center
    p Archivo fotográfico de la Hemeroteca de la Universidad de Ciudad Juárez, de Cedimac y del Archivo personal de Ramona sobre feminicidios en Ciudad Juárez. Recopilados por Alejandra Aragón.
  SectionHeader.semibold(:title="sectionHeaders.first")
  TextBlock( :text="block3")
  Quote.bold(id="e1q1" :quote="quotes.first" transition="transition-3s" size="md-width")
  .container-fluid.px-0
    #e1i7.full-bg.section
    //-  img.responsive(src="/assets/Entry1/_MG_1649c.jpg")
  .label-img.justify-content-center
    p Cruz rosa en memoria de Brenda Patricia desaparecida en 2013. Fue colocada en medio del desierto donde encontraron a Brenda. Foto por Itzel Aguilera.
  TextBlock( :text="block4")
  .container-fluid-img.px-0
    .container-img.graph-bg
      img.responsive(src="/assets/Entry1/G2_entry1.svg" alt="gráfica de tasa de mujeres asesinadas y desaparecidas en méxico")
  SectionHeader.semibold(:title="sectionHeaders.second")
  TextBlock( :text="block5")
  Quote.bold(id="e1q2" :quote="quotes.second" transition="transition-6s" size="md-width")
  TextBlock( :text="block6")
  .container-fluid.white-bg
    .row
      .col-sm-12.col-md-6.px-1.pt-2
        img.responsive(src="/assets/Entry1/DSC04671.JPG" alt="camión con letrero de búsqueda de una mujer desaparecida")
      .col-sm-12.col-md-6.px-1.pt-2
        img.responsive(src="/assets/Entry1/P1040281.JPG" alt="mural con rostros de desaparecidas")
  .label-img.justify-content-center
    p Archivo de acompañamiento de la doctora Julia Monárrez en actos de protesta contra la desaparición y feminicidios en Ciudad Juárez.
  TextBlock( :text="block7")
  Quote.bolder(id="e1q3" :quote="quotes.third" transition="transition-9s" size="md-width")
  TextBlock( :text="block8")
  .container-img
    img.responsive.section(src="/assets/Entry1/_MG_9806c.jpg" alt=" madre de desaparecida sosteniendo una fotrografía de su hija")
  .label-img.justify-content-center
    p.w-800 Retrato de Paula Flores Bonilla madre de María Sagrario González Flores, víctima de feminicidio en 1998. Foto por Alejandra Aragón.
  TextBlock( :text="block9")
  TextBlock.bold( :text="block10")
  SectionHeader.semibold(:title="sectionHeaders.third")
  TextBlock( :text="block11")
  FullQuote(v-bind="fullQuotes.third" )
  TextBlock( :text="block12")
  Pagination(:prev="prevEntry" :next="nextEntry")
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import AudioPlayer from "@/components/AudioPlayer.vue";
import TextBlock from "@/components/TextBlock.vue";
import EntryHeader from "@/components/EntryHeader.vue";
import SectionHeader from "@/components/SectionHeader.vue";
import FullQuote from "@/components/FullQuote.vue";
import Quote from "@/components/Quote.vue";
import Pagination from "@/components/EntryPagination/Pagination.vue";
import { firstEntryText } from "@/assets/FirstEntry.js";
import { secondTitle } from "@/assets/SecondEntry.js";

export default {
  name: "FirstEntry",
  components: {
    TextBlock,
    EntryHeader,
    SectionHeader,
    FullQuote,
    Quote,
    Pagination,
    Navbar,
    AudioPlayer
  },
  setup() {
    const prevEntry = "";
    const nextEntry = secondTitle;
    const audio = "https://s3.amazonaws.com/media.reportajes.fordfoundation.org/Feminicidios_DanielaPastrana.m4a"
    const voice = "Lucía Uribe";
    const [
      block1,
      block2,
      block3,
      block4,
      block5,
      block6,
      block7,
      block8,
      block9,
      block10,
      block11,
      block12,
      entryHeader,
      sectionHeaders,
      fullQuotes,
      quotes
    ] = firstEntryText;
    return {
      block1,
      block2,
      block3,
      block4,
      block5,
      block6,
      block7,
      block8,
      block9,
      block10,
      block11,
      block12,
      entryHeader,
      sectionHeaders,
      fullQuotes,
      quotes,
      prevEntry,
      nextEntry,
      audio,
      voice
    };
  }
};
</script>
<style lang="scss">
#Entry1 {
  #Header {
    .header-bg {
      background-image: url("/assets/Entry1/Foto Cruces Arroyo del Navajo 1 _Rey R. Jauregui_LaVerdad.jpeg")
    }
    /* background-image: url("/assets/Entry1/Foto Cruces Arroyo del Navajo 1 _Rey R. Jauregui_LaVerdad.jpeg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    height: 200vh;
    margin: auto auto 5rem auto; */
  }
  .grid-img {
    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }
  .full-bg.revealed {
    background-color: rgba(#fff, 0);
  }
  img.section {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s ease 1s, opacity 800ms ease,
      filter 0.5s ease-in-out;
    filter: blur(1px);
  }
  img.section.revealed {
    filter: blur(0);
    visibility: visible;
    opacity: 1;
    transition-delay: 0s;
  }
  #e1i7 {
    background-image: url("/assets/Entry1/_MG_1649c.jpg");
  }
}
@media (max-width: 420px) {
  #Entry1 {
    .header-bg {
      background-position: center;
    }
    #e1i7 {
      background-position: center;
    }
    .grid-img {
      height: auto;
    }
  }
}
</style>
