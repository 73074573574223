<template lang="pug">
router-view

</template>

<!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div> -->
<!-- <router-view /> -->
<script>
// import { ref, onMounted } from "vue";

export default {
  setup() {}
};
</script>
<style lang="scss" src="./index.scss"></style>
