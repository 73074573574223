<template lang="pug">
#Pagination
    .container-fluid
      .row
        .col-sm-12.col-md-4
          .entry.d-flex.justify-content-center
            a.title.mt-5( :href="prev.route" v-if="prev")
              img.arrow.mobile-arrow.px-3(src="/assets/left-arrow.png" alt="flecha reportaje anterior")
              p.bold {{prev.preTitle}}
              p.bolder {{prev.title}}
              p.bold {{prev.subtitle}}
              p.italic.bold(v-if="prev.place")
                span.place.px-2 {{prev.place}}
        .explore.col-sm-12.col-md-4.d-inline-flex.justify-content-center
          a(:href="prev.route" v-if="prev")
            img.arrow.desktop.px-3(src="/assets/left-arrow.png" alt="flecha reportaje anterior")
          p.bolder.italic.larger.mt-2 {{nextPrevText}}
          a(:href="next.route" v-if="next")
            img.arrow.desktop.px-3(src="/assets/right-arrow.png" alt="flecha reportaje siguiente")
        .col-sm-12.col-md-4
          .entry.d-flex.justify-content-center
            a.title.mt-5( :href="next.route" v-if="next")
              img.arrow.mobile-arrow.px-3(src="/assets/right-arrow.png" alt="flecha reportaje siguiente")
              p.bold {{next.preTitle}}
              p.bolder {{next.title}}
              p.bold {{next.subtitle}}
              p.italic.bold(v-if="next.place")
                span.place.px-2 {{next.place}}
</template>

<script>
export default {
  name: "Pagination",
  props: ["prev", "next"],
  setup(props) {
    let nextPrevText =
      props.prev && props.next
        ? "Explorar otros reportajes"
        : props.next
        ? "Ir a siguiente reportaje"
        : "Ir a reportaje previo";
    return {
      nextPrevText
    };
  }
};
</script>
<style lang="scss">
#Pagination {
  background-color: #fff;
  padding: 10rem 0;
  .entry {
    width: 100%;
    border-top: 2px solid black;
    margin-top: 1.5rem;
    .title {
      margin: 1rem;
      max-width: 55%;
      text-decoration: none;
      color: #303030;
      text-align: center;
      p {
        margin: 0;
      }
      .place {
        background-color: rgba(219, 130, 204, 0.39);
      }
    }
  }
  .title:hover {
    background-color: rgba(128, 128, 128, 0.068);
  }
  .arrow {
    max-height: 3rem;
  }
  .mobile-arrow {
    visibility: hidden;
  }
}
@media (max-width: 769px) {
  #Pagination {
    .entry {
      .title {
        max-width: 95%;
        font-size: 0.9rem;
      }
    }
  }
}

@media (max-width: 420px) {
  #Pagination {
    border-top: 2px solid black;
    .entry {
      border: none;
      .title {
        max-width: 75%;
        font-size: 1rem;
      }
    }

    .explore {
      order: -1;
    }
    /* .desktop {
      display: none;
    }*/
    .mobile-arrow {
      visibility: initial;
    }
  }
}
</style>
