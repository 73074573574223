<template lang="pug">
#Header
  .header-bg
  section.section
    h3.subtitle.neutral-color {{preTitle}}
    h1.title(:class="color") {{title}}
    h3.subtitle.light.neutral-color {{subtitle}}
    h3.place.lighter.neutral-color {{place}}
    p.author.lighter.italic.neutral-color.mt-5 {{author}}
     p.small.lighter.italic.neutral-color.mt-2 27 de mayo, 2021
</template>
<script>
import { ref, onMounted } from "vue";
export default {
  name: "EntryHeader",
  props: ["preTitle", "title", "subtitle", "place", "author", "color"],
  setup() {
    let sectionObserver = ref(null);
    const options = {
      rootMargin: "0px 0px",
      threshold: 0.7
    };

    function observeSections() {
      try {
        if (sectionObserver.value) sectionObserver.value.disconnect();
      } catch (error) {
        console.log(error);
      }
      sectionObserver.value = new IntersectionObserver(
        sectionObserverHandler,
        options
      );
      const sections = document.querySelectorAll(".section");
      sections.forEach(section => {
        sectionObserver.value.observe(section);
      });
    }
    function sectionObserverHandler(entries) {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          entry.target.classList.add("revealed");
        } else {
          entry.target.classList.remove("revealed");
        }
      }
    }
    onMounted(observeSections);
  }
};
</script>
<style lang="scss">
#Header {
  .header-bg {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    /* background-position: center; */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: -1;
  }
  height: 200vh;
  text-align: center;
  section {
    padding: 1rem;
    visibility: hidden;
    opacity: 0;
    height: 110vh;
    /* transition: visibility 0s linear 0.5s, opacity 250ms ease-out; */
    transition: visibility 0.5s ease 0.5s, opacity 0.5s ease;
    background-color: rgba(0, 0, 0, 0.4);
    .title {
      font-size: 3.5rem;
    }
    .subtitle {
      font-size: 2.3rem;
    }
    .place {
      font-size: 2rem;
    }
    .author {
      font-size: 1.5rem;
      font-weight: 300;
    }
  }
  .revealed {
    visibility: visible;
    opacity: 1;
    transition-delay: 0s;
  }
}
//small devices
@media (max-width: 420px) {
  #Header {
    section {
      .title {
        font-size: 2.5rem;
      }
      .subtitle {
        font-size: 1.6rem;
      }
      .place {
        font-size: 1.4rem;
      }
      .author {
        font-size: 1.2rem;
      }
    }
  }
}
</style>
