<template lang="pug">
#Hero
  .container-fluid.my-0
    .row.px-3
      .logos.col-4.col-md-3.col-lg-2
        img(src="/assets/Home/Logo DC_Blanco.png" alt="logo data cívica")
      .logos.col-4.col-md-3.col-lg-2
        img(src="/assets/Home/logo-pie-de-pagina-blanco.png" alt="logo pie de página")
      .logos.col-4.col-md-3.col-lg-2
        img(src="/assets/Home/Ford-Foundation.png" alt="logo fundación ford")
  section.section.hero
    h1.text-center
      span.lilac-color De las muertas de Juárez al
      br
      span.accent-color #NosQueremosVivas:
    h2.neutral-color.text-center Así ha cambiado la violencia contra las mujeres en México.
  section.section.container.hero-intro
    p.text-center.py-3.md-width.bolder
      span.neutral-color Del año 2000 al 2019, han asesinado
      br
      span.hot-pink-color.boldest en México a más de 42 mil mujeres y desaparecido a más de 62 mil.
      br
      span.neutral-color Algunas cosas han cambiado en estos casi 20 años, una guerra transformó los patrones y niveles de violencia en el país. Y otras permanecen: la misoginia y la precariedad donde germina la violencia de género.
    p.neutral-color.text-center.py-3.md-width Estos textos son un intento de entender cómo llegamos aquí.
    //- p.neutral-color.text-center.py-3.md-width Nos matan, nos desaparecen, nos borran, nos quieren callar.
    //- p.neutral-color.text-center.boldest.py-3.md-width La historia de las mujeres en México ha sido contada desde las pérdidas, las ausencias, la orfandad.
    //- p.text-center.py-3.lg-width
    //-   span.neutral-color Pero las mujeres nos rebelamos a que esa sea la última historia, también queremos contar la nuestra:&nbsp;
    //-   span.hot-pink-color.boldest la del cuidado, la ternura, el ser compañeras; la de la lucha diaria por construir lugares que podamos habitar.
</template>
<script>
export default {
  name: "Hero",
  setup() {}
};
</script>
<style lang="scss" scoped>
#Hero {
  .logos {
    height: 2.4rem;
    margin: 2rem 0 0 0;
    img {
      width: 100%;
      height: auto;
      max-width: 210px;
    }
  }
  height: 250vh;
  section {
    height: 90vh;
    padding: 1rem;
    visibility: hidden;
    opacity: 0;
    transition: visibility 1s ease 1s, opacity 1s ease;
    /* transition: visibility 1s linear 1s, opacity 1s linear; */
    h2 {
      font-size: 1.6rem;
      font-weight: bold;
    }
    &.hero-intro {
      .md-width {
        max-width: 850px;
      }
      .lg-width {
        max-width: 850px;
      }
      p {
        font-size: 1.4rem;
        font-family: "Mulish", sans-serif;
      }
    }
  }
  .revealed {
    visibility: visible;
    opacity: 1;
    transition-delay: 0s;
  }
}
@media (max-width: 420px) {
  #Hero {
    section {
      h2 {
        font-size: 1.4rem;
      }
      &.hero-intro {
        p {
          font-size: 1.25rem;
        }
      }
    }
  }
}
</style>
