<template lang="pug">
#Intro.container-fluid.my-0.px-0
  .fixed-bg
  Hero
  Entries
  Collectives
  Credits

</template>

<script>
// @ is an alias to /src
import { ref, onMounted } from "vue";
import Hero from "@/components/Home/Hero.vue";
import Entries from "@/components/Home/Entries.vue";
import Collectives from "@/components/Home/Collectives.vue";
import Credits from "@/components/Home/Credits.vue";

export default {
  name: "Home",
  components: {
    Hero,
    Entries,
    Collectives,
    Credits
  },
  setup() {
    let sectionObserver = ref(null);
    const options = {
      rootMargin: "0px 0px",
      threshold: 0.8
    };

    function observeSections() {
      try {
        if (sectionObserver.value) sectionObserver.value.disconnect();
      } catch (error) {
        console.log(error);
      }
      sectionObserver.value = new IntersectionObserver(
        sectionObserverHandler,
        options
      );
      const sections = document.querySelectorAll(".section");
      sections.forEach(section => {
        sectionObserver.value.observe(section);
      });
    }
    function sectionObserverHandler(entries) {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          entry.target.classList.add("revealed");
        } else {
          entry.target.classList.remove("revealed");
        }
      }
    }
    onMounted(observeSections);
  }
};
</script>
<style lang="scss">
#Intro {
  .fixed-bg {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: url("/assets/Header_Reportajes.png");
    /* background-position: center; */
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
  }
}
@media (max-width: 420px) {
  #Intro {
    .fixed-bg {
      background-position: center;
      background-image: url("/assets/Header_Reportajes_Mobile.png");
    }
  }
}
/* #Intro {
  background-image: url("/assets/Header_Reportajes.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  /* background-size: cover;
  background-attachment: fixed;
  height: 400vh;
}
@media (max-width: 420px) {
  #Intro {
    background-image: url("/assets/Header_Reportajes_Mobile.png");
  }
} */
</style>
