<template lang="pug">
#Credits
  h2 Acerca del proyecto
  .container.my-5.px-5
    p.about.mb-4 Quizás ahora sea difícil recordarlo, pero en febrero de 2020 el tema de género estuvo en la agenda pública prácticamente cada semana. La atención que tuvo no fue de a gratis. Estábamos hablando de violencia contra las mujeres porque tuvimos tres casos en la Ciudad de México que a muchas nos dejaron desoladas. Estos casos fueron los de Abril Pérez, Ingrid Escamilla y Fátima Cecilia.  La violencia contra las mujeres no es novedad en México, pero esta vez la reacción de las mujeres sí lo fue. Las calles se llenaron de mujeres exigiéndole a las autoridades que no nos fallaran más. Las redes se atiborraron de fotos de perritos, de jardínes y de amaneceres que miles de mujeres subieron para evitar que las fotos del cuerpo mutilado de Ingrid Escamilla se siguieran compartiendo. En este contexto desolador y a la vez esperanzador nació este proyecto; de la certeza de que la violencia contra las mujeres es antiquísima, pero aún no la hemos entendido lo suficiente para evitarla.
    p.about Anteriormente, en Data Cívica habíamos investigado los patrones de homicidios y desapariciones de mujeres. En febrero de 2020 decidimos aliarnos con Pie de Página con el propósito de generar una serie de materiales que nos ayudaran a entender a profundidad muchas de las dudas que aún teníamos sobre los datos de violencia contra las mujeres. ¿Por qué desaparecen a más mujeres adolescentes, pero asesinan más a adultas jóvenes? ¿Cómo son las mujeres que asesinan y desaparecen diferentes? ¿Cómo ha cambiado la violencia en estos años? ¿Cómo se entrecruza la pobreza y la precariedad con los contextos de violencia de género? Aún tenemos mucho por entender; estos textos son una apuesta por entender aún más. Durante nuestras primeras conversaciones nos percatamos también de que en este agujero sin fin que parece ser la violencia hay un rayo de esperanza. Ese rayo somos nosotras mismas. Las mujeres para quienes la rabia se ha convertido en fuerza, alegría, ternura y motor. La historia de la violencia no estaría completa sin nosotras.
  h2 Créditos
  .container
    .row.d-flex.justify-content-between
      .col-sm-12.col-md-6.mt-5.px-4
        p.boldest De las muertas de Juárez al #NosQueremosVivas
        p.pink es un proyecto de Data Cívica y Pie de Página
          br
          span elaborado con el apoyo de Fundación Ford.
        hr.mt-4
        p.bolder Los reportajes son de Daniela Rea, Lydiette Carrión, Daniela Pastrana y Vania Pigeonutt.
        hr.mt-4
        p.bolder El diseño del sitio estuvo a cargo de Alejandra Nava y Nelly Ramírez desarrolló el sitio.
        hr.mt-4
        p.bolder Los datos los procesaron Adrián Lara y Georgina Jiménez.
        hr.mt-4
        p.bolder Las voces son de Lucía Uribe, Ixchel Cisneros, Eréndira Derbez, Vivir Quintana, Karen Jocelyn Monzón, Alma Delia Murillo y Daniela Rea.
        hr.mt-4
        p.bolder Georgina Jiménez coordinó el proyecto.

      .col-sm-12.col-md-5.mt-5.px-4
        p.bolder Las fotos son de:
          ul
            li María Ruiz
            li Ecos del desierto
            li Archivo hemerográfico Cedimac
            li Compilación Alejandra Aragón
            li Itzel Aguilera
            li Archivo de Ramona Morales
            li Archivo de Hemeroteca de la Universidad Autónoma de Ciudad Juárez
        hr.mt-4
        p.bolder El proyecto se publicó el 27 de mayo de 2021.

</template>
<script>
export default {
  name: "Credits"
};
</script>
<style lang="scss">
#Credits {
  background-color: #e5e5e5;
  .container {
    padding: 3rem 1rem 10rem 1rem;
  }
  ul {
    list-style: none;
  }
  li::before {
    content: "•";
    color: #ba7eaf;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
  .about {
    font-family: "Mulish", sans-serif;
    font-weight: 700;
  }
  p,
  li {
    font-size: 1.4rem;
    margin: 0;
    line-height: 1.7;
  }
  hr {
    width: 65%;
    background-color: #000;
    border: 0;
    opacity: 1;
  }
  h2 {
    display: flex;
    flex-direction: row;
    font-size: 3.8rem;
    font-weight: 600;
  }
  h2:before,
  h2:after {
    content: "";
    flex: 1 1;
    border-bottom: 2px solid #ba7eaf;
    margin: auto;
  }
  h2:before {
    margin-right: 6vw;
  }
  h2:after {
    margin-left: 6vw;
  }
}
@media (max-width: 420px) {
  #Credits {
    h2 {
      font-size: 2.1rem;
    }
    h2:before {
      margin-right: 3vw;
    }
    h2:after {
      margin-left: 3vw;
    }
    p,
    li {
      font-size: 1.2rem;
    }
  }
}
</style>
