const block1 = [
  {
    text:
      "Esta joven ya fue localizada, por lo que se omite su nombre real. La llamaremos Tania. Tania vive en un pueblito del municipio Otzolotepec, enclavado en el Cerro Verónica, Estado de México."
  },
  {
    text:
      "A finales del año 2011 a ese pueblo llegaron una madre y un hijo de 20 años. Un habitante del pueblo los conoció en una iglesia del entonces Distrito Federal. La madre dijo que era pasante de Derecho; y el hijo, de nombre Horacio, dijo ser atleta de alto rendimiento. Así que el pueblo era un lugar propicio para entrenar, por sus campos y caminos para correr, y por su relativa cercanía con el Centro Ceremonial Otomí (a una hora distancia), valorado por los corredores, ya que se encuentra a más  de 3000 metros de altura.  Así que cada cierto tiempo iban al pueblo y se quedaban en una cabañita."
  },
  {
    text:
      "Horacio conoció a Tania. Ella, entonces de 15 años, él de más de 20 años. Se hicieron novios a escondidas, como ocurre en muchos casos, en muchas zonas rurales. Y así tuvieron una relación larga, la cual, para enero de 2013, tomó un giro que pareciera ser de “usos y costumbres”."
  },
  {
    text:
      "Tania acudía  a la preparatoria en otro pueblo (no había escuela en el suyo); iba y venía sola. Pero un día no regresó a casa. Huyó con Horacio y se fueron con la madre de él al Distrito Federal.  Tania contaba entonces con 16 años."
  }
];
const block2 = [
  {
    text:
      "No sólo entre los miembros de la etnia hñahñu, sino en casi todos ambientes rurales, una costumbre extendida es “robarse a la novia”. Por lo general en estos lugares los adolescentes viven noviazgos a escondidas y cuando quieren irse a vivir juntos, una estrategia es huir. Al tercer día, la pareja regresa a “pedir perdón” a la familia de la novia, con algún padrino que responda por el joven o con su familia; entonces se pacta una dote y la formalización de la unión."
  },
  {
    text:
      "Usos y costumbres, en un pueblo donde todos se conocen y las familias se conocen de toda la vida, y la pareja también, desde niños. Y también, donde las adolescentes no cuentan con la expectativa de estudiar más allá de la educación básica o media."
  },
  {
    text:
      "Pero las cosas en buena parte de los municipios del país han cambiado. Incluido el municipio de Otzolotepec."
  },
  {
    text:
      "Al día siguiente de la desaparición, la madre de Tania  interpuso una denuncia; y fue entonces que se enteró que su hija levantó un acta en la que declaraba sufrir maltrato por  su mamá, quien además, acusaba la adolescente, le quitaba su dinero. Pero  no había tal dinero, Tania sólo estudiaba, no trabajaba."
  }
];
const block3 = [
  {
    text:
      "Los familiares pronto supieron que estaba con Horacio y la madre de éste en Ciudad de México. Un mes después, a mediados de febrero, recuperaron a  la adolescente. La familia de Tania, a pesar de sus limitaciones económicas quiso hacer las cosas bien: la canalizaron a terapias psicológicas; ahí, la muchacha admitió que la idea de levantar un acta para “meterle miedo” a la familia, fue de Horacio y su madre."
  },
  {
    text:
      "Pocos días después,  Horacio buscó a “su novia” a la salida de la escuela. Pero la familia había decidido que ella ya no se trasladaría sola en transporte público. De nuevo, la familia quería hacer las cosas correctamente, no querían que la hija dejara de estudiar (ella alguna vez habló de ser médica),  así que un tío  la llevaba y recogía todos los días en su taxi.  Cuando Horacio se percató de que no se la podría llevar, rompió el parabrisas del taxi."
  },
  {
    text:
      "Días después, Horacio y la madre se apersonaron para hablar con la madre de Tania. No la encontraron, pero dejaron dicho que la adolescente estaba embarazada.  Era mentira."
  }
];
const block4 = [
  // {
  //   text:
  //     "Llegó  marzo y no paraba el acoso. Desde el Facebook de Horacio llegaron mensajes a Tania y una amiga de ella. Quien  escribía aseguró ser la madre de aquél: decía  que Horacio se había suicidado por Tania. Tampoco era verdad."
  // },
  {
    text:
      "Horacio se presentó una y otra vez en la escuela de Tania. Llegó a jalonearla para que se fuera con él."
  },
  {
    text:
      "Sus compañeros de escuela la defendieron. Pero a partir de entonces, Tania le temió a Horacio. Pero las autoridades le decían a la madre de Tania: “resígnese, señora, son usos y costumbres”. La madre reviró: “soy de pueblo, de provincia. Seré muy ignorante, pero sé que las cosas no se hacen así”."
  },
  {
    text:
      "Para abril de 2013, las cosas tomaron un giro fuera de cualquier ambigüedad. El tío pasó por Tania a la escuela. Entonces, un auto gris se le cerró. Era Horacio, quien bajó armado. Amenazó con matarlo y exigió que Tania saliera. Así, a punta de pistola, la cargó por la cintura y se la llevó."
  },
  {
    text:
      "Las autoridades siguieron sin determinar si había delito o no. Pasaron al menos 3 años para que Tania fuera localizada. No se sabe en qué condiciones."
  }
];
const block5 = [
  // {
  //   text:
  //     "La violencia feminicida, la desaparición de mujeres y niñas, se recrudece y se articula en más territorios. ¿Se puede hablar de un cambio en las formas, o en la intensidad de la violencia?, ¿tiene esto que ver con un tránsito de migraciones que van del campo a la ciudad?"
  // },
  {
    text:
      "Inició en la frontera de México con Estados Unidos, en los años noventa. Mujeres que desaparecen, Algunas halladas, semanas, meses después, muertas. Otras permanecen sin ser localizadas. Pero esta violencia se recrudeció y se articuló en más territorios. Paloma Bonfil lo reconoce de manera empírica al menos. Cada vez se habla más de desapariciones, trata, violencias que antes eran lejanas en las comunidades ¿Se puede hablar de un cambio en las formas, o en la intensidad de la violencia?, ¿tiene esto que ver con un tránsito de migraciones que van del campo a la ciudad?"
  },
  {
    text:
      "Bonfil responde: depende. Depende de la comunidad de donde se sale y las redes. Hay comunidades muy progresistas, donde las mujeres pueden ser autoridades y heredan tierras. Pero hay comunidades donde el tejido social ya se destruyó, o no tienen herramientas."
  },
  {
    text:
      "Fridaguerrera Villalvazo es una activista y comunicadora mexicana que en los últimos años se ha dedicado a denunciar los casos de feminicidio. Ella piensa que sí. Que el tránsito a la ciudad es un riesgo."
  },
  {
    text: "Advierte:"
  }
];
const block6 = [
  {
    text:
      "Y en este collage nacional de violencias, las niñas y mujeres indígenas, cuando son violentadas, son  invisibles para el sistema."
  }
  // {
  //   text:
  //     "Y sí, agrega, las niñas y mujeres indígenas son de los grupos más violentados y vulnerables en este orden social; y los feminicidios son  invisibles para el sistema."
  // }
];
const block7 = [
  {
    text: "Como ejemplo, uno de sus primeros casos de investigación:"
  },
  {
    text:
      "Karina era una niña mixe de la sierra norte de Oaxaca. En 2011, se mudó con su familia al área conurbada de la ciudad de Oaxaca. Una familia más buscando una vida mejor. Ahí la niña pudo ir a la escuela, aprender español y leer y escribir. Pero un par de años después de migrar el padre murió, “por un tema de alcoholismo”, así que la madre, de nombre Elsa, quedó sola y con varios hijos."
  },
  {
    text:
      "Para 2016, Karina ya tenía 13 años y debía ayudar al gasto de la casa. Se fue a trabajar con una señora que tenía un estanquillo de comida junto a la carretera, un lugar al que llegaban  transportistas, choferes y traileros."
  },
  {
    text:
      "Karina “empezó a tener un comportamiento extraño”, narra Frida. “A la madre le hacen saber que la niña tiene amistad con un sujeto mayor, como de unos 30 años”. Una vecina de la familia, que es psicóloga y se ha ganado la confianza de la niña, le hace saber a Elsa que la niña no está bien. La señora con la que trabaja en el restaurante, también alerta a la madre. Así que Elsa decide que la sacará de trabajar de ahí, ya verá cómo harán para vivir. Quizá lavando y planchando... pero las cosas se complican además porque Elsa está embarazada otra vez."
  },
  {
    text:
      "Era el 5 de abril de 2016. Cuando Elsa fue a buscar a su hija al estanquillo, le dicen que “se la llevó este sujeto e iba mareada”."
  },
  // {
  //   text:
  //     "La niña fue a trabajar. El trailero llegó al lugar. Era el 5 de abril de 2016.  Cuando Elsa fue a buscarla al estanquillo, le dicen que la niña “iba mareada, y se la llevó este sujeto”."
  // },
  {
    text:
      "Los conocidos le dijeron a Elsa que interpusiera una denuncia, pero ella no sabía dónde, ni cómo. Elsa no sabe leer ni escribir, habla poco español. Tampoco sabe a dónde dirigirse, mucho menos qué camión tomar. Finalmente, 22 días después, su vecina psicóloga la acompañó a levantar la denuncia."
  },
  {
    text:
      "El 6 de abril de 2016, el cuerpo de una mujer fue hallado en la carretera México-Puebla. Los peritos le calcularon 28 años, al lado de ella habían condones y kleenex. La clasificaron como prostituta. Dos días después la enviaron a la fosa común."
  },
  {
    text:
      "Cuando Fridaguerrera tomó el caso, cotejó la vestimenta señalada por la madre con los hallazgos de varias fiscalías del país. Así lograron identificar a Karina. El cuerpo de la México-Puebla no era el de una mujer de 28 años; era el de una niña de 13. Y no era prostituta. Era una menor de edad de la etnia mixe que debía trabajar, y a quien se la llevó un transportista de nombre Mariano."
  },
  {
    text:
      "“El tipo que mató a Karina está identificado; no hay nadie que lo detenga. Debe seguir engañando y matando niñas”, concluye Frida."
  }
  // {
  //   text:
  //     "“Sigo  dando seguimiento al caso. El tipo que mató a Karina está identificado; no hay nadie que lo detenga. Debe seguir engañando y matando niñas”, concluye Frida."
  // },
  // {
  //   text:
  //     "Intersecciones. Ahí donde se juntan diversas vulnerabilidades, y hay más riesgo de sufrir violencia. Tienen territorios definidos: áreas conurbadas de la ciudades, colonias irregulares, unidades precarias, donde viven las familias más golpeadas por la violencia estructural."
  // },
  // {
  //   text:
  //     "Pero este deterioro tiene varias décadas, y esa violencia se reproduce e intensifica."
  // }
];
const block8 = [
  {
    text:
      "Desapariciones y feminicidios. ¿Un asunto de migraciones?, ¿un producto de choques culturales?, se le cuestiona a Teresa Almada, quien lleva más de 20 años trabajando estos temas en Ciudad Juárez, a través de su organización Centro de Asesoría y promoción Juvenil, A.C. (CASA). Ella guarda silencio unos momentos, y dice:"
  }
];
const block9 = [
  {
    text:
      "En esa zona hay una situación más recrudecida. Hay muchos casos de chicas que se encuentra una en las tapias, con situaciones que tienen que ver con esto: chicas que, a los 13 años, y se unen con tipos de 30 años."
  },
  {
    text:
      "“Encontramos muchos casos de chicas con esta falta de seguridad, de soporte, de protección. Y también hemos encontrado muchos casos de niñas que son abusadas en estas mismas condiciones familiares, por otros niños. De 12, 13 años. Sí hay esta condición de forma muy recrudecida”, dice Almada."
  },
  {
    text:
      "A pesar del tema del feminicidio, no hay  políticas públicas en relación con toda esta población infantil de niñas y adolescentes. [Las niñas]  también tienen mucho esta presión de responsabilidades [no acordes a su edad] de hacerse cargo de los hermanitos, de las tareas del hogar. Muchas adolescentes están huyendo de lo que viven al interior de sus casas."
  },
  {
    text:
      "Y esto también las hace más vulnerables. Vulnerables al crimen; son chicas que están buscando huir, explica Tere. Este es un tema que no ha sido suficientemente reflexionado en la ciudad… y que es la base de estas violencias. “La punta del iceberg es el feminicidio, pero debajo hay muchas violencias previas.”"
  },
  {
    text:
      "De acuerdo con el informe de Data Cívica, más de la mitad de las mujeres reportadas como desaparecidas por el Cenapi eran menores de edad al momento de su desaparición  (51.2%).  Y el 75 % tenían 23 años o menos. Y es en este grupo de edad en el que se localiza a la mayor cantidad de mujeres con vida: el 32% del total de mujeres  localizadas con  vida tienen ese mismo rango de edad."
  }
];
const block10 = [
  {
    text:
      "Tere Almada habla de Juárez. Pero se llevan a niñas de Oaxaca, raptan a adolescentes del Estado de México. Y matan bebés en Ciudad de México. Fridaguerrera agrega además otro dato: cada vez más jóvenes. Y cada vez mayor violencia contra las niñas y niños en el país."
  },
  {
    text:
      "“Los niños y las niñas que vemos son parte del panorama: niños en los cruceros, vendiendo chicles. Y no sabemos si esa niñita está siendo explotada, si la que la tiene es la madre o no”, advierte Fridaguerrera, y esos niños, socialmente, a nadie le importa."
  },
  {
    text:
      "La activista advierte que, de forma empírica, ella aprecia un aumento en esa violencia: cada vez más cuerpos de niñitas o niñitos asesinados."
  }
];
const block11 = [
  {
    text:
      "“Recuerdas como hace unos años veíamos venir mayor violencia contra las mujeres? lo mismo ocurre ahora con los niños y niñas. Y no lo estamos viendo”."
  }
];
const block12 = [
  {
    text:
      "Por ejemplo, el último caso: una bebé de entre 6 meses y 24 meses de edad, en una maleta, localizada en la Alcaldía Gustavo A. Madero. Es una bebé no identificada y la activista emprendió una campaña para identificarla y dar con sus agresores."
  },
  {
    text:
      "“Es una bebé. No tenía más de un año tres meses, esa bebita. Tenía señas de haber sido abusada, desnutrición. No tienes idea cuántas mamás han llegado preguntando. Muchas mamás, de Monterrey, de Lázaro Cárdenas [Michoacán], una enorme cantidad de mamás preguntando, porque sus parejas se llevaron a sus hijas y no saben dónde están”."
  }
];
const block13 = [
  {
    text:
      "Volviendo con Tere Almada se le cuestiona sobre la violencia familiar que desemboca en infanticidios. Ella también ve un patrón distinto."
  },
  {
    text:
      "“Muchas de las dinámicas cotidianas [en Ciudad Juárez] están muy marcadas por patrones del narco, pero también por otras situaciones. Desde el 2010, 2011, que fueron los máximos años de violencia, esta nunca ha cesado, pero también nunca se atendió [a las víctimas]. Entonces, niños de entonces, cuyos padres fueron asesinados y que ahora son adolescentes, no pueden ser ajenos” a la reproducción de la violencia."
  },
  {
    text:
      "“Crece el deterioro, de una generación a otra. Se reproducen las condiciones de la generación de la violencia. El contexto de deterioro es cada vez mayor. Pareciera un destino, tan difícil de revertir…”."
  },
  {
    text: "–¿Se puede revertir algo de esto?"
  }
];
const block14 = [
  {
    text:
      "“Hay que hacer un trabajo de intervención muy fino. Yo creo que se pueden hacer grandes cosas. Se requieren apoyos, soportes, procesos de formación. Ojalá los gobiernos vieran esto. Se piensa que con darles una beca, o una acción de relumbrón. Pero hay muy poca inversión en estas poblaciones”."
  }
];
const block15 = [
  {
    text:
      "Intersecciones. Ahí donde se juntan diversas vulnerabilidades hay más riesgo de sufrir violencia, y hay más posibilidades de que las violencias permanezcan impunes. Estas intersecciones se desenvuelven en territorios definidos: áreas conurbadas de la ciudades, colonias irregulares, unidades precarizadas donde viven las familias más golpeadas por la violencia estructural, pueblitos “tragados” por las grandes urbes del país; migrantes de los pueblos originarios a la gran ciudad... "
  },
  {
    text:
      "De acuerdo con el documento “Interseccionalidad de las desigualdades de género en México. Un análisis para el seguimiento” publicado por la secretaría de Gobernación en junio de 2020, el  42.2 por ciento de las mujeres mexicanas viven bajo la línea de la pobreza, frente a 41.4 por ciento de los hombres. Y la proporción es casi el doble en las mujeres hablantes de lengua indígena y 1.6 veces mayor entre aquellas que además residen en localidades rurales."
  },
  {
    text:
      "¿Todo esto impactará también en las violencias más crudas: el feminicidio en el hogar, en la calle?"
  }
];
const block16 = [
  {
    text:
      "Paloma Bonfil es antropóloga; ha trabajado por casi 10 años con redes de mujeres indígenas que trabajan el tema de violencia. Ella resume: “Son tan víctimas como las no indígenas. Pero los mecanismos que tenemos no las hacen tan visibles”."
  },
  {
    text:
      "A partir de estas redes de mujeres indígenas –muchas de ellas, profesionistas, abogadas– los diagnósticos arrojan  que la violencia de género en las zonas indígenas más tradicionales se queda en la comunidad. “No se denuncia” penalmente. Es en la comunidad donde “se resuelve o no se resuelve. El estimado de ellas es que nueve de cada 10 casos de todo tipo de violencia, 9 de 10 se quedan en la comunidad”."
  },
  {
    text:
      "A partir de esto, se plantean otros problemas. Por ejemplo, qué mecanismos hay al interior de la comunidad –la mayoría de las estructuras de gobierno están encabezadas por hombres–, y si determinadas formas de violencia están naturalizadas –violencias que no son consideradas como tal: golpear a la esposa, violencia económica– ."
  },
  {
    text:
      "Sin embargo, y “pese a todas las restricciones y a la falta de instrumentos propios, las mujeres indígenas organizadas tienen mayores chances de ser protegidas dentro de su comunidad, que en la justicia ordinaria”, señala Bonfil."
  },
  {
    text:
      "Esto es porque los jueces, los ministerios públicos están lejos. No hay traductores, o si los hay, no están capacitados en género, la lejanía de las instituciones, los costos de traslado, etcétera.  “La violencia contra mujeres indígenas es mucho más fuerte por la omisión de las instituciones y del Estado. Para muchas mujeres no es opción denunciar. Ser indígena es ser invisible para el Estado”."
  }
];
const block17 = [
  {
    text:
      "“Eso es lo que pasa. Cuando matan a una mujer indígena para los medios no es tan importante”. Así lo resume Elocadia, hablante de la lengua otomí y madre de Maicha Pamela. Pamela era una adolescente de 16 años, que fue asesinada en su propia casa el 9 de mayo de 2020. Aquel día, Elo salió a su trabajo y dejó a su hija en su hogar, en Temoaya, Estado de México. Esta es una comunidad en la parte alta y boscosa del Estado, cerca de las zonas conurbadas, tanto de Toluca, como de la Ciudad de México. "
  },
  {
    text:
      "A las pocas horas una sobrina le habló al trabajo. Alguien entró a la casa, maniató a Pamela y la metió viva en un tambo con agua y lo tapó. El móvil no fue una agresión sexual; nadie sabe cuál fue el móvil."
  }
];
const block18 = [
  {
    text:
      "Tampoco hay sospechosos. “Te da coraje”, explica la madre. “No sabemos”, porque aunque hay indicios y pruebas, no hay investigación. “Sí hay varias líneas de investigación. Se han arrojado”. [Pero ha pasado] tanto tiempo. No sería así si estuvieran haciendo su trabajo”. "
  }
];
const block19 = [
  {
    text:
      "Los costos para la familia han sido muy altos. Elocadia ha perdido su empleo mientras busca justicia para su hija. La tiendita que la familia montó en la casa –y que Pamela atendía– tampoco deja ya. Los clientes eran los niños que iban a la escuela. Pero con la pandemia, no hay clases ni niños en la calle."
  },
  {
    text:
      "El feminicidio de Pamela ocurrió cerca de donde vivía Fátima Varinia, una niña de 12 años que fue asesinada el 5 de febrero de  2015, en el municipio de Lerma, cuando regresaba de la escuela. Los asesinos y agresores eran tres vecinos de ella; dos de ellos menores de edad."
  },
  {
    text:
      "Lorena Gutiérrez, madre de Fátima, explica que estos lugares eran “pueblitos tranquilos donde no pasaba nada, pero ahora es peligroso”. Aunque, tras una pausa Lorena matiza: quizá siempre fue peligroso pero quedaba oculto."
  },
  {
    text:
      "La llegada del crimen organizado. En el caso de Fátima, los agresores tenían vínculos con policías corruptos y bandas de narcotraficantes. La familia fue amenazada y desplazada  a Monterrey. Todo ello implicó más daños: uno de los hermanos tuvo que dejar de estudiar; otro batalló  con una depresión terrible y murió el año pasado por negligencia médica, mientras el padre de familia sigue sin poder trabajar. Todos esos daños invisibles... "
  }
];
const block20 = [
  {
    text:
      "Adriana Segovia es socióloga por la UNAM y terapeuta de lo familiar. Conoce los entretelones de la violencia en el ámbito privado. Ella advierte desde su experiencia  de más de 20 años trabajando con violencia intrafamiliar, qué cosas podría ver en las familias que eventualmente son los contextos donde hay feminicidio. "
  },
  {
    text:
      "“Ayuda a hacer una narrativa compleja. A veces las cosas no se nombran por no criminalizar pero se deben nombrar”, advierte. “En mi experiencia ciertamente yo diría que desconozco los perfiles de las chavas que han desaparecido o feminicidio, pero sí me atrevo a decir que desde familias que están a punto de expulsar a una adolescente o están a punto por varios factores, se convierten en lugares muy vulnerables de expulsión a la calle, a una relación violenta o embarazo adolescente. O todo junto”."
  }
];
const block21 = [
  {
    text:
      "“Chavas que han vivido violencia de sus padres, del padre o de la madre, justamente están muy urgidas de tener una salida a esa violencia. [...] Mi experiencia viene del trabajo en la clínica de violencia, ahí es como el pan de cada día. Familias que vienen a decir que la hija o el hijo no hacen caso, se descarrilan, en drogas y padres muy desesperados de cómo controlar al hijo o hija. Y el común de estas familias es su sensación de que han perdido el control, pero justamente lo que quieren es seguir haciendo más de lo mismo en cuanto a violencia. "
  }
];
const block22 = [
  {
    text:
      "Pero, “las familias no son las culpables. Son familias que por generaciones han vivido en marginalidad y violencia, no es la familia Pérez, son contextos históricamente marginados y excluidos y violentos”. "
  }
];
const entryHeader = {
  preTitle: "",
  title: "La precarización de la vida",
  subtitle: "y el recrudecimiento de la violencia",
  place: null,
  author: "Por: Lydiette Carrión",
  color: "hot-pink-color",
  route: "precarizacion"
};
const sectionHeaders = {
  first: "Robarse a la novia.",
  second: "Las víctimas silenciosas.",
  third: "La intensificación de las violencias.",
  fourth: "La otra intersección: violencia familiar y violencia en la calle", //"Infancias trastocadas.",
  fifth: "Sí. Hay solución.",
  sixth: "Cuando la víctima es indígena.",
  seventh: "Usos y costumbres.",
  eighth: "Pamela."
};
const fullQuotes = {
  first: {
    id: "e2f1",
    text:
      "–No me atrevería a hacer afirmaciones tan categóricas. Lo que sí es que hay un nivel muy claro de violencia y de precarización, muy generalizado.En donde muchas de ellas [las adolescentes] han crecido en condiciones de mucho abandono, de mucha pobreza, de violencia, de abuso sexual. Hay un conjunto de condiciones en la ciudad… Por ejemplo en el suroriente [de Ciudad Juárez], donde se han construido casas muy pequeñas. Donde viven familias grandes, a veces familias compuestas con hijos de ambos padres, y también el espacio urbano es altamente degradado: enormes lotes baldíos, una enorme mancha urbana, un déficit importante de espacios educativos; y una falta de infraestructura para dar soporte a la vida de las familias de las personas–.",
    subtext: "",
    bgColor1: "light-grey-bg",
    bgColor2: "pink-bg",
    color1: "grey-color",
    color2: "grey-color",
    fontSize: "sm"
  }
};
const quotes = {
  first:
    "“La documentación de lo que hacemos es la punta del iceberg de lo que ocurre en cada caso, en cada historia”; en cada una se  conjugan diferentes violencias que culminan en el feminicidio”.",
  second:
    "–Se requieren programas, muy cercanos a las chicas, que se construyan con ellas. Espacios de crecimiento, de reflexión, para que puedan ir desnaturalizando [la violencia] y creando otras condiciones a nivel familias y comunidades. Las escuelas podrían hacer mucho más.  Se requeriría otro nivel de inversión entre las chicas y los chicos. "
};

export const secondEntryText = [
  block1,
  block2,
  block3,
  block4,
  block5,
  block6,
  block7,
  block8,
  block9,
  block10,
  block11,
  block12,
  block13,
  block14,
  block15,
  block16,
  block17,
  block18,
  block19,
  block20,
  block21,
  block22,
  entryHeader,
  sectionHeaders,
  quotes,
  fullQuotes
];
export const secondTitle = entryHeader;
