<template lang="pug">
#FullQuote.fullQuote(:class="bgColor1,color1, id")
  .quoteText.cdmxCase(v-if="typeof text !== 'string'")
    p.purple-color.text.px-3.my-0(:class="fontSize") {{text.text1}}
    p.text.px-3(:class="fontSize") {{text.text2}}
  .quoteText(v-if="typeof text === 'string'")
    p.text.px-3(:class="fontSize") {{text}}
    p.subtext.px-3 {{subtext}}
</template>
<script>
import { ref, onMounted } from "vue";
export default {
  name: "FullQuote",
  props: [
    "id",
    "text",
    "subtext",
    "bgColor1",
    "color1",
    "bgColor2",
    "color2",
    "fontSize"
  ],
  setup(props) {
    let sectionObserver = ref(null);
    const options = {
      rootMargin: "0px 0px",
      threshold: 0.6
    };
    function observeSections() {
      try {
        if (sectionObserver.value) sectionObserver.value.disconnect();
      } catch (error) {
        console.log(error);
      }
      sectionObserver.value = new IntersectionObserver(
        sectionObserverHandler,
        options
      );
      const sections = document.querySelectorAll("." + props.id);
      sections.forEach(section => {
        sectionObserver.value.observe(section);
      });
    }
    function sectionObserverHandler(entries) {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          entry.target.classList.remove(props.color1, props.bgColor1);
          entry.target.classList.add(props.color2, props.bgColor2);
        } else {
          entry.target.classList.remove(props.color2, props.bgColor2);
          entry.target.classList.add(props.color1, props.bgColor1);
        }
      }
    }
    onMounted(observeSections);
  }
};
</script>
<style lang="scss">
#FullQuote {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //- margin: 5rem auto;
  padding: 2rem 1rem;
  min-height: 120vh;
  transition: background-color 1s ease-out, color 1s ease;
  .cdmxCase {
    line-height: 1;
  }
  .text {
    /* font-size: 1rem; */
    font-weight: bold;
    max-width: 1000px;
  }
  .subtext {
    font-size: 1.3rem;
    max-width: 1000px;
  }
  .xl {
    font-size: 4.6rem;
  }
  .lg {
    font-size: 3rem;
  }
  .md {
    font-size: 2.1rem;
  }
  .sm {
    font-size: 1.8rem;
  }
}
@media (max-width: 420px) {
  #FullQuote {
    .text {
      text-align: left;
    }
    .xl {
      font-size: 3rem;
    }
    .lg {
      font-size: 2rem;
    }
    .md {
      font-size: 1.5rem;
    }
    .sm {
      font-size: 1.3rem;
    }
  }
}
</style>
