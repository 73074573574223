const block1 = [
  {
    text:
      "Un hecho atroz parió a la Asamblea Vecinal Nos Queremos Vivas Neza. El 8 de junio de 2017 Valeria salió de la escuela con su padre y la pareja de éste. Ese día llovía y los adultos pensaron que era buena idea subirla a una combi de la ruta 40 para que no se mojara, mientras ellos la seguían en sus bicicletas."
  },
  {
    text:
      "La perdieron de vista.  El chofer de la combi la vio sola, desvió su ruta, la asfixió y violó. Valeria, de 11 años, fue encontrada en la calle La Zandunga de la colonia Benito Juárez en Nezahualcóyotl, Estado de México, 20 horas después, el viernes 9 de junio. El feminicidio infantil conmocionó a la comunidad."
  },
  {
    text:
      "La gente se volcó a las calles. El domingo, dos días después del feminicidio de Valeria, unas 100 personas salieron autoconvocadas. Iban mamás de otras víctimas de feminicidio. Las personas recorrieron la misma ruta que la combi, la avenida Sor Juana; pasaron por La Zandunga, la calle donde dejaron el cuerpo de Valeria, donde hicieron una pequeña ofrenda. Algunas ya habían participado en marchas, otras se estrenaban en tomar las calles."
  },
  {
    text:
      "Ahí se encontraron Lupita, Yelitza, Julieta, Diana y Karina, vecinas indignadas porque Valeria podría ser la hermana, la hija, la compañera de cualquiera. Sin saberlo aún, estas mujeres formarían la Asamblea Nos queremos vivas Neza, una colectiva que en el territorio, acompaña a mujeres víctimas de muchas violencias. Las demás, como Elsa y Rubí se fueron integrando poco a poco."
  },
  {
    text:
      "Yelitza Pareja Ruiz, de 28 años, fue con su mamá Julieta Ruiz, de 50 años. También llegó Karina Bolaños, una activista de otras causas como los altos cobros de energía eléctrica. Y Elsa Arista, una de las primeras en tomar el micrófono para exigir justicia."
  }
];
const block2 = [
  {
    text:
      "“Cuando nos enteramos de lo que había pasado se removieron muchas cosas”, relata Yelitza, a quien la historia de Valeria le recordó su propia infancia, pues usaba la misma ruta para llegar a la escuela y algunas veces sufrió asaltos, algunos con pistola. “Yo estaba ida, disociada, entre la cotidianidad, pero al mismo tiempo todo esto pasando. Es muy fuerte y me dolía”."
  },
  {
    text:
      "Neza vivía acostumbrada a la violencia: asesinatos a la luz del día, secuestros, extorsiones y cobro de piso; convoyes militares de día y noche. “Pero una niña asesinada era un mensaje para todas”, cuenta Yeli."
  },
  {
    text:
      "Al terminar la marcha al borde de Chimalhuacán, recuerda Lupita, que alguien dijo: “Hay que pasarnos nuestros números, hacer un grupito, estar al pendiente, hay que hacer acciones’. Me apunté, hasta una de mis hermanas se anotó”."
  }
];
const block3 = [
  {
    text:
      "Lupita Alvarado, de 38 años, es mamá de dos mujeres y un hombre. Estudió hasta la secundaria y en 2017 mantenía a sus hijos vendiendo ropa interior en Facebook; eso le permitía tener contacto con vecinos de Neza, sobre todo mujeres."
  },
  {
    text:
      "El primer día de la marcha fue sola. Al día siguiente se llevó a su marido, sus hijas y dos de sus hermanas. Ese domingo escuchó a Irinea Buendía y la inspiró su energía. Ella, oriunda de ahí, sabía que Neza era un municipio violento, pero algo ocurrió al escuchar a Irinea. Y se motivó para acudir a las asambleas."
  },
  {
    text:
      "Diana Betanzos, profesora de danza contemporánea de 29 años, también llegó a la movilización. Y ahí reencontró a Karina Bolaños, que venía de otros procesos organizativos."
  }
];
const block4 = [
  {
    text:
      "Apenas unas semanas después de encontrarse en la manifestación por Valeria fundaron la Asamblea Nos Queremos Vivas Neza, el 23 de junio. Su nombre surgió de las marchas: “Nos queremos vivas y libres”, se leía en las pancartas. Organizadas, convocaron a una Asamblea “mixta, vecinal” –con el tiempo quedarían sólo mujeres– y a más manifestaciones. A una de ellas llegó la señora Lidia Florencio, mamá de Diana Vázquez, asesinada en 2017 en Chimalhuacán. Lidia fue una de las primeras mujeres a quien comenzaron a acompañar."
  },
  {
    text:
      "Su organización fue creciendo poco a poco. Tuvieron mucha visibilidad mediática, lo que ellas se explican a partir del contexto de violencia feminicida donde surgieron: Neza es vecina de Chimalhuacán, un hoyo negro para las mujeres."
  },
  {
    text:
      "Hicieron una página de Facebook para convocar a movilizaciones, de pronto empezaron a llegar mensajes de mujeres que pedían acompañamiento. Uno de los primeros casos que les llegó fue de una niña que se llama Karina, luego otra niña que se llama Estrella."
  },
  {
    text:
      "“Como que de pronto fue muy inminente, el hecho de que ya estábamos ahí”, dice Diana Betanzos."
  },
  {
    text:
      "Los primeros cinco meses fueron de movimiento y comunicación: trabajar directo con la gente, a pie en el barrio; vincularse con los profesores del CBT1. Rehabilitaron el Parque la llanta. Fueron meses de trabajo hacia afuera, motivadas por el dolor y la desesperación de mamás que buscaban a sus hijas."
  }
];
const block5 = [
  {
    text:
      "La autora nigeriana está en contra de que una historia sea contada como única. Es decir, en los lugares donde hay tragedias, también hay gozo, la gente que no tiene grandes posesiones materiales tiene muchas otras posesiones valiosas. La historia no es única. Ni estamos definidos por una sola característica."
  },
  {
    text:
      "En el año 2017, cuando se funda la Asamblea, Karina Bolaños trabajaba en un centro de Derechos Humanos de defensa del territorio, al igual que Elsa. Para ellas las luchas organizadas no eran algo nuevo. Aún así a Karina le sorprendió la cantidad de mujeres que llegaban a las marchas, muchas de ellas de entre 30 a 45 años, como Lupita."
  },
  {
    text:
      "Y aunque Lupita consideraba que no tenía mucho qué aportar a la Asamblea, con el tiempo se convirtió en el lazo que unía a las jóvenes con la comunidad, pues ella nació y creció ahí. Al igual que sus hermanas, se casó antes de ser mayor de edad. No quiere que sus hijas sigan su camino. Quiere que estudien, se realicen, sean libres. La libertad que ella misma habría querido para sí."
  },
  {
    text:
      "De las asambleas en la explanada del Palacio municipal pasaron a tomar las calles con los talleres callejeros: de defensa personal, de autocuidados, de comidas nutritivas, de súper alimentos. Fue en estos espacios donde las vecinas empezaron a compartir sus historias de violencia."
  },
  {
    text:
      "“A los talleres invitábamos a compañeras de otros espacios, de otras geografías, de otras colectivas para fortalecer”, recuerda Dianis."
  }
];
const block6 = [
  {
    text:
      "Julieta da clases en Chimalhuacán donde ha documentado historias de violencia hacia las niñas y adolescentes y ahí imaginó un taller. “Se llama cambiando el cuento, es un taller muy bonito, porque le cambian la historia de Caperucita y más bien es ver que el lobo es un acosador, que ella está en peligro con ese ser masculino que está abusando de su poder”, dice Julieta."
  },
  {
    text:
      "En estos años aprendieron que el trabajo no lo pueden hacer solas. Además de la Asamblea trabajan y tienen sus propios problemas, sueños y necesidades. Les falta tiempo, dinero y no siempre pueden estar al 100. “Ese ha sido uno de los obstáculos más grandes. Conciliar nuestros propios deseos, necesidades, tristezas”, admite Rubí."
  },
  {
    text:
      "Pero aquí están, valorando las experiencias de cada una y fortaleciéndose de ellas.  “Ver que, a pesar de un contexto difícil, complejo, feminicida, de acoso, de negligencia por parte del Estado: Aquí estamos. No nos movemos. Es posible mantener un vínculo honesto, amistoso, desinteresado, por el bien común”, dice Diana. Vínculos que a veces no hay ni en las familias"
  },
  {
    text:
      "Poco a poco le dieron estructura a su experiencia e intuición y ahora tienen tres ejes de trabajo: acompañamiento, información y formación. Así armaron un pequeño manual de “Los primeros auxilios en el caso de desaparición”, un condensado de las cinco cosas que hay que hacer ante la desaparición de una mujer o de una niña.  Así asesoran -personal o virtualmente- a las familias cuando tienen una hija desaparecida y la autoridad no las quiere atender."
  }
];
const block7 = [
  {
    text:
      "Neza es un lugar donde 4 de cada 10 personas viven en pobreza. Aquí se han emitido dos alertas de género en 2015 y 2019. Pero la violencia contra mujeres no para. Apenas el 28 de junio de 2020, dos niñas de 9 meses y 3 años, fueron asesinadas y sus cuerpos abandonados en dos maletas. Una semana antes fueron asesinadas Elideth de 30 años y Diana Ruiz de 37."
  },
  {
    text: "¿Qué significa acompañar en medio de la violencia?"
  },
  {
    text:
      "“Saber hasta dónde podemos llegar, qué es lo que quieren (las familias de las víctimas). Ese dolor que atraviesa la víctima también lo estoy atravesando y que me puede llevar a perder, meterme tanto en el dolor, también a mi me llega  a afectar”, dice Elsa."
  }
];
const block8 = [
  {
    text:
      "“Acompañar es la posibilidad de reconocerme en la otra, no sólo en quiénes están siendo víctimas de violencia, o en las familias que están buscando justicia, reconocerme en las compañeras de la colectiva. Saber que compartimos problemas por ser mujer y ser de la periferia”, dice Rubí."
  }
];
const block9 = [
  {
    text:
      "Yelitza vivió violencia física, sexual y emocional por una ex pareja y hasta ahora, acompañada por la colectiva, pudo nombrarse como sobreviviente de violencia feminicida. “Me ha costado mucho afrontar en el proceso de sanación y el trabajo organizativo con mujeres me ha alivianado un montón, no es algo que yo abiertamente pueda hablar con mi familia”, dice Yelitza."
  },
  {
    text:
      "Para ellas no hay teorías que empaten con su realidad y tratan de contextualizar siempre. Es muy triste vivir en un “feministómetro”, donde al final las mujeres siguen en competencia, por quién es o no la mejor feminista y afuera hay un mundo que las quiere asesinar. Seas una feminista liberal, radical."
  }
];
const block10 = [
  {
    text:
      "Acompañar significa también saber estar, aguardar, entender la historia personal de cada una de las mujeres, respetar sus procesos."
  },
  {
    text:
      "“Al final del camino tú no le puedes decir a una señora que deje de hablarle a su papá o su esposo porque es un macho. Sino que ella sepa que no está sola. Y que en ese contexto tenemos que chambear. Que para nosotras es más importante estar ahí, como que ese es el lugar, no tenemos porque negarlo”, dice Dianis."
  },
  {
    text:
      "Acompañar también implica entender y sortear el territorio sin negarlo. La Asamblea se asume pacifista en las marchas porque los locales en Neza son comercios familiares, que se han levantado con esfuerzos. También porque la brutalidad policial no es la misma en el centro de la Ciudad de México que en la periferia. “No es lo mismo aventar diamantina o romper vidrios en Reforma, que hacerlo aquí. Porque finalmente aquí nosotras vivimos”."
  }
];
const block11 = [
  {
    text:
      "La Asamblea, y otras colectivas, son un lugar al que las familias recurren para luchar por la justicia para Valeria, Diana, Karina y Fátima. Acompañan, denuncian, presionan, obligan a las autoridades a voltear: no importa si deben pasar la madrugada ante el MP para evitar que se libere a un hombre que intentó secuestrar a una niña."
  },
  {
    text:
      "“Desde el inicio nos escribían a la página. Muchas veces decíamos: chale, qué hacemos, órale, hay que buscar, hay que preguntar. Elsa que ya tiene mucha experiencia en la defensa de los Derechos Humanos nos ayudaba un poco, pero en realidad ninguna habíamos trabajado esto y fue entrarle como aventarse al agua fría, en caliente. Ha sido un poco abrumador, porque a veces no hemos tenido las herramientas, pero no nos hemos quedado con los brazos cruzados”, dice Kari."
  }
];
const block12 = [
  {
    text:
      "“A veces tenemos qué decir No, porque no nos damos abasto. Porque la situación es enorme: el monstruo es tan grande, que un grupo de unas cuantas no vamos a combatirlo, por eso hemos pensado no ser un centro de atención, sino un centro de formación e información”, agrega."
  }
];
const block13 = [
  {
    text:
      "El amor no como algo abstracto o romántico, sino  como algo que las reúne a luchar por sus cuerpos y por su barrio. Porque lo quieren, lo reivindican. Aquí crecieron, aquí se enamoraron, aquí conocieron a sus amigas; aquí crecieron sus hijos, hijos socializados en el patriarcado."
  },
  {
    text:
      "Julieta dice que las autoridades han romantizado la pobreza, dejando sin posibilidad a quienes quieren acceder a una vida libre de violencia. No pueden decirles a las mujeres con hijos que se salgan de sus casas y ya. Es más complejo. No hay espacios para acogerlas, pero sobre todo, hay más espacios afuera donde podrían ser vulnerables. Pero le queda claro que no son “huevonas” no están así “porque no le echan ganas o porque quieran”. Debe haber apoyo real."
  },
  {
    text:
      "En la calle de la colonia Benito Juárez, en la casa de Lupita, hay cartulinas verdes fluorescentes con la leyenda: “Juntas comemos, juntas florecemos”. “Itacate solidario y socorro: sororidad: amor entre mujeres”."
  }
];
const block14 = [
  {
    text:
      "Es un día de junio del 2020, en plena emergencia sanitaria por el covid-19. Lupita con su playera morada, cubrebocas azul marino, guantes de látex, sirve platos de papas con acelgas y salsa verde, agua de pepino con limón. Al lado, sus compañeras reparten bolsas con verdura y fruta a las mujeres de Neza. Muchas de estas mujeres tienen a sus hijas desaparecidas; otras se quedaron sin trabajo. Todas ellas se acercaron a la Asamblea a recibir comida, apoyo, cuidado, escucha."
  },
  {
    text:
      "Con la Asamblea se organizaron también otras colectivas como Vivas en la Memoria, la 25N Neza-Chimalhuacán, Mujeres del Oriente que Luchan, Colectiva Moradas, Libertaria Fanzine.  Todas ellas saben que la violencia es estructural y que sus vidas están cruzadas por muchas de esas violencias, por eso decidieron reaccionar y ayudar; así como lo hicieron después del sismo de 2019 y armaron centros de acopio para damnificados en Morelos, Estado de México y Oaxaca."
  },
  {
    text:
      "Este junio también celebran su cuarto aniversario. Alrededor de la mesa comparten reflexiones sobre la pandemia, la violencia. Recuerdan el poema que escribió Dianis:"
  }
];
const block15 = [
  {
    text:
      "A mi vida, a mi cuerpa y a mi alma les debo y procuraré el agua limpia, el alimento fresco y el descanso necesario."
  },
  {
    text:
      "Me prometo que no callaré mi voz y que dejaré fluir la palabra escrita."
  },
  {
    text:
      "Que tomaré alga espirulina y polen para ponerme fuerte y disfrutar de los colores de la vida y la muerte…"
  }
];
const entryHeader = {
  preTitle: "El amor de mujeres para todo el barrio:",
  title: "Nos queremos vivas Neza",
  subtitle: "",
  place: "Ciudad Nezahualcóyotl",
  author: "Por: Vania Pigeonutt",
  color: "hot-pink-color",
  route: "neza",
  imagePath: "/assets/Home/Colectivos_Landing_1.png"
};
const sectionHeaders = {
  first: "la organización.",
  second: "una historia de todas.",
  third: "acompañar acompañadas.",
  fourth: "un lugar",
  fifth: "juntas"
};
const fullQuotes = {
  first: {
    id: "e4f1",
    text:
      " “El que no se mueve no escucha el ruido de sus cadenas”, “Nos queremos vivas: Neza, justicia para Valeria”, ",
    subtext:
      "se leía las pancartas de cartulinas que portaban los vecinos indignados.",
    bgColor1: "white-bg",
    bgColor2: "pink-bg",
    color1: "grey-color",
    color2: "grey-color",
    fontSize: "lg"
  }
};
const quotes = {
  first:
    " “Las historias importan. Importan muchas historias. Las historias se han utilizado para desposeer y calumniar, pero también pueden usarse para facultar y humanizar. Pueden quebrar la dignidad de un pueblo, pero también pueden restaurarla”, escribió Chimamanda Adichie Ngozi. ",
  second:
    "“El amor a otras mujeres es lo primero que a mi me hace pensar en querer luchar”, dice Rubí. “Poder reconocerme en las otras, hace que tenga amor no sólo por las otras sino también por mí y por la vida en general”.",
  third:
    "Estas mujeres caminan en territorios espinosos, pero a la vez allí está su casa, su hogar, su familia."
};

export const fourthEntryText = [
  block1,
  block2,
  block3,
  block4,
  block5,
  block6,
  block7,
  block8,
  block9,
  block10,
  block11,
  block12,
  block13,
  block14,
  block15,
  entryHeader,
  sectionHeaders,
  fullQuotes,
  quotes
];
export const fourthTitle = entryHeader;
