<template lang="pug">
#Audio.white-bg.px-3
  p.italic Escucha este reportaje en la voz de {{voice}}, introducción y créditos con la voz de Alma Delia Murillo.
  audio(controls controlsList="nodownload" :src="src" type="audio/mpeg")
    p Tu navegador no soporta elementos de audio

</template>
<script>
export default {
  name: "AudioPlayer",
  props: ["src", "voice"],
  setup() {}
};
</script>
<style lang="scss">
#Audio {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 6rem;
  p {
    max-width: 500px;
    font-size: 1.25rem;
  }
  audio {
    width: 100%;
    max-width: 500px;
    background-color: #c5c5c5;
    border-radius: 0px;
  }
  audio::-webkit-media-controls-panel {
    background-color: #c5c5c5;
  }
}
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    #Audio {
      audio {
        background-color: transparent;
      }
    }
  }
}
</style>
