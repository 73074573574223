import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import FirstEntry from "../views/FirstEntry.vue";
import SecondEntry from "../views/SecondEntry.vue";
import ThirdEntry from "../views/ThirdEntry.vue";
import FourthEntry from "../views/FourthEntry.vue";
import FifthEntry from "../views/FifthEntry.vue";
import SixthEntry from "../views/SixthEntry.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/feminicidios",
    name: "FEMINICIDIOS",
    component: FirstEntry
  },
  {
    path: "/precarizacion",
    name: "PRECARIZACIÓN",
    component: SecondEntry
  },
  {
    path: "/desapariciones",
    name: "DESAPARICIONES",
    component: ThirdEntry
  },
  {
    path: "/neza",
    name: "Nos queremos Vivas Neza",
    component: FourthEntry
  },
  {
    path: "/cdmx",
    name: "Siempre Vivas",
    component: FifthEntry
  },
  {
    path: "/ecatepec",
    name: "Poner el cuerpo",
    component: SixthEntry
  },
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue")
  // }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
